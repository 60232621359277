import React from 'react';

export default class FormMoreInfo extends React.Component {
	constructor(props) {
		super(props);
    }
    render() {
        return (
            <>
                <span className={`form-section__info-btn-container${this.props.Active ? ' form-section__info-btn-container--hidden' : ''}`}>
                    <a className={`form-section__info-btn`} href={`#${this.props.Id}`} onClick={(e) => this.props.toggleInfo(e)} role="button">{this.props.BtnText}</a>
                </span>
				<div className={`form-section__info${this.props.Active ? ' form-section__info--active' : ''}`} role="tooltip" aria-hidden={!this.props.Active} id={this.props.Id}>
                    <span className="form-section__info-text" dangerouslySetInnerHTML={{ __html: this.props.Info }}></span>
                    <button className="form-section__info-close-btn" onClick={(e) => this.props.toggleInfo(e)}><span className="show-for-sr">{this.props.Translations.BtnClose}</span></button>
                </div>
			</>
		);
	}
}