import React from 'react';
import FormField from './form-field.jsx';
import FormStepNavigation from './form-step-navigation.jsx';
import idHelper from '../../02-tools/id-helper/idHelper.js';


export default class FormStep extends React.Component {
	constructor(props) {
		super(props);
    }

    renderFormFields = () => {
	    let formFieldComps = [];
	    this.props.Step.formFields.map((formField, index) => (
            formFieldComps.push(
                <FormField
                    key={index}
                    FormFieldData={formField}
                    FormDataInput={this.props.FormDataInput}
                    FormSteps={this.props.FormSteps}
                    ActiveStep={this.props.ActiveStep}
                    navigateToStep={this.props.navigateToStep}
                    toggleNavigation={this.props.toggleNavigation}
                    StepIndex={this.props.StepIndex}
                    handleChange={this.props.handleChange}
                    setFormErrors={this.props.setFormErrors}
                    removeFieldError={this.props.removeFieldError}
                    setErrorMessage={this.props.setErrorMessage}
                    Errors={this.props.Errors}
                    Translations={this.props.Translations}
                />
            )));
        return formFieldComps;
    }

    render() {
        if (this.props.ActiveStep === this.props.StepIndex) {
			return (
                <section className="form-section__section xsmall-12 medium-8 large-6 xlarge-5 column">
                    {this.props.Step.title ?
	                    <h2 className="form-section__section-title" id={idHelper.createId(this.props.Step.title)}>{this.props.Step.title}</h2> : null}
                    {this.props.Step.formFields ? this.renderFormFields() : null}
                    {this.props.ShowNavigation ?
                        <FormStepNavigation
                            navigateToStep={this.props.navigateToStep}
                            handleSubmit={this.props.handleSubmit}
                            openModal={this.props.openModal}
                            ActiveStep={this.props.ActiveStep}
                            StepIndex={this.props.StepIndex}
                            TotalSteps={this.props.TotalSteps}
                            NextButtonUrl={this.props.Step.nxtBtnUrl}
                            Translations={this.props.Translations}
                            Loading={this.props.Loading}
                            ShowSystemError={this.props.ShowSystemError}
                        />
                    : null}
				</section>
			);
        } else {
	        return null;
        }
	}
}
