class Params {
  constructor() {
    
  }

  getParams() {
    let results = {};

    var match,
      pl = /\+/g,
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); };

    let query = "";
    if (typeof window === 'object') {
      query = window.location.search.substring(1);

      while (match = search.exec(query)) {
        results[decode(match[1])] = decode(match[2]);
      }
    }
    return results;
  }

  createParams(params) {
    let result = "";

    for (let param in params) {
      if (params[param]) {
        result = result + '&' + param + '=' + params[param];
      }
    }

    return decodeURIComponent(result);
  }

  addParam(value, param) {
    if (param) {
      return param + "|" + value;
    } else {
      return value;
    }
  }

  removeParam(value, param) {
    let split = param.split('|');
    let index = split.indexOf(value);
    let result = '';

    if (index > -1) {
      split.splice(index, 1);
    }

    for (let values of split) {
      if (result == '') {
        result = values;
      } else {
        result = result + '|' + values;
      }
    }

    return result;
  }
}

export default new Params();