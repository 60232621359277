import Mount from '../../02-tools/mount';
import Pikaday from 'pikaday';
import Moment from 'moment';
import GlobalText from '../../02-tools/global-text/global-text';
import Keyboard from '../../02-tools/keyboard/keyboard';
import FormSummary from '../form-summary/form-summary';
import RWD from '../../02-tools/rwd/rwd';
import XHR from '../../02-tools/xhr/xhr';

export default class FormSection {
    constructor(element) {
        const form = element.querySelector('.form-section__form');
        const formSummary = document.querySelector('.form-summary');
        const concent = element.querySelector('.js-form-concent');
        const dateElements = element.querySelectorAll('.form__date');
        const robot = document.getElementById('Robot');
        const dropdownDependencies = element.querySelectorAll('[data-dropdown-dependency]');
        const maxLengthFields = element.querySelectorAll('[maxlength]');

        const submitbtn = element.querySelector('.form-section__submit');
        const cancelbtn = element.querySelector('.form-section__cancel-btn');
        const changeBtns = document.querySelectorAll('.form-guide__change-link');
        const fieldInfoButtons = element.querySelectorAll('.js-field-info-btn');
        const resetBtns = element.querySelectorAll('.form-section__reset-btn');

        this.fieldsToValidate = element.querySelectorAll(':not(.form-section--hidden) .form__textarea, :not(.form-section--hidden) .form__textbox, :not(.form-section--hidden) .form__date, :not(.form-section--hidden) .form__time, :not(.form-section--hidden) .form__select');

        this.notifications = element.querySelector('.js-error-notification');
        this.notificationsList = element.querySelector('.js-error-notifications-list');
        this.notificationsCounter = 0;

        this.datePickers = [];
        this.isDesktop = (RWD.resize().large || RWD.resize().xlarge);

        // Reset form on pageLoad
        window.addEventListener('pageshow', function (event) {
            form.reset();
        });

        // Reset notifications and validation on empty fields when changing answers in form guide
        for (const link of changeBtns) {
            link.addEventListener('click', (e) => {
                this.notifications.classList.add('notification--hidden');
                for (const field of this.fieldsToValidate) {
                    if (field.value === '') {
                        this.resetFieldValidation(field);
                    }
                }
            });
        }

        // Add event listeners to fields that should have validation
        for (const field of this.fieldsToValidate) {

            field.addEventListener('change', (e) => {
                const target = e.target;
                const parent = target.parentNode.parentNode;
                const value = target.value;
                const required = target.required;

                this.handleErrors(target, parent, this.isValid(field), false);
            });

            field.addEventListener('blur', (e) => {
                const target = e.target;
                const parent = target.parentNode.parentNode;
                const value = target.value;
                const required = target.required;

                this.handleErrors(target, parent, this.isValid(field), false);
                if (target.dataset.autocompleteField) this.autocompletePlaceFromZip(target.value, target.dataset.autocompleteField);
            });
        }

        // Show/hide info boxes
        for (const button of fieldInfoButtons) {
            const info = document.getElementById(button.getAttribute('aria-controls'));

            button.addEventListener('click', (e) => {
                e.preventDefault();
                info.classList.toggle('form-section__info--active');
                info.classList.contains('form-section__info--active') ? info.setAttribute('aria-hidden', false) : info.setAttribute('aria-hidden', true);
            });
        }

        // Handle fields with max length values
        for (const field of maxLengthFields) {
            field.addEventListener('keyup', (e) => {
                const target = e.target;
                const parent = target.parentNode.parentNode;
                const maxLength = field.getAttribute('maxlength');
                const fieldLength = field.value.length;

                if (fieldLength >= maxLength) {
                    this.handleErrors(target, parent, false, false);
                } else {
                    this.handleErrors(target, parent, true, false);
                }
            });
        }

        // Handle concent checkbox
        if (concent) {
            concent.addEventListener('change', (e) => {
                this.handleErrors(concent, concent.parentNode, concent.checked, false);
            });
        }

        // Initiate Datepicker on date fields
        GlobalText.get().then((data) => {
            for (const [index, element] of dateElements.entries()) {
                element.addEventListener('click', (e) => {
                    e.preventDefault();
                });
                this.initDatePicker(element, data, index);
            }
        }).catch((error) => {
            console.log('error', error);
        });

        // Form submit
        submitbtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.notificationsList.innerHTML = '';
            this.notificationsCounter = 0;

            // Validate all validation fields on submit
            for (const field of this.fieldsToValidate) {
                const parent = field.parentNode.parentNode;
                const errorMsg = parent.querySelector('.form__label-text').innerHTML;

                this.handleErrors(field, parent, this.isValid(field), false);
                this.handleNotifications(errorMsg, this.isValid(field), field);
            }

            // Validate concent concent
            if (concent) {
                this.handleErrors(concent, concent.parentNode, concent.checked, false);
                this.handleNotifications(concent.parentNode.querySelector('.form__label-text').innerHTML, concent.checked);
            }

            // Scroll to notifications box if validation errors, or mount form summary
            if (this.notificationsCounter > 0) {
                window.scrollTo(0, this.notifications.offsetTop - document.querySelector('.pno-header').offsetHeight - 24);
                this.notifications.focus();
            } else {
                this.notifications.classList.add('notification--hidden');
                if (formSummary) {
                    Mount('form-summary', FormSummary);
                } else {
                    form.submit();
                }
            }
        });

        // Cancel form process and reload page
        cancelbtn.addEventListener('click', (e) => {
            e.preventDefault();
            location.reload();
        });

        // Handle dropdown dependencies
        for (const dependency of dropdownDependencies) {
            dependency.addEventListener('change', (e) => {
                const showWhenSelected = dependency.options[dependency.selectedIndex].getAttribute('data-show-when-selected');
                const allDependencies = dependency.querySelectorAll('[data-show-when-selected]');

                // Hide all dependencies
                for (const item of allDependencies) {
                    const field = document.getElementById(item.dataset.showWhenSelected);
                    const label = field.parentNode.parentNode;

                    field.value = '';
                    this.handleErrors(field, label, true, false);
                    label.classList.add('form__label--hidden');
                    label.setAttribute('aria-hidden', true);
                }

                // Show dependency if selected
                if (showWhenSelected) {
                    const fieldToShow = document.getElementById(showWhenSelected);
                    const fieldLabel = fieldToShow.parentNode.parentNode;

                    fieldLabel.classList.remove('form__label--hidden');
                    fieldLabel.setAttribute('aria-hidden', false);
                }
            });
        }

        // Handle reset buttons
        for (const btn of resetBtns) {
            btn.addEventListener('click', (e) => {
                e.preventDefault();

                const btn = e.target;
                const fieldsToReset = btn.dataset.resetFields.split('|');

                fieldsToReset.map((id) => {
                    const field = document.getElementById(id);

                    switch (field.tagName.toLowerCase()) {
                        case 'input':
                            field.value = '';
                            this.resetFieldValidation(field);
                            break;
                        case 'select':
                            field.selectedIndex = 0;
                            this.resetFieldValidation(field);
                            break;
                        default:
                            return;
                    }
                });
            });
        }

        setTimeout(() => {
            robot.tabIndex = -1;
        }, 3000);
    }

    // Field validation
    isValid(field) {
        const validationType = field.dataset.validate;
        const required = field.required;
        const isVisible = field.offsetHeight !== 0;
        let value = field.value;
        let endDatePrecedesStartDate = false;
        let isSelectValueDisabled = false;
        let futureDate = false;
        let validationErrors = {};

        // Set flag if end date precedes start date
        if ((validationType === 'date' || validationType === 'time') && field.dataset.validateLinked) {
            const startDateTimeContainer = document.getElementById(field.dataset.validateLinked);
            const endDateTimeContainer = field.parentNode.parentNode;
            const startDateTime = startDateTimeContainer.querySelector('input').value + ' ' + startDateTimeContainer.querySelector('select').value;
            const endDateTime = endDateTimeContainer.querySelector('input').value + ' ' + endDateTimeContainer.querySelector('select').value;

            let startDateTimeFormatted = Moment(startDateTime.replace(/(\d+).(\d+).(\d+)/, '$3-$2-$1'));
            let endDateTimeFormatted = Moment(endDateTime.replace(/(\d+).(\d+).(\d+)/, '$3-$2-$1'));

            startDateTimeFormatted = startDateTimeFormatted.isValid() ? startDateTimeFormatted : Moment('1900-01-01 00:00');
            endDateTimeFormatted = endDateTimeFormatted.isValid() ? endDateTimeFormatted : Moment('2099-01-01 00:00');
            endDatePrecedesStartDate = endDateTimeFormatted.isBefore(startDateTimeFormatted) ? true : false;
        }

        // Set flag if date is in the future
        if ((validationType === 'date' || validationType === 'time') && field.value) {
            const isDate = validationType === 'date' ? true : false;
            const hasLinkedField = field.dataset.linkedField ? true : false;
            let dateTime = field.value;

            if (isDate && hasLinkedField) {
                dateTime = field.value + ' ' + document.getElementById(field.dataset.linkedField).value;
            } else if (!isDate && hasLinkedField) {
                dateTime = document.getElementById(field.dataset.linkedField).value ? (document.getElementById(field.dataset.linkedField).value + ' ' + field.value) : ('01.01.1900 ' + field.value);
            }

            const currentDate = Moment();
            const pickedDate = Moment(dateTime.replace(/(\d+).(\d+).(\d+)/, '$3-$2-$1'));

            futureDate = pickedDate.isAfter(currentDate) ? true : false;
        }

        if ((validationType === 'date' || validationType === 'time') && isVisible) {
            validationErrors.isFutureDate = futureDate ? true : false;
            validationErrors.isEndDateBeforeStartDate = endDatePrecedesStartDate ? true : false;
            validationErrors.isRequiredFieldEmpty = (required && value.length === 0) ? true : false;
            validationErrors.field = field;

            return validationErrors;
        }

        // Check if disabled value in select is selected
        isSelectValueDisabled = (validationType === 'select' && field.options[field.selectedIndex].getAttribute('disabled') === 'disabled') ? true : false;

        if (!isVisible) {
            // Hidden fields are always valid
            return true;
        } else if (isSelectValueDisabled) {
            // Disabled fields are never valid
            return false;
        } else if (!required && value.length === 0) {
            // Non-required fields without input are valid
            return true;
        } else if (required && value.length === 0) {
            // Required fields without input are not valid
            return false;
        } else if (value.length > 0) {
            // All fields with input are regexp tested
            return this.getRegExp(validationType).test(value);
        }

        return false;
    }

    // Handle validation errors in input fields
    handleErrors(elem, parent, valid, isInitiatedByLinked) {
        const errorMsg = parent.querySelector('.form-section__message--error');
        const errorMsgClass = 'form-section__message--active';
        const errorClass = 'form__input-error';
        const hasValidationErrorObj = Object.keys(valid).length ? true : false;
        let invalidFields = 0;

        if (errorMsg) {
            if (hasValidationErrorObj) {
                const validationObjHasErrors = (valid.isRequiredFieldEmpty || valid.isFutureDate || valid.isEndDateBeforeStartDate) ? true : false;
                const errorMsgFragmentClass = 'form-section__error-message-fragment--active';
                const errMsgRequiredField = errorMsg.querySelector('[data-err-for="required-field"]');
                const errMsgFutureDate = errorMsg.querySelector('[data-err-for="future-date"]');
                const errMsgEndDatePrecedesStartDate = errorMsg.querySelector('[data-err-for="precedes-start-date"]');
                const errMsgFragments = errorMsg.querySelectorAll('.form-section__error-message-fragment');

                if (!isInitiatedByLinked) {
                    for (const fragment of errMsgFragments) {
                        fragment.classList.remove(errorMsgFragmentClass);
                    }
                }

                if (validationObjHasErrors) {
                    // Has error
                    if (valid.isRequiredFieldEmpty && errMsgRequiredField) {
                        errMsgRequiredField.classList.add(errorMsgFragmentClass);
                    }

                    if (valid.isFutureDate && errMsgFutureDate) {
                        errMsgFutureDate.classList.add(errorMsgFragmentClass);
                    }

                    if (valid.isEndDateBeforeStartDate && errMsgEndDatePrecedesStartDate) {
                        errMsgEndDatePrecedesStartDate.classList.add(errorMsgFragmentClass);
                    }
                }

                if (validationObjHasErrors && !elem.classList.contains(errorClass)) {
                    errorMsg.classList.add(errorMsgClass);
                    elem.classList.add(errorClass);
                } else if (!validationObjHasErrors && elem.classList.contains(errorClass)) {
                    // No error
                    errorMsg.classList.remove(errorMsgClass);
                    elem.classList.remove(errorClass);
                }
            } else {
                // Field doesn't validate and field is missing error class - Add error message
                if (!valid && !elem.classList.contains(errorClass)) {
                    // Add error message
                    errorMsg.classList.add(errorMsgClass);
                    elem.classList.add(errorClass);

                    // Field validates and field has error class - Remove error message
                } else if (valid && elem.classList.contains(errorClass)) {
                    // Remove error message
                    errorMsg.classList.remove(errorMsgClass);
                    elem.classList.remove(errorClass);
                }
            }
        }

        // Count invalid fields
        for (const field of this.fieldsToValidate) {
            if (field.classList.contains(errorClass)) {
                invalidFields += 1;
            } else if (field.hasAttribute('required') && field.value.length === 0) {
                invalidFields += 1;
            }
        }

        if (invalidFields === 0) {
            this.notifications.classList.add('notification--hidden');
        }

        // Check for triggered validations
        if (elem.dataset.triggerValidation && elem.dataset.triggerValidation.length > 0) {
            const linkedElems = elem.dataset.triggerValidation.split(',');

            for (const elem of linkedElems) {
                const target = document.getElementById(elem);
                const parent = target.parentNode.parentNode;
                const value = target.value;
                const required = target.required;

                this.handleErrors(target, parent, this.isValid(target), false);
            }
        }

        // Check for linked fields
        // Do not validate linked fields initiated by checking an linked field to prevent infinite loop
        if (elem.dataset.linkedField && !isInitiatedByLinked) {
            const target = document.getElementById(elem.dataset.linkedField);
            const parent = target.parentNode.parentNode;
            const value = target.value;
            const required = target.required;

            this.handleErrors(target, parent, this.isValid(target), true);
        }
    }

    // Handle notifications in notifications area in top of form
    handleNotifications(errorMsg, valid, field) {
        const validationObjHasErrors = (valid.isRequiredFieldEmpty || valid.isFutureDate || valid.isEndDateBeforeStartDate) ? true : false;
        let isDuplicate = false;

        if (!valid || validationObjHasErrors) {
            const notificationNode = document.createElement('li');
            const message = errorMsg.indexOf('<') > 0 ? errorMsg.substr(0, errorMsg.indexOf('<')) : errorMsg;

            // Errors in date/time fields causes duplicate entries in notifications list.
            // Set flag if entry already exists.
            for (const notificationListItem of this.notificationsList.querySelectorAll('li')) {
                if (notificationListItem.innerHTML.trim().indexOf(message) > -1) {
                    isDuplicate = true;
                }
            }

            notificationNode.classList.add('notification__list-item');
            notificationNode.innerHTML = message;

            // Only add entry if it doesn't exists already
            if (!isDuplicate) {
                this.notificationsList.appendChild(notificationNode);
                this.notifications.classList.remove('notification--hidden');

                this.notificationsCounter += 1;
            }
        }
    }

    resetFieldValidation(field) {
        const errorMsg = field.parentNode.parentNode.querySelector('.form-section__message--error');
        const errorMsgClass = 'form-section__message--active';
        const errorClass = 'form__input-error';

        if (errorMsg) {
            field.classList.remove(errorClass);
            errorMsg.classList.remove(errorMsgClass);
        }
    }

    getRegExp(validationType) {
        const regexpText = /^[^<>~#}{^|]+$/;
        const regexpEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
        const regexpTel = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]{5,25}$/;
        const regexpDate = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.\d{4}$/;
        const regexpTime = /^[\d]{2}\:[\d]{2}$/;
        const regexpNumeric = /^\d*$/;
        const regexpSelect = /^[^<>`~})(_^{=|']+$/;
        const regexpZipCode = /^\d{4}$/;
        const regexpSocialSecurityNumber = /^\d{11}$/;
        const regexpImei = /^[\/\d -]*$/;

        switch (validationType.toLowerCase()) {
            case 'textarea':
            case 'text':
                return regexpText;
            case 'select':
                return regexpSelect;
            case 'email':
                return regexpEmail;
            case 'tel':
                return regexpTel;
            case 'number':
                return regexpNumeric;
            case 'date':
                return regexpDate;
            case 'zip':
                return regexpZipCode;
            case 'ssn':
                return regexpSocialSecurityNumber;
            case 'imei':
                return regexpImei;
            case 'time':
                return regexpTime;
            default:
                return regexpText;
        }
    }

    initDatePicker(element, data, index) {
        this.datePickers[index] = new Pikaday({
            field: element,
            blurFieldOnSelect: this.isDesktop ? false : true,
            format: 'DD.MM.YYYY',
            firstDay: 1,
            yearRange: 5,
            i18n: {
                previousMonth: data.months ? data.months.previous : 'Forrige',
                nextMonth: data.months ? data.months.next : 'Neste',
                months: data.months ? [data.months.jan, data.months.feb, data.months.mar, data.months.apr, data.months.may, data.months.jun, data.months.jul, data.months.aug, data.months.sep, data.months.oct, data.months.nov, data.months.dec] : ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
                weekdays: data.weekdays ? [data.weekdays.sun, data.weekdays.mon, data.weekdays.tue, data.weekdays.wed, data.weekdays.thu, data.weekdays.fri, data.weekdays.sat] : ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
                weekdaysShort: data.weekdays ? [data.weekdays.short.sun, data.weekdays.short.mon, data.weekdays.short.tue, data.weekdays.short.wed, data.weekdays.short.thu, data.weekdays.short.fri, data.weekdays.short.sat] : ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør']
            },
            onOpen: () => {
                element.addEventListener('keydown', this.handlePickerKeydown);
            },
            onClose: () => {
                element.removeEventListener('keydown', this.handlePickerKeydown);
            }
        });
    }

    handlePickerKeydown(e) {
        const evt = e || window.event;
        if (evt.which === Keyboard.up || evt.which === Keyboard.down) {
            evt.preventDefault();
        }
    }

    autocompletePlaceFromZip(zip, fieldToAutocomplete) {
        const input = document.getElementsByName(fieldToAutocomplete);

        if (zip && fieldToAutocomplete) {
            XHR.get('/api/zipcode/postalname/', zip).then((data) => {
                let json = JSON.parse(data);
                input[0].value = json.postalName.toUpperCase();
                this.handleErrors(input[0], input[0].parentNode.parentNode, true, false);
            }).catch((error) => {
                console.log(error);
            });
        }
    }
}
