import React from 'react';
import Guid from '../../../02-tools/guid/guid';
import FormHint from '../form-hint.jsx';
import FormMoreInfo from '../form-more-info.jsx';
import FormInputCheckboxOption from './form-input-checkbox-option.jsx';
import FieldFormError from '../form-error.jsx';


export default class FormInputCheckbox extends React.Component {
	constructor(props) {
        super(props);

        this.guid = Guid.create();

		this.state = {
            showInfo: false,
            oneIsChecked: false
		}
    }

    toggleInfo = (e) => {
        e.preventDefault();
	    this.setState(prevState => ({
		    showInfo: !prevState.showInfo
	    }));
    }

    handleChange = (e) => {
        if (this.props.Required) {
	        let oneIsChecked = false;
	        // Get all checkboxes
	        let checkboxes = document.querySelectorAll('[type="checkbox"][name="' + this.props.Name + '"]');
            for (let checkbox of checkboxes) {
                if (checkbox.checked) {
	                oneIsChecked = true;
                }
            }

            this.setState({
                oneIsChecked: oneIsChecked
            });

            if (this.props.HasError) this.props.ResetError(true);
			this.props.OnChange(this.props.Name, this.state.selectedOption);
            this.props.ValidateField(e);
        }
    }

    render() {
        const errorClass = "form-section__message form-section__message--error form-section__message--active";

		return (
            <fieldset className="form-section__fieldset">
                {!this.state.oneIsChecked ? <input name={this.props.Name} type="hidden" value="" data-label={this.props.Label} data-type="checkbox" required={this.props.Required} data-validation="checkbox" /> : null}
                {this.props.Label ?
                    <legend className="form-section__legend">{this.props.Required ? this.props.Label : this.props.Label + ' ' + this.props.Translations.Optional}</legend>
	                : null}
                {this.props.Hint ?
                    <FormHint Hint={this.props.Hint} />
                    : null}
                {this.props.InfoTrigger && this.props.Info ?
                    <FormMoreInfo Active={this.state.showInfo} Id={`more-info-${this.guid}`} Info={this.props.Info} BtnText={this.props.InfoTrigger} toggleInfo={this.toggleInfo} Translations={this.props.Translations} />
                    : null}
                {this.props.HasError ?
                    <FieldFormError errorMessage={this.props.ErrorMessage} stylename={errorClass} />
                    : null}
                {this.props.Options ?
                    this.props.Options.map((option, index) => (
                        <FormInputCheckboxOption key={`checkbox-option-${index}`} Name={this.props.Name} Value={option.value} UserInputValue={this.props.UserInputValue} Label={option.label} Checked={option.isChecked} handleChange={this.handleChange} />
	                ))
	            : null}
            </fieldset>
		);
	}
}