import React, { createRef } from 'react';
import FormStep from './form-step.jsx';
import XHR from '../../02-tools/xhr/xhr';
import Insight from '../../02-tools/insight/insight';
import { transformData } from './weapon-application-data-transform';
import { isValid } from './form-field-validation.js';
import SCROLLTO from '../../02-tools/scroll-to/scroll-to';
import idHelper from '../../02-tools/id-helper/idHelper.js';

export default class Form extends React.Component {
    constructor(props) {
        super(props);

        this.formData = props.FormData;
        this.formRef = createRef();

	    this.formDataInput = [];
	    this.formData.form.steps.map(() => (
            this.formDataInput.push([])
        ));

        this.state = {
            formErrors: [],
            showErrorSummary: false,
            activeStep: props.CurrentStep,
            loading: false
        }
    }

    componentDidMount() {
        let modal = document.getElementById('modal-2');
        if (modal) {
            modal.addEventListener('click', (e) => {
                if (e.target.contains(modal.querySelector('.modal-2__content'))) {
                    modal.classList.remove('modal-2--open');
                    modal.setAttribute('aria-hidden', true);
                    modal.setAttribute('aria-expanded', false);
                }
            });
        }
    }

    handleChange = (inputName, inputValue) => {
        this.setState({
            [inputName]: inputValue,
            errors: []
        });
    }

    handleFormDataInput = (stepIndex) => {
	    this.formDataInput[stepIndex] = [];
        let formData = new FormData(this.formRef.current);

        for (var pair of formData.entries()) {
            let formField = document.querySelector('[name="' + pair[0] + '"]');
	        let obj = {
                label: formField.getAttribute('data-label'),
                name: pair[0],
                value: pair[1].trim(),
                readonly: formField.getAttribute('readOnly'),
                required: formField.getAttribute('required'),
                type: formField.getAttribute('type'),
                datatype: formField.getAttribute('data-type'),
                validation: formField.getAttribute('data-validation')
	        };
            this.formDataInput[stepIndex].push(obj);
	    }
    }

    setErrorMessage = (errorType) => {
        switch (errorType) {
            case 'disabledselected':
	            return this.props.Translations.Errors.DisabledSelected;
            case 'requiredempty':
                return this.props.Translations.Errors.RequiredEmpty;
            case 'textarea':
            case 'text':
                return this.props.Translations.Errors.Text;
            case 'email':
                return this.props.Translations.Errors.Email;
            case 'tel':
            case 'phonenumber':
            case 'phone':
                return this.props.Translations.Errors.Phone;
            case 'number':
                return this.props.Translations.Errors.Number;
            case 'date':
                return this.props.Translations.Errors.Date;
            case 'zip':
            case 'postcode':
                return this.props.Translations.Errors.Zip;
            case 'postcodelocation':
	            return this.props.Translations.Errors.ZipLocation;
            case 'fnr':
                return this.props.Translations.Errors.Fnr;
            case 'time':
                return this.props.Translations.Errors.Time;
            case 'huntinglicense':
                return this.props.Translations.Errors.HuntingLicense;
            default:
                return this.props.Translations.Errors.General;
        }
    }

    handleErrors = (fieldsList) => {
        let fieldsToBeValidated = fieldsList.map((field) => {
            if (field.readonly != '') {
	            let validationObj = isValid(field);
                return {
                    name: field.name,
                    label: field.label,
                    isFieldValid: validationObj.valid,
                    value: field.value,
                    required: field.required == '' ? true : false,
                    type: field.type,
                    datatype: field.datatype,
                    errorType: validationObj.errorType
                };
            }
        });

        let filteredFieldsToBeValidated = fieldsToBeValidated
                                                .filter(value => value !== undefined && value.name !== null)
                                                .map(val => val);

        let errors = filteredFieldsToBeValidated.map((field) => {
            if (!field.isFieldValid) {
                return {
                    name: field.name,
                    label: field.label,
                    required: field.required,
                    datatype: field.datatype,
                    value: field.value,
                    hasError: true,
                    errorMessage: this.setErrorMessage(field.errorType)
                };
            }
        });

        return errors;
    }

    navigateToStep = (step, goingForward, nextBtnUrl) => {
        if (nextBtnUrl) {
	        this.handleFormDataInput(this.props.CurrentStep - 1);
	        const fieldsList = this.formDataInput[this.props.CurrentStep - 1];
	        let errors = this.handleErrors(fieldsList);
	        let errorsList = errors.filter(val => val !== undefined);
	        const filteredErrors = errorsList.length > 0 ? (
		        Array.from(new Set(errorsList.map(a => a.name)))
			        .map(name => {
				        return errorsList.find(a => a.name === name)
			        })) : []
	        this.setState({
                errors: filteredErrors
	        });


            if (errors.filter(value => value !== undefined).length > 0) {
		        this.setState({
			        formErrors: filteredErrors,
			        showErrorSummary: true
                }, () => {
                    let scrollToPos = document.querySelector('.js-errors').offsetTop + document.querySelector('.polno-header').offsetTop;
                    SCROLLTO.y(scrollToPos, 0, 'easeInOutQuint');
			        document.querySelector('.js-error-notification').focus();
		        });
		        return;
	        }

            window.location.href = nextBtnUrl;
        } else if (goingForward) {
            this.handleFormDataInput(this.props.CurrentStep - 1);
            const fieldsList = this.formDataInput[this.props.CurrentStep - 1];
            let errors = this.handleErrors(fieldsList);
            let errorsList = errors.filter(val => val !== undefined);
            const filteredErrors = errorsList.length > 0 ? (
                Array.from(new Set(errorsList.map(a => a.name)))
                    .map(name => {
                        return errorsList.find(a => a.name === name)
                    })) : []
            this.setState({
                errors: filteredErrors
            });

            if (errors.filter(value => value !== undefined).length > 0) {
                this.setState({
                    formErrors: filteredErrors,
                    showErrorSummary: true
                }, () => {
                    let scrollToPos = document.querySelector('.js-errors').offsetTop + document.querySelector('.polno-header').offsetTop;
                    SCROLLTO.y(scrollToPos, 0, 'easeInOutQuint');
	                document.querySelector('.js-error-notification').focus();
                });
                return;
            }

            this.props.setCurrentStep(step);
            if (this.props.CurrentStep !== step) {
                this.setState({
                    errors: [],
                    formErrors: [],
                    showErrorSummary: false
                });
            }

	        Insight.track('ga', { 'data': location.pathname + '/steg-' + step });
	        Insight.track('matomo', { 'data': location.pathname + 'steg-' + step });
        } else {
            this.props.setCurrentStep(step);
        }
    }

    toggleNavigation = () => {
	    this.props.toggleNavigation(this.refs.form);
    }


    openModal = (e) => {
        e.preventDefault();
        let modal = document.getElementById('modal-2');
        if (modal) {
	        let modalContent = modal.querySelector('.modal-2__content');
	        modal.classList.add('modal-2--open')
	        modal.setAttribute('aria-hidden', false);
            modal.setAttribute('aria-expanded', true);
	        modalContent.focus();
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        });

	    this.props.setSystemError(false);
        this.handleFormDataInput(this.props.CurrentStep - 1);
	    const fieldsList = this.formDataInput[this.props.CurrentStep - 1];
	    let errors = this.handleErrors(fieldsList);
	    let errorsList = errors.filter(val => val !== undefined);
	    const filteredErrors = errorsList.length > 0 ? (
		    Array.from(new Set(errorsList.map(a => a.name)))
			    .map(name => {
				    return errorsList.find(a => a.name === name)
			    })) : []
	    this.setState({
		    errors: filteredErrors
	    });

	    if (errors.filter(value => value !== undefined).length > 0) {
		    this.setState({
			    formErrors: filteredErrors,
                showErrorSummary: true,
                loading: false
		    });
		    return;
        }

	    Insight.track('ga', { 'data': location.pathname + '/skjema-innsendt' });
	    Insight.track('matomo', { 'data': location.pathname + 'skjema-innsendt' });
        let data = {};

	    this.formDataInput.flat().map((item) => {
		    data[item.name] = item.value;
        });

        if (this.formData.form.contactInformation?.ErReservert !== undefined) {
	        data['krr-reserved'] = this.formData.form.contactInformation.ErReservert;
        }

        // Data transformation for weapon application
        if ((data['form-type'] && data['form-type'].toUpperCase() === 'PIT-998') ||
            (data['form-type'] && data['form-type'].toUpperCase() === 'PIT-999')) {
            data = transformData(data);
        }

        XHR.post(this.formData.form.action, JSON.stringify(data), 'application/json').then((response) => {
	        Insight.track('ga', { 'data': location.pathname + '/skjema-kvittering' });
	        Insight.track('matomo', { 'data': location.pathname + 'skjema-kvittering' });
            let jsonResponse = JSON.parse(response);
            this.props.showReceipt({ ...data, ...{ 'district': jsonResponse.district }}, this.formDataInput);
            this.setState({
                loading: false
            });
        }).catch(() => {
            Insight.track('ga', { 'data': location.pathname + '/skjema-error/innsending-feilet' });
            Insight.track('matomo', { 'data': location.pathname + 'skjema-error/innsending-feilet' });
	        this.props.setSystemError(true);
            this.setState({
		        loading: false
	        });
        });
    }

    setFormErrors = (field) => {
        let errorFields = this.state.formErrors;
        let error = { name: field };
        if (errorFields.filter(value => value.name == error.name).length == 0) errorFields.push(error);

        this.setState({
            formErrors: errorFields,
            showErrorSummary: false
        });
    }

    removeFieldError = (field) => {
        this.setState(({formErrors}) => ({
            formErrors: formErrors.length > 0 ? formErrors.filter(value => value.name !== field) : formErrors
        }));
    }

    render() {
        let errors = this.state.formErrors;

        return (
            <>
                { errors.length > 0 && this.state.showErrorSummary ?
                    <section className="row stack-large js-errors">
                        <div className="xsmall-12 medium-8 large-6 xlarge-5 column">
                            <div className="notification notification--error js-error-notification" role="alert" tabIndex="-1">
                                <h2 className="notification__heading" id={idHelper.createId(this.props.Translations.Errors.ErrorSummaryHeading)}>{this.props.Translations.Errors.ErrorSummaryHeading}</h2>
                                <ul className="notification__list js-error-notifications-list" aria-live="assertive" aria-relevant="additions removals">
                                    {errors.map((error, index) => <li className="notification__list-item" key={index}>{error.label}</li>)}
                                </ul>
                            </div>
                        </div>
                    </section> : null}
                <form ref={this.formRef} className="form-section__form" onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="row">
                        {this.formData.form.steps.map((step, index) => (
                            <FormStep
                                key={`form-step-${index}`}
                                Step={step}
                                ActiveStep={this.props.CurrentStep}
                                TotalSteps={this.props.TotalSteps}
                                StepIndex={index + 1}
                                ShowNavigation={this.props.ShowNavigation}
                                FormDataInput={this.formDataInput}
                                FormSteps={this.formData.form.steps}
                                Errors={this.state.errors}
                                setFormErrors={this.setFormErrors}
                                removeFieldError={this.removeFieldError}
                                openModal={this.openModal}
                                navigateToStep={this.navigateToStep}
                                toggleNavigation={this.toggleNavigation}
                                handleChange={this.handleChange}
                                handleSubmit={this.handleSubmit}
                                setErrorMessage={this.setErrorMessage}
                                setGlobalError={this.setGlobalError}
                                Translations={this.props.Translations}
                                Loading={this.state.loading}
                                ShowSystemError={this.props.ShowSystemError}
                            />
                        ))}
                    </div>
                </form>
            </>
        );
    }
}
