import React from 'react';
import Autocomplete from '../autocomplete/autocomplete.jsx';
import GeoLocation from '../../02-tools/geolocation/geolocation';
import OrgUnits from '../../02-tools/org-units/org-units';
import ErrorHandler from '../../02-tools/error-handler/error-handler';
import Insight from '../../02-tools/insight/insight';
import idHelper from '../../02-tools/id-helper/idHelper.js';

export default class FindOrgUnit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      SearchTitle: null,
      Loading: false,
      Results: [],
      Error: null,
      SearchText: '',
      id: null
    };

    this.page = 1;
    this.pages = 0;
  }

  componentDidUpdate() {
    if (!this.state.Loading) {
      const results = document.querySelector(".find-org-unit__list-group");

      const length = (this.page - 1) * (this.props.PageSize || 5);
      const item = results?.children[length];
      item?.querySelector("a")?.focus();
    }
  }

  onGpsClick = (cumulate = false) => {
    this.setState({
      Loading: true,
    });

    cumulate ? (this.page = this.page) : (this.page = 1);

    GeoLocation.getGeoLocation()
      .then((position) => {
        const params =
          'latitude=' +
          position.coords.latitude +
          '&longitude=' +
          position.coords.longitude +
          '&pageSize=' +
          this.props.PageSize +
          '&page=' +
          this.page;
        this._runPromise(OrgUnits.findUnit(params), cumulate);
        Insight.track('ga', {
          isEvent: true,
          category: 'Finn',
          action: 'Finn - Søk',
          label: 'Finn - Søk - Din posisjon',
          value: 0,
        });
        Insight.track('matomo', {
          isEvent: true,
          category: 'Finn',
          action: 'Finn - Søk',
          name: 'Finn - Søk - Din posisjon',
          value: 0,
        });
      })
      .catch((error) => {
        this.setState({
          Loading: false,
          Results: [],
          Error: ErrorHandler.handle({
            data: error,
            globalText: this.props.Translations.Errors,
          }),
        });
      });
  };

  onSubmit = (value, id, cumulate = false) => {
    this.setState({
      Loading: true,
      id
    });

    cumulate ? (this.page = this.page) : (this.page = 1);

    if (id && id !== '') {
      this._runPromise(
        OrgUnits.findUnitFromId(
          id + '?pageSize=' + this.props.PageSize + '&page=' + this.page
        ),
        cumulate
      );
    } else {
      this._runPromise(
        OrgUnits.findUnitFromAddress(
          value + '?pageSize=' + this.props.PageSize + '&page=' + this.page
        ),
        cumulate
      );
    }

    Insight.track('ga', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Søk',
      label: 'Finn - Søk - ' + value,
      value: 0,
    });
    Insight.track('matomo', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Søk',
      name: 'Finn - Søk - ' + value,
      value: 0,
    });
  };

  onShowMore = () => {
    this.page = this.page + 1;
    this.state.SearchText
      ? this.onSubmit(this.state.SearchText, this.state.id, true)
      : this.onGpsClick(true);

    Insight.track('ga', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Vis flere',
      label: 'Finn - Vis flere - ' + this.page,
      value: 0,
    });
    Insight.track('matomo', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Vis flere',
      name: 'Finn - Vis flere - ' + this.page,
      value: 0,
    });
  };

  _runPromise = (promise, cumulate = false) => {
    promise
      .then((data) => {
        let json = JSON.parse(data);
        let results = this.state.Results;

        this.pages = Math.ceil(json.totalHits / json.pageSize);

        if (cumulate) {
          results.units = results.units.concat(json.units);
        }

        this.setState({
          Loading: false,
          SearchTitle: json.searchAddress,
          Results: cumulate ? results : json,
          Error: null,
        });
      })
      .catch((error) => {
        this.setState({
          Loading: false,
          Results: [],
          Error: ErrorHandler.handle({
            data: error,
            globalText: this.props.Translations.Errors,
          }),
        });
      });
  };

  getDistance = (distanceInMeters) => {
    const showAsMeters = distanceInMeters < 1000;
    let distance = showAsMeters
      ? Math.round(distanceInMeters) + ' m'
      : Math.round(distanceInMeters / 1000) + ' km';
    if (distanceInMeters === 0) {
      distance = '–';
    }

    return distance;
  };

  updateSearchText = (value) => {
    this.setState({
      SearchText: value,
    });
  };

  render() {
    return (
      <div className='row stack-large-fixed layout--alignment'>
        <div className={this.props.GridClasses}>
          <h2 className={this.props.HeadingSize} id={idHelper.createId(this.props.Title)}>{this.props.Title}</h2>
          <section
            className='find-org-unit'
            data-gtm-component='find'
            data-gtm-category={this.props.GTM.Category}
            data-gtm-action={this.props.GTM.Action}
            data-testid='find-org-unit'
          >
            {this.props.Summary ? (
              <p className='find-org-unit__summary'>{this.props.Summary}</p>
            ) : null}
            <Autocomplete
              Translations={this.props.Translations}
              SearchTitle={this.state.SearchTitle}
              onGpsClick={this.onGpsClick}
              onSubmit={this.onSubmit}
              onShowMore={this.onShowMore}
              updateSearchText={this.updateSearchText}
              Loading={this.state.Loading}
              Error={this.state.Error}
              HasPositionOption={true}
            />

            <div
              className={`find-org-unit__results ${
                this.state.Results.units
                  ? ''
                  : 'find-org-unit__results--no-results'
              }`}
              aria-live='polite'
            >
              {this.state.Results.units ? (
                <div>
                  <h3 className='list-group__legend' id={idHelper.createId(this.props.Translations.Place)}>
                    {this.props.Translations.Place}
                    {/*
                    <span className='list-group__label'>
                      {this.props.Translations.Distance}
                    </span>
                    */}
                  </h3>
                  <ol className='list-group list--reset-style find-org-unit__list-group'>
                    {this.state.Results.units.map((result, index) => (
                      <li key={index} className='list-group__item'>
                        {/*
                        <span className='list-group__label list-group__item--label' aria-hidden>
                          {this.getDistance(result.distance)}
                        </span>
                        */}
                        <a href={result.url} className='list-group__button'>
                          {result.name}
                        </a>
                        {result.mapUrl ? (
                          <a
                            href={result.mapUrl}
                            className='list-group__link weight--normal'
                            target='_blank'
                            aria-label={`${this.getDistance(result.distance)} ${this.props.Translations.To} ${result.name}. ${this.props.Translations.Map}`}
                          >
                            {this.props.Translations.Map}
                          </a>
                        ) : null}
                      </li>
                    ))}
                  </ol>
                  {this.pages > this.page ||
                  (this.page > 1 && this.state.Loading) ? (
                    <button
                      className={`show-more ${
                        this.state.Loading ? 'show-more--loading' : ''
                      }`}
                      disabled={this.state.Loading ? true : false}
                      aria-busy={this.state.Loading ? true : false}
                      onClick={this.onShowMore}
                    >
                      <span
                        className='show-more__loading-icon'
                        role='presentation'
                      ></span>
                      <span
                        className='show-more__more-icon'
                        role='presentation'
                      ></span>
                      <span className='show-more__btn-text'>
                        {this.props.Translations.ShowMore}
                      </span>
                    </button>
                  ) : null}
                </div>
              ) : null}
            </div>
          </section>
        </div>
      </div>
    );
  }
}
