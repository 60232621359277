import React from 'react';
import idHelper from '../../02-tools/id-helper/idHelper';

/*type Props = {
  Label: Array<Object>;
  Parameter: Array<Object>;
	ServiceAreasParam: String;
  Items: Array<Object>;
  Loading: Boolean;
	Translations: Array<Object>;
};*/

export default class FilterPanelVVR extends React.Component {
  constructor(props) {
    super(props);
  }

  check = (e) => {
    let indexOfItem = this.props.Items.findIndex(i => i.Id == e.target.value);

    if (indexOfItem >= 0) {
      this.props.Items[indexOfItem].Selected = !(this.props.Items[indexOfItem].Selected);
    }

    this.props.filter(e.target.name, e.target.value, e.target.dataset.param, e.target.checked);
  }

	listItemMarkup = (item) => {
		return (
			<li className="xsmall-12 medium-4 column" key={item.Name} role="text">
				<label className="form__label" aria-hidden={item.NumberOfItems === 0 ? true : false}>
					<input className="form__checkbox form__checkbox--filter"
						type="checkbox"
						onChange={this.check.bind(this)}
						name={this.props.Parameter}
						value={item.Id}
						data-param={item.Name}
            id={`Filter-${this.props.Parameter}-${item.Id}`}
						checked={item.Selected ? 'checked' : ''}
						disabled={item.NumberOfItems === 0 || this.props.Loading ? 'disabled' : ''} />
					<span className="form__label-text form__label--offset">
            {item.Name}&nbsp;(<span aria-label={`${this.props.Translations.NumberOfItemsPrefix} ${item.NumberOfItems} ${this.props.Translations.NumberOfItemsPostfix}`}>{item.NumberOfItems}</span>)
					</span>
				</label>
			</li>
		);
	};

  render() {
    const p = this.props;
    const serviceAreas = [];
    const training = [];

    p.Items.sort((a, b) => {
	    const nameA = a.Name;
	    const nameB = b.Name;

	    if (nameA < nameB) return -1;
	    if (nameA > nameB) return 1;

	    return 0;
    });

    p.Items.map((item) => {
      if (item.Group === p.ServiceAreasParam) {
				serviceAreas.push(
			    this.listItemMarkup(item)
		    );
      } else {
		    training.push(
          this.listItemMarkup(item)
		    );
      }
    });

    return (
      <div className={`filter__panel${p.selected ? '' : ' filter__panel--hidden'}`}>
	      <div className="xsmall-12 medium-12 column" id={`${p.Label}Panel`} role="tabpanel" aria-labelledby={`${p.Label}Tab ${p.Label}TabMobile`} aria-hidden={!this.props.selected}>
		      {serviceAreas.length ?
            <section className="filter__choices-section">
              <h2 className="filter__item-choices-heading" id={idHelper.createId(p.Translations.VVR.BusinessFields)}>{p.Translations.VVR.BusinessFields}</h2>
			        <ul className="filter__item-choices filter__item-choices--sectioned list--reset-style-type row">{serviceAreas}</ul>
						</section>
	        : null}
		      {training.length ?
            <section className="filter__choices-section">
              <h2 className="filter__item-choices-heading" id={idHelper.createId(p.Translations.VVR.Training)}>{p.Translations.VVR.Training}</h2>
			        <ul className="filter__item-choices filter__item-choices--sectioned list--reset-style-type row">
			          {training}
              </ul>
						</section>
	        : null}
		      <div className="filter__btn-container">
			      <button className="btn btn--positive btn--small" onClick={p.CloseTabs}>{p.Translations.Close}</button>
		      </div>
	      </div>
      </div>
    );
  }
}
