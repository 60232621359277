import XHR from "../../02-tools/xhr/xhr";

export default class SearchResult {
    constructor(element) {
        const numberOfHits = element.dataset.hits;
        const query = element.dataset.query;
        const proxyPath = element.dataset.proxyPath;

        this.suggestion = element.querySelector(".js-search-suggestion");
        this.suggestionWord = element.querySelector(
            ".js-search-suggestion-word",
        );
        this.related = element.querySelector(".js-search-related");
        this.relatedWords = element.querySelector(".js-search-related-words");
        this.spellcheckUrl = `${proxyPath}_spellcheck?query={0}&size=1&hits_cutoff={1}`;
        this.relatedSearchTermsUrl = `${proxyPath}_didyoumean?query={0}&size=3&hits_cutoff={1}`;

        if (this.suggestion && this.suggestionWord && numberOfHits === "0") {
            this.spellcheck(query, numberOfHits);
        } else if (this.related && this.relatedWords && numberOfHits !== "0") {
            this.relatedQuery(query, numberOfHits);
        }
    }

    spellcheck(query, hitsCutoff) {
        const url = this.spellcheckUrl
            .replace("{0}", encodeURIComponent(query))
            .replace("{1}", hitsCutoff);

        XHR.get(url, "")
            .then((data) => {
                const json = JSON.parse(data);

                if (json && json.length > 0) {
                    const suggestion = json[0];
                    this.suggestionWord.innerHTML = `&laquo;<a class="search-result__searchword-link" href="?SearchText=${suggestion}">${suggestion}</a>&raquo;`;
                    this.suggestion.classList.add(
                        "search-result__suggestion--visible",
                    );
                    this.suggestion.setAttribute("aria-hidden", false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    relatedQuery(query, hitsCutoff) {
        const url = this.relatedSearchTermsUrl
            .replace("{0}", encodeURIComponent(query))
            .replace("{1}", hitsCutoff);

        XHR.get(url, "")
            .then((data) => {
                const json = JSON.parse(data);

                if (json && json.length) {
                    const list = json.map(
                        (hit) =>
                            `&laquo;<a class="search-result__searchword-link" href="?SearchText=${hit}">${hit}</a>&raquo;`,
                    );
                    this.relatedWords.innerHTML = list.join(", ");
                    this.related.classList.add(
                        "search-result__related--visible",
                    );
                    this.related.setAttribute("aria-hidden", false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
