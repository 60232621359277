import XHR from '../../02-tools/xhr/xhr';

const lang = (typeof window === 'object') ? document.documentElement.dataset.userLangChoice : 'nb';
const apiUrl = '/api/orgunit/v2';

export default class OrgUnits {
  static findUnit(address) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findunit/' + lang + '?', encodeURI(address)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findUnitFromId(id) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findunitid/' + lang + '/', encodeURI(id)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findUnitFromAddress(address) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findunit/' + lang + '/', encodeURI(address)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findService(address) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findservice/' + lang + '?', encodeURI(address)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findServiceFromId(id) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findserviceid/' + lang + '/', encodeURI(id)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findServiceFromAddress(address) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findservice/' + lang + '/', encodeURI(address)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findMunicipalityService(address) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findmunicipalityservice/' + lang + '?', encodeURI(address)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findMunicipalityServiceFromId(id) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findmunicipalityserviceid/' + lang + '/', encodeURI(id)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findMunicipalityServiceFromAddress(address) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findmunicipalityservice/' + lang + '/', encodeURI(address)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findDistrictService(address) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/finddistrictservice/' + lang + '?', encodeURI(address)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findDistrictServiceFromId(id) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/finddistrictserviceid/' + lang + '/', encodeURI(id)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findDistrictServiceFromAddress(address) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/finddistrictservice/' + lang + '/', encodeURI(address)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findGeneralOfficeServiceFromAddress(address) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findgeneralofficeservice/' + lang + '/', encodeURI(address)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static findGeneralOfficeServiceFromId(id) {
    return new Promise((resolve, reject) => {
      XHR.get(apiUrl + '/findgeneralofficeserviceid/' + lang + '/', encodeURI(id)).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
