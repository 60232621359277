export const getRegExp = (validationType) => {
    const regexpText = /^[^<>~#}{^|]+$/;
    const regexpEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    const regexpTel = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]{5,25}$/;
    const regexpDate = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.\d{4}$/;
    const regexpTime = /^[\d]{2}\:[\d]{2}$/;
    const regexpNumeric = /^\d*$/;
    const regexpSelect = /^[^<>`~})(_^{=|']+$/;
    const regexpZipCode = /^\d{4}$/;
    const regexZipAndLocation = /^\d{4}(\s[^<>~#}{^|0-9\s]+)+$/;
    const regexpSocialSecurityNumber = /^\d{11}$/;
    const regexpHuntingLicenseNumber = /^(\d{6}|\d{8})$/;

    switch (validationType.toLowerCase()) {
        case 'textarea':
        case 'text':
            return regexpText;
        case 'select':
            return regexpSelect;
        case 'email':
            return regexpEmail;
        case 'tel':
        case 'phonenumber':
        case 'phone':
            return regexpTel;
        case 'number':
            return regexpNumeric;
        case 'date':
            return regexpDate;
        case 'zip':
        case 'postcode':
            return regexpZipCode;
        case 'postcodelocation':
            return regexZipAndLocation;
        case 'fnr':
            return regexpSocialSecurityNumber;
        case 'time':
            return regexpTime;
        case 'huntinglicense':
            return regexpHuntingLicenseNumber;
        default:
            return regexpText;
    }
}

// Copied and updated from form-section.js
export const isValid = (field) => {
    if (!field) {
        return {valid: false, errorType: 'nofield'}
    }
    const validationType = field.dataset ? (field.dataset.validation || field.datatype || field.type || 'text') : (field.validation || field.datatype || field.type || 'text');
    const required = field.required === '' || field.required == true ? true : false;
    const isVisible = (field.type !== 'hidden');
    let value = field.value.trim();
    let isSelectValueDisabled = false;

    // VALIDATE ALL
    // Required = ""
    // Non-required = null

    // VALIDATE SINGLE
    // Required = true
    // Non-required = false

    isSelectValueDisabled = (validationType === 'select' && !field.value ? true : false);

    if (!isVisible && field.datatype !== 'radio' && field.datatype !== 'select' && field.datatype !== 'checkbox') {
        // Hidden fields are always valid
        return {valid: true, errorType: null};
    } else if (isSelectValueDisabled) {
        // Disabled fields are never valid
        return {valid: false, errorType: 'disabledselected'};
    } else if (!required && value.length === 0) {
        // Non-required fields without input are valid
        return {valid: true, errorType: null};
    } else if (required && value.length === 0) {
        // Required fields wihtout input are invalid
        return {valid: false, errorType: 'requiredempty'};
    } else if (value.length > 0) {
        // All fields with input are regexp tested
        return {valid: getRegExp(validationType).test(value), errorType: validationType};
    }

    return false;
}
