import React from 'react';
import PropTypes from 'prop-types';
import FilterSorting from '../filter/filter-sorting.jsx';
import RoleCard from '../role/role-card.jsx';
import idHelper from '../../02-tools/id-helper/idHelper.js';

export default class ContactPointsFilterResult extends React.Component {
    constructor(props) {
        super(props);
    }

    static defaultProps = {
        SelectedFilterItems: {}
    };

    render() {
        const p = this.props;
        var resultString = this.props.Translations.Results;
        resultString = resultString.replace('{0}', p.TotalItems);
        return (
            <div className="column xsmall-12">
                <div className="row stack-regular">
                    <div className="column xsmall-12 filter__hits-column">
                        <div className="filter__results-toolbar">
                            <h2 className="filter__hits" aria-hidden="true" id={idHelper.createId(resultString)}>{resultString}</h2>
                            <FilterSorting Translations={this.props.Translations} SortItems={this.props.SortItems} SortParam={this.props.SortParam} updateParams={this.props.updateParams} />
                        </div>
                    </div>
                </div>
                <div className="row stack-large">
                    <div className="xsmall-12 medium-8 xlarge-6 column">
                        <div className="filter__results" aria-live="polite" aria-atomic="true">
                            {(p.Items.length) ? p.Items.map((item, index) => (
                                <RoleCard
                                    key={index}
                                    HasDistrictAsTitle={true}
                                    HasFunctionAsTitle={true}
                                    LinkUrl={item.Url}
                                    ResponsibleUnit={item.ResponsibleUnit}
                                    Function={item.Function}
                                    Responsibility={item.Responsibility}
                                    Email={item.Email}
                                    Phone={item.Phone ? item.Phone.replace(/ /g, '') : ''}
                                    PhoneDisplay={item.Phone}
                                    Mobile={item.Mobile ? item.Mobile.replace(/ /g, '') : ''}
                                    MobileDisplay={item.Mobile}
                                    Translations={p.Translations}
                                    NoSMS={item.NoSms}
                                    Availability={item.Availability} />
                            )) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ContactPointsFilterResult.propTypes = {
	Title: PropTypes.string,
	SortItems: PropTypes.array,
	SortParam: PropTypes.string,
	Translations: PropTypes.object
};
