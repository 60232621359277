import guid from "../guid/guid";

class IdHelper {
  createId(title) {
    return title && typeof title === 'string'
      ? this._removeSpecialCharacters(this._replaceUniqueLetters(this._replaceSpacesWithHyphen(title.trim().toLowerCase())))
      : null;
  }

  _removeSpecialCharacters(title) {
    return title.replace(/[^a-zæøå0-9\s-]/gmi, "") || guid.create();
  }

  _replaceSpacesWithHyphen(title) {
    return title.replace(/\s/gmi, "-");
  }

  _replaceUniqueLetters(title) {
    return title
      .replace(/æ/gmi, "ae")
      .replace(/ø/gmi, "ø")
      .replace(/å/gmi, "aa");
  }
}

export default new IdHelper();
