import React from 'react';

export default class FormContextInfo extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={`form-section__context-info${this.props.Position ? `--${this.props.Position}` : ''}`} dangerouslySetInnerHTML={{ __html: this.props.Message }}></div>
    )
  }
}