import React from "react";

import EventMessage from "./event-message.jsx";
import EventMessageHigh from "./event-message-high.jsx";

/*type Props = {
  Data: Array<Object>;
  IsSubPage: Boolean;
  Translations: Array<Object>;
};*/

export default class Event extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const p = this.props;
        const eventPages = p.Data.eventpages.length ? p.Data.eventpages : [];
        let events = [];
        let criticalityHigh = [];
        let criticalityLow = [];
        let criticalityInfo = [];
        let criticalityHighAndLow = [];

        if (eventPages.length) {
            eventPages.map((event, index) => {
                switch (event.criticality) {
                    case "Information":
                        // Only add to array of events that will be displayed if it is a sub page and
                        // show on sub pages is set to true OR if its not a sub page
                        if (
                            (p.IsSubPage && event.showOnChildPages) ||
                            !p.IsSubPage
                        ) {
                            criticalityInfo.push(event);
                        }
                        break;
                    case "Low":
                        if (
                            (p.IsSubPage && event.showOnChildPages) ||
                            !p.IsSubPage
                        ) {
                            criticalityLow.push(event);
                        }
                        break;
                    case "High":
                        if (
                            (p.IsSubPage && event.showOnChildPages) ||
                            !p.IsSubPage
                        ) {
                            criticalityHigh.push(event);
                        }
                        break;
                }
            });
        } else {
            return null;
        }

        // If there are more than one event with criticality high or low, we display them all as low.
        // Events with criticality info are always rendered by them self.
        criticalityHighAndLow = criticalityHigh.concat(criticalityLow);

        if (criticalityHighAndLow.length > 1) {
            criticalityHigh = [];
            criticalityLow = criticalityHighAndLow;
        }

        // When on subpages, events with both criticality high and low should be presented as low
        if (p.IsSubPage) {
            criticalityLow = criticalityHighAndLow;
            criticalityHigh = [];
        }

        if (criticalityHigh.length) {
            events.push(
                <EventMessageHigh
                    Translations={p.Translations}
                    Events={criticalityHigh}
                    RootClass="event"
                    key="high"
                />
            );
        }

        if (criticalityLow.length) {
            events.push(
                <EventMessage
                    Translations={p.Translations}
                    Events={criticalityLow}
                    RootClass="event event--small"
                    key="low"
                />
            );
        }

        if (criticalityInfo.length) {
            events.push(
                <EventMessage
                    Translations={p.Translations}
                    Events={criticalityInfo}
                    RootClass="event event--small event--info"
                    key="info"
                />
            );
        }

        return <>{events}</>;
    }
}
