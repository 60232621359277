import React from 'react';
import Moment from 'moment';
import MetaData from '../metadata/metadata.jsx'
import FilterSorting from '../filter/filter-sorting.jsx';
import idHelper from '../../02-tools/id-helper/idHelper.js';

/*type Props = {
  Title: ?string;
  SortItems: Array<Object>;
  SortParam: String;
  Translations: Array<Object>;
};*/

export default class NewsFilterResult extends React.Component {
  constructor(props) {
    super(props);
  }

  static defaultProps = {
    SelectedFilterItems: {}
  };

  formatTime = (startTime, endTime) => {
    const start = Moment(startTime);
    const end = Moment(endTime);

    if (end.isValid()) {
      if (start.format('DD.MM.YY') === end.format('DD.MM.YY')) {
        return start.format('DD.MM.YY HH:mm') + '–' + end.format('HH:mm');
      }

      return start.format('DD.MM.YY HH:mm') + '–' + end.format('DD.MM.YY HH:mm');
    }

    return start.format('DD.MM.YY HH:mm');
  }

  render() {
    const p = this.props;
    var resultString = this.props.Translations.Results;
    resultString = resultString.replace('{0}', p.TotalItems);
    return (
      <div className="column xsmall-12">
	      <div className="row">
		      <div className="column xsmall-12 filter__hits-column">
			      <div className="filter__results-toolbar">
				      <h2 className="filter__hits" aria-hidden="true" id={idHelper.createId(this.props.Translations.Results)}>{resultString}</h2>
				      <FilterSorting Translations={this.props.Translations} SortItems={this.props.SortItems} SortParam={this.props.SortParam} updateParams={this.props.updateParams}/>
			      </div>
		      </div>
	      </div>
	      <div className="row">
		      <div className="column xsmall-12 medium-10 large-8">
			      {p.Items.length ?
              <ol className="filter__results list--reset-style-type" aria-live="polite" aria-atomic="true">
                {p.Items.map((item, index) => (
                  <li className="page-listing__item" key={index}>
	                  <article className="filter__article" role="text">
		                  <h2 className="h3 page-listing__title" id={idHelper.createId(item.Title)}>
			                  <a className="page-listing__link" href={item.Url}>{item.Title}</a>
		                  </h2>
		                  <MetaData RootClass="metadata metadata--subtle list--reset-style" PublishedDate={item.Published} ChangedDate={item.Changed} ContentType={item.IsOccurrencepage ? null : item.ContentType} ShowResponsibleUnits={true} ShowPublishedOrChangedDate={true} ShowDate={true} ResponsibleUnits={item.ResponsibleUnitReference} Translations={p.Translations} />
		                  {item.IsOccurrencepage ?
                        <ul className="page-listing__info-list">
                          {item.StartTime || item.EndTime ? <li className="page-listing__info-list-item"><span className="weight--medium">{p.Translations.When}</span> {this.formatTime(item.StartTime, item.EndTime)}</li> : null}
                          {item.FreeTextLocation || item.Location ? <li className="page-listing__info-list-item"><span className="weight--medium">{p.Translations.Where}</span> {item.FreeTextLocation ? item.FreeTextLocation : item.Location}</li> : null}
                          {item.MainIntro ? <li className="page-listing__info-list-item">{item.MainIntro}</li> : null}
                        </ul> :
	                      <p className="page-listing__excerpt">{item.MainIntro}</p>
                      }

		                  {(item.Themes && item.Themes.length) ? <FilterResultTags Items={item.Themes} UserSelectedCategories={p.SelectedFilterItems.ListOfCategoryFilterItemLists} /> : null}
	                  </article>
                  </li>
                ))}
              </ol>
            : null}
		      </div>
	      </div>
      </div>
    );
  }
}

class FilterResultTags extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const p = this.props;

    return (
      <ul className="tag-list list--reset-style-type">
        {p.Items.map((result, i) => {
          let tagIsSelected = false;

          if (p.UserSelectedCategories && p.UserSelectedCategories.length) {
            p.UserSelectedCategories[0].Items.map((item) => {
              if (item.Name === result) {
                tagIsSelected = true;
              }
            });
          }

          return (
            <li key={i} className={tagIsSelected ? 'tag-list__tag tag-list__tag--accented' : 'tag-list__tag'}>{result}</li>
          )
        })}
      </ul>
    )
  }
}
