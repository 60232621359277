import React from 'react';
import { Notification as PdsNotification } from '@politiet/pds';
import PropTypes from 'prop-types';

/**
 * @param Type (regular, inverted, positive, alert, warning, error)
 * @param Size (regular, small)
 * @param Subtle (true, false)
 * @param Hidden (true, false)
 * @param Inline (true, false)
 * @param Icon (bell, calendar, location, info)
 * @param Position(default, above, below)
 * @param Modifier
 * @param Heading
 * @param Message
 */
export default function Notification({ Heading, Message, Type, Size, Icon, Subtle, Hidden, Inline, Modifier }) {
    return (
        <PdsNotification
            type={Type}
            title={Heading}
            size={Size}
            icon={Icon}
            isSubtle={Subtle}
            isHidden={Hidden}
            isInline={Inline}
            className={Modifier}
        >
            <div dangerouslySetInnerHTML={{ __html: Message }} />
        </PdsNotification>
    )
};

Notification.propTypes = {
    Heading: PropTypes.string,
    Message: PropTypes.string,
    Type: PropTypes.oneOf(["alert", "error", "inverted", "success", "warning"]),
    Size: PropTypes.exact("small"),
    Icon: PropTypes.oneOf(["notifications", "calendar_today", "place", "info"]),
    Subtle: PropTypes.bool,
    Hidden: PropTypes.bool,
    Inline: PropTypes.bool,
    Modifier: PropTypes.string,
}
