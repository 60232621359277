import Insight from '../../02-tools/insight/insight';

export default class FormGuide {
  constructor(element) {
    this.guideForm = element.querySelector('.form-guide__form');
    this.guide = element.querySelector('.form-guide__list');
    this.loader = element.querySelector('.form-guide__loader');
    this.steps = element.querySelectorAll('.form-guide__list-item');
    this.urlParams = new URLSearchParams(window.location.search);
    const initialStepQueryParam = this.urlParams.get('steg1');

    if (!initialStepQueryParam) {
      this.loader.classList.add('form-guide__loader--hidden');
      this.guideForm.classList.remove('form-guide__form--loading');
    }

    // Save default radiobutton value to steps.
    for (let step of this.steps) {
      step.defaultRadio = step.querySelector('input:checked');
    }

    this.urlParams = new URLSearchParams(window.location.search);
    this.responses = element.querySelectorAll('.form-guide__response-item');
    this.findOrgUnit = element.querySelector('.form-guide__find-org-unit');
    this.activeStepIndex = 0;
    this.responseCode = 0;
    this.filterId = "";

    // Find adjacent form
    if (element.nextElementSibling.classList.contains('form-section')) {
      this.adjacentForm = element.nextElementSibling;
    } else {
      console.log('Could not find adjacent form');
    }

    // Next button 
    this.nextBtn = element.querySelector('.form-guide__next-btn');
    this.nextBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.next(element);
    });

    /*
    // Sort params
    this.urlParams.sort();
    this.stepQueryItems = [];

    // Remove non-step query params
    for (const key of this.urlParams.keys()) {
      key.startsWith('steg') ? this.stepQueryItems.push(key) : this.urlParams.delete(key);
    }

    if (this.stepQueryItems) this.sortSteps(this.stepQueryItems.reverse());
    */

    // Handle query string predefined answers
    if (initialStepQueryParam) {
      this.handleQueryStringAnswers(1, 'steg1', initialStepQueryParam);

      setTimeout(() => {
        this.loader.classList.add('form-guide__loader--hidden');
        this.guideForm.classList.remove('form-guide__form--loading');
      }, 150);
    }
  }

  next(element) {
    const index = this.activeStepIndex;

    let changeBtn = this.steps[this.activeStepIndex].querySelector('.form-guide__change-link');
    changeBtn.classList.remove('form-guide__hidden');
    
    changeBtn.addEventListener('click', (e) => {
      e.preventDefault();
      if (e.target.dataset.overrideid) {
        this.responseCode = e.target.dataset.overrideid;
      }
      this.activeStepIndex = index;
      this.hideResponse();
      this.hideAdjacentForm();
      this.resetGuide(index);
      this.setActive(this.steps[index]);
      this.findOrgUnit.classList.add('form-guide__hidden');
    });

    this.setInactive(this.steps[index]);

    const choice = this.steps[index].querySelector('input:checked');
    if (choice.dataset.id) {
      this.filterId = choice.dataset.id;
      let nextChangeBtn = this.steps[choice.dataset.next].querySelector('.form-guide__change-link');
      nextChangeBtn.dataset.overrideid = this.responseCode;
    }

    this.activeStepIndex = choice.dataset.next;

    if (choice.dataset.next == -1) {
      // Form is not valid, and should return error response
      this.nextBtn.parentElement.classList.add('form-guide__hidden');
      this.nextBtn.setAttribute('aria-hidden', 'true');
      this.findOrgUnit.classList.remove('form-guide__hidden');
      this.showResponse();
      Insight.track('gtm', { 'event': 'AnmeldelseFullførteSteg', 'category': 'Anmeldelse', 'data': this.getCompletedSteps(element)[0] });
      Insight.track('gtm', { 'event': 'AnmeldelseSvarSteg', 'category': 'Anmeldelse', 'data': this.getCompletedSteps(element)[1] });
      Insight.track('gtm', { 'event': 'AnmeldelseVisFinnDittPoliti', 'category': 'Anmeldelse', 'data': 'Vis Finn ditt politi' });

    } else if (choice.dataset.next == 0) {
      if (this.adjacentForm) {
        this.showAdjacentForm('.form-filter__item--' + this.filterId);
        Insight.track('gtm', { 'event': 'AnmeldelseFullførteSteg', 'category': 'Anmeldelse', 'data': this.getCompletedSteps(element)[0] });
        Insight.track('gtm', { 'event': 'AnmeldelseSvarSteg', 'category': 'Anmeldelse', 'data': this.getCompletedSteps(element)[1] });
        Insight.track('gtm', { 'event': 'AnmeldelseVisSkjema', 'category': 'Anmeldelse', 'data': 'Vis anmeldelsesskjema' });
      }

      this.nextBtn.parentElement.classList.add('form-guide__hidden');
      this.nextBtn.setAttribute('aria-hidden', 'true');
      this.showResponse();
    } else {
      this.steps[this.activeStepIndex].classList.remove('form-guide__hidden');
      this.steps[this.activeStepIndex].setAttribute('aria-hidden', 'false');
      this.steps[this.activeStepIndex].defaultRadio.focus();
    }

    // Handle query string predefined answers
    /*const stepNum = parseInt(choice.dataset.next) + 1;
    const stepQueryParamKey = 'steg' + stepNum;
    const stepQueryParamValue = this.urlParams.get(stepQueryParamKey);

    if (stepQueryParamValue) {
      this.handleQueryStringAnswers(stepNum, stepQueryParamKey, stepQueryParamValue);
    }*/
  }

  getCompletedSteps(element) {
    const completedGuideSteps = element.querySelectorAll('.form-guide__list .form-guide__list-item--inactive');
    let completedSteps = [];
    let stepHeadings = '';
    let stepAnswers = '';

    for (const step of completedGuideSteps) {
      const itemHeading = step.querySelector('h2').textContent;
      const itemLabel = step.querySelector('.form-section__input-group-item:not(.form-guide__hidden) .form__label-text').textContent;

      //stepHeadings += itemHeading + '|';
      //stepAnswers += itemLabel + '|';

      //We currently only need the last completed step and its answer, so therefore
      stepHeadings = itemHeading;
      stepAnswers = itemLabel;
    }

    completedSteps.push(stepHeadings);
    completedSteps.push(stepAnswers);

    return completedSteps;
  }

  setInactive(element) {
    element.classList.add('form-guide__list-item--inactive');
    let formGroups = element.querySelectorAll('.form__label');

    for (let item of formGroups) {
      let input = item.getElementsByTagName('input')[0];
      if(input.checked) {
        input.classList.add('form__item--hidden');
        input.setAttribute('aria-hidden', 'true');
        if (input.value != -1) {
          this.responseCode = input.value;
        }

      } else {
        item.classList.add('form-guide__hidden');
        item.setAttribute('aria-hidden', 'true');
      }
    }
  }

  setActive(element) {
    this.nextBtn.parentElement.classList.remove('form-guide__hidden');
    this.nextBtn.setAttribute('aria-hidden', 'false');
    element.classList.remove('form-guide__list-item--inactive');
    let changeBtn = element.querySelector('.form-guide__change-link');
    changeBtn.classList.add('form-guide__hidden');
    changeBtn.setAttribute('aria-hidden', 'true');

    let formGroups = element.querySelectorAll('.form__label');
    for (let item of formGroups) {
      let input = item.getElementsByTagName('input')[0];
      if (input.checked) {
        input.classList.remove('form__item--hidden');
        input.setAttribute('aria-hidden', 'false');
      } else {
        item.classList.remove('form-guide__hidden');
        item.setAttribute('aria-hidden', 'false');
      }
    }
  }

  resetGuide(index) {
    for (let i = this.steps.length-1; index < i; i--) {
      this.setActive(this.steps[i]);
      this.steps[i].classList.add('form-guide__hidden');
      this.steps[i].setAttribute('aria-hidden', 'true');
      // Reset radiobuttons
      let inputs = this.steps[i].querySelectorAll('input');
      for (let input of inputs) {
        if (input === this.steps[i].defaultRadio) {
          input.checked = true;
        } else {
          input.checked = false;
        }
      }
    }
  }

  showResponse() {
    for (let response of this.responses) {
      if (response.dataset.id == this.responseCode) {
        response.classList.remove('form-guide__hidden');
        response.setAttribute('aria-hidden', 'false');
        response.parentElement.classList.remove('form-guide__hidden');
        response.parentElement.setAttribute('aria-hidden', 'false');
      }
    }
  }

  hideResponse() {
    for (let response of this.responses) {
      response.classList.add('form-guide__hidden');
      response.setAttribute('aria-hidden', 'true');
      response.parentElement.classList.add('form-guide__hidden');
      response.parentElement.setAttribute('aria-hidden', 'true');
    }
  }

  showAdjacentForm(data) {
    this.adjacentForm.classList.remove('form-section--hidden');
    this.adjacentForm.setAttribute('aria-hidden', 'false');
    let filterItems = this.adjacentForm.querySelectorAll(data);

    let reportString = document.querySelector('input:checked[name="3"]').nextElementSibling;
    let typeofreport = this.adjacentForm.querySelector('#form-typeofreport-input');
    typeofreport.value = reportString.textContent;

    for (let item of filterItems) {
      if (item.classList.contains('form-section__form-group')) {
        item.classList.add('form-section__form-group--striped');
      }
      item.classList.remove('form-section--hidden');
      item.setAttribute('aria-hidden', 'false');
    }
  }

  hideAdjacentForm() {
    this.adjacentForm.classList.add('form-section--hidden');
    this.adjacentForm.setAttribute('aria-hidden', 'true');
    let filterItems = this.adjacentForm.querySelectorAll('.form-filter__item');

    for (let item of filterItems) {
      if (item.classList.contains('form-section__form-group')) {
        item.classList.remove('form-section__form-group--striped');
      }
      item.classList.add('form-section--hidden');
      item.setAttribute('aria-hidden', 'true');
    }
  }

  handleQueryStringAnswers(stepAnswered, queryParamKey, queryParamValue) {
    const queryChoiceLabel = this.guide.querySelector('[data-step="' + stepAnswered + '"] .form-section__input-group-item:nth-child(' + queryParamValue + ')');

    setTimeout(() => {
      if (queryChoiceLabel) {
        queryChoiceLabel.click();
        this.urlParams.delete(queryParamKey);
        this.nextBtn.click();
      }
    }, 100);
  }

  sortSteps(steps) {
    for (const step of steps) {
      const stepNum = step.replace('steg', '');
      const listItem = this.guide.querySelector('[data-step="' + stepNum + '"]');

      this.guide.insertBefore(listItem, this.guide.querySelector('.form-guide__list-item:first-child'));
    }

    const listItems = this.guide.querySelectorAll('.form-guide__list-item');

    for (const [index, item] of listItems.entries()) {
      if (index === 0) {
        item.classList.remove('form-guide__hidden');
        item.setAttribute('aria-hidden', false);
      } else {
        item.classList.add('form-guide__hidden');
        item.setAttribute('aria-hidden', true);
      }
    }
  }
}