import React from 'react';
import Params from '../../02-tools/params/params';
import Keyboard from '../../02-tools/keyboard/keyboard';
import Insight from '../../02-tools/insight/insight';

/*type Props = {
  SortItems: Array<Object>;
  SortParam: String;
  Translations: Array<Object>;
};*/

export default class FilterSorting extends React.Component {
  constructor(props) {
    super(props);

    const params = Params.getParams();
    let selectedValue = '';

    if (params[this.props.SortParam]) {
      this.props.SortItems.map((item) => {
        selectedValue =
          params[this.props.SortParam] === item.SortValue
            ? item.Name
            : selectedValue;
      });
    } else {
      selectedValue = this.props.SortItems[0].Name;
    }

    this.state = {
      isExpanded: false,
      selectedValue: selectedValue,
      selectedRef: '',
    };
  }

  toggleSort = () => {
    this.setState({
      isExpanded: this.state.isExpanded ? false : true,
    });
  };

  sort = (name, value, e) => {
    let params = Params.getParams();
    params[this.props.SortParam] = value;

    this.setState({
      selectedValue: name,
      isExpanded: false,
      selectedRef: e.target,
    });

    this.props.updateParams(params);
    this.toggleSortBtn.focus();
    Insight.track('ga', {
      isEvent: false,
      data: location.pathname + location.search,
    });
    Insight.track('matomo', {
      isEvent: false,
      data: location.pathname + location.search,
      title: document.title,
    });
  };

  focus = (e) => {
    this.setState({
      selectedRef: e.target,
    });
  };

  sortArray = (a, fn) => {
    var non_matches = [];
    var matches = a.filter(function (e, i, a) {
      var match = fn(e, i, a);
      if (!match) non_matches.push(e);
      return match;
    });
    return matches.concat(non_matches);
  };

  onSortKeyDown = (e) => {
    if (e.which === Keyboard.up) {
      e.preventDefault();
      if (
        this.state.selectedRef ===
        this.refs.listItems.firstElementChild.firstElementChild
      ) {
        this.refs.listItems.lastElementChild.firstElementChild.focus();
      } else {
        this.state.selectedRef.parentNode.previousElementSibling.firstElementChild.focus();
      }
    } else if (e.which === Keyboard.down) {
      e.preventDefault();
      if (
        this.state.selectedRef ===
        this.refs.listItems.lastElementChild.firstElementChild
      ) {
        this.refs.listItems.firstElementChild.firstElementChild.focus();
      } else {
        this.state.selectedRef.parentNode.nextElementSibling.firstElementChild.focus();
      }
    }
  };

  render() {
    const p = this.props;
    const params = Params.getParams();
    let sortItems = this.sortArray(
      p.SortItems,
      (e) => e.SortValue === params[this.props.SortParam]
    );
    sortItems.shift();

    let items = [];

    sortItems.map((item, index) => {
      items.push(
        <li
          className={
            index === 0
              ? 'filter__sort-list-item filter__sort-list-item--current'
              : 'filter__sort-list-item'
          }
          key={index}
        >
          <button
            className='filter__sort-btn'
            onClick={(e) => this.sort(item.Name, item.SortValue, e)}
            onFocus={(e) => this.focus(e)}
          >
            {item.Name}
          </button>
        </li>
      );
    });

    return (
      <div className='filter__sort'>
        <ul
          ref='listItems'
          onKeyDown={this.onSortKeyDown.bind(this)}
          className={
            this.state.isExpanded
              ? 'filter__sort-list filter__sort-list--expanded list--reset-style'
              : 'filter__sort-list list--reset-style'
          }
          aria-expanded={this.state.isExpanded ? true : false}
        >
          <li className='filter__sort-list-item'>
            <button
              ref={(btn) => {
                this.toggleSortBtn = btn;
              }}
              className='filter__sort-toggler'
              onClick={this.toggleSort.bind(this)}
              onFocus={(e) => this.focus(e)}
            >
              {p.Translations.Sort}:{' '}
              <span className='filter__sort-selected-value'>
                {this.state.selectedValue}
              </span>
              <span className='filter__sort-icon-arrow'></span>
            </button>
          </li>
          {items}
        </ul>
      </div>
    );
  }
}
