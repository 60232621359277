import React from "react";
import MetaData from "../metadata/metadata.jsx";

export default class EventItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <li className="column list--item-flex spacing--no-margin">
                <a
                    href={this.props.item.Url}
                    className="card card--event"
                    data-gtm-hook="news-list-home-link"
                >
                    <h3 className="h2 card__headline">
                        {this.props.item.Heading}
                    </h3>

                    <MetaData
                        PublishedDate={this.props.item.Metadata.PublishedDate}
                        ChangedDate={this.props.item.Metadata.ChangedDate}
                        ContentType={this.props.item.Metadata.ContentType}
                        EnhancedLabels={this.props.item.Metadata.EnhancedLabels}
                        ShowResponsibleUnits={
                            this.props.item.Metadata.ShowResponsibleUnits
                        }
                        ShowPublishedOrChangedDate={
                            this.props.item.Metadata.ShowPublishedOrChangedDate
                        }
                        ShowDate={this.props.item.Metadata.ShowDate}
                        ResponsibleUnits={
                            this.props.item.Metadata.ResponsibleUnits
                        }
                        RootClass={this.props.item.Metadata.RootClass}
                        Translations={this.props.item.Metadata.Translations}
                    />

                    <ul className="card__event-list list--reset-style">
                        <li className="card__event-list-item">
                            <span className="weight--medium">
                                {this.props.item.Translations.When}&nbsp;
                            </span>
                            <span aria-hidden="true">
                                {this.props.item.Time}
                            </span>
                            <span className="show-for-sr">
                                {this.props.item.Time +
                                    " " +
                                    this.props.item.Translations.To}
                            </span>
                        </li>
                        <li className="card__event-list-item">
                            <span className="weight--medium">
                                {this.props.item.Translations.Where}&nbsp;
                            </span>
                            {this.props.item.FreetextLocation
                                ? this.props.item.FreetextLocation
                                : item.props.item.Location}
                        </li>
                        <li className="card__event-list-item">
                            {this.props.item.Summary}
                        </li>
                    </ul>
                    <div className="card__event-date" aria-hidden="true">
                        <span className="card__event-day">
                            {this.props.item.StartTimeDay}
                        </span>
                        <span className="card__event-month">
                            {this.props.item.StartTimeMonth}
                        </span>
                    </div>
                </a>
            </li>
        );
    }
}
