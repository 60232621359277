export default class Toggler {
  constructor(element) {
    const target = document.getElementById(element.dataset.target);
    const targetHeading = target ? target.querySelector('h2, h3') : null;
    const icon = element.querySelector('.toggler__toggle-icon');
    const hiddenTargetClass = 'toggler__hidden';
    const expandedIconClass = 'toggler__toggle-icon--expanded';
    const expandedElementClass = 'toggler--expanded';

    if (targetHeading) {
      targetHeading.classList.add('show-for-medium');
    }

    element.addEventListener('click', () => {
      if (target.classList.contains(hiddenTargetClass)) {
        element.setAttribute('aria-expanded', true);
      } else {
        element.setAttribute('aria-expanded', false);
      }
      icon.classList.toggle(expandedIconClass);
      target.classList.toggle(hiddenTargetClass);
      element.classList.toggle(expandedElementClass);
    });
  }
}
