import React from 'react';
import Notification from '../notification/notification.jsx';
import XHR from '../../02-tools/xhr/xhr';
import Moment from 'moment';
import docCookies from '../../02-tools/cookies/cookies';
import Hash from 'content-hash';
import idHelper from '../../02-tools/id-helper/idHelper.js';

export default class IdStatusService extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHidden: true,
            notificationType: null,
            notificationHeading: null,
            notificationMsg: null,
            documentNumber: '',
            actor: '',
            requestTimestamp: '',
            referenceNumber: '',
            loading: false,
            timerIsDone: true,
            docNumError: false,
            apiError: false,
            actorError: false,
            showActorHelp: false
        }
    }

    componentDidMount() {
        const actorCookie = docCookies.getItem('POLNO-IdStatus-Actor');
        if (actorCookie) {
            this.setState({
                actor: Hash.decode(actorCookie)
            });
        }
    }

    formatMsg = (msg, data, translations) => {
        return `${msg.replace('#docnum#', data.documentNumber)}
				<p class="id-status-service__updated-date">${translations.dataDateMsg} ${Moment(data.lastHeartbeat).format('DD.MM.YY [kl.] HH:mm')}</p>`;
    }

    doCountdown = (timeout) => {
        setTimeout(() => {
            this.setState({
                timerIsDone: true
            });
        }, timeout);
    }

    doStatusCheck = (docNum, actor) => {
        let hashedActor = Hash.encode('sha2-512', actor);
        docCookies.setItem('POLNO-IdStatus-Actor', hashedActor, 7884000, null, 'politiet.no', true, 'strict');

        let CONSTANTS = {
            type: {
                MATCH: 'warning',
                NO_MATCH: 'regular',
                UNKNOWN_DOCUMENT: 'warning'
            },
            heading: {
                MATCH: this.props.IdNegativeHeading,
                NO_MATCH: this.props.IdPositiveHeading,
                UNKNOWN_DOCUMENT: this.props.IdUnknownHeading,
            },
            msg: {
                MATCH: this.props.IdNegative,
                NO_MATCH: this.props.IdPositive,
                UNKNOWN_DOCUMENT: this.props.IdUnknown
            }
        }

        XHR.post('/api/idstatus/check', '{"actor": "' + actor + '", "documentNumber": "' + docNum.toUpperCase() + '"}', 'application/json').then((data) => {
            const json = JSON.parse(data);
            this.setState({
                isHidden: false,
                documentNumber: json.documentNumber,
                requestTimestamp: json.requestTimestamp,
                referenceNumber: json.receipt,
                notificationType: CONSTANTS.type[json.status],
                notificationHeading: CONSTANTS.heading[json.status],
                notificationMsg: this.formatMsg(CONSTANTS.msg[json.status], json, this.props.Translations),
                loading: false
            });
        }).catch((error) => {
            this.setState({
                isHidden: true,
                loading: false,
                apiError: true
            });
            console.log(error);
        });
    }

    resetForm = () => {
        this.setState({
            docNumError: false,
            apiError: false,
            actorError: false,
            isHidden: true,
            showActorHelp: false
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.resetForm();

        let docNum = this.refs.docId.value;
        let actor = this.state.actor;
        if (!this.props.ShowActor) {
            actor = "PUBLIC_SECTOR";
        }

        if (this.validateInput(docNum, actor)) {
            this.setState({
                loading: true,
                timerIsDone: false
            });
            this.doCountdown(750);
            this.doStatusCheck(docNum, actor);
        }
    }

    validateInput = (docNum, actor) => {
        this.setState({
            docNumError: docNum === '' ? true : false,
            actorError: actor === '' ? true : false
        });

        return (docNum && actor);
    }

    validateActor = (actor) => {
        this.setState({
            actorError: actor === '' ? true : false
        });
    }

    validateDocNum = (docNum) => {
        this.setState({
            docNumError: docNum === '' ? true : false
        });
    }

    onActorChange = (e) => {
        this.setState({
            actor: e.target.value
        });
        this.validateActor(e.target.value);
    }

    onDocumentNumberChange = (e) => {
        this.validateDocNum(this.refs.docId.value);
    }

    toggleActorHelp = (e) => {
        e.preventDefault();
        this.setState({
            showActorHelp: !this.state.showActorHelp
        });
    }

    printPdf = (e, title, timestamp) => {
        e.preventDefault();
        let webTitle = document.title;
        let printTitle = title + ' ' + Moment(timestamp).format('DD-MM-YYYY HH.mm');

        document.title = printTitle;
        print();
        document.title = webTitle;
    }

    render() {
        return (
            <section className="id-status-service">
                <form className="id-status-service__form print--hidden" autoComplete="off">

                    {this.props.ShowActor ?
                        <fieldset className="form-section__fieldset">
                            <legend className="form-section__legend">{this.props.IdStatusActorLabel}
                                {this.props.ActorHelpText ?
                                    <a href="#tooltip-actor" className="form-section__info-btn" role="button" aria-controls="tooltip-actor" aria-expanded={this.state.showActorHelp} onClick={(e) => this.toggleActorHelp(e)}>
                                        <span className="show-for-sr">{this.props.Translations.infoBtn}</span>
                                    </a>
                                    : null}
                            </legend>
                            {this.props.ActorHelpText ?
                                <span className={`form-section__info${this.state.showActorHelp ? ' form-section__info--active' : ''}`} id="tooltip-actor" role="tooltip" aria-hidden={!this.state.showActorHelp} aria-expanded={this.state.showActorHelp}>
                                    <span className="form-section__info-text" dangerouslySetInnerHTML={{ __html: this.props.ActorHelpText.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></span>
                                </span>
                                : null}
                            <div className="form-section__input-group" onChange={(e) => this.onActorChange(e)}>
                                <span className={`form-section__message form-section__message--error${this.state.actorError ? ' form-section__message--active' : ''}`}>{this.props.Translations.noActorError}</span>
                                <label className="form__label form-section__input-group-item">
                                    <input ref="actor" className="form__radio" name="actor" type="radio" value="PUBLIC_SECTOR" defaultChecked={this.state.actor === 'PUBLIC_SECTOR' ? true : false} />
                                    <span className="form__label-text form__label--offset">{this.props.Translations.actorPublicSector}</span>
                                </label>
                                <label className="form__label form-section__input-group-item">
                                    <input ref="actor" className="form__radio" name="actor" type="radio" value="PRIVATE_SECTOR" defaultChecked={this.state.actor === 'PRIVATE_SECTOR' ? true : false} />
                                    <span className="form__label-text form__label--offset">{this.props.Translations.actorPrivateSector}</span>
                                </label>
                                <label className="form__label form-section__input-group-item">
                                    <input ref="actor" className="form__radio" name="actor" type="radio" value="PRIVATE_PERSON" defaultChecked={this.state.actor === 'PRIVATE_PERSON' ? true : false} />
                                    <span className="form__label-text form__label--offset">{this.props.Translations.actorPrivatePerson}</span>
                                </label>
                            </div>
                        </fieldset>
                        : null}

                    <fieldset className="form-section__fieldset">
                        <label className="form__label id-status-service__label">
                            <span className="form__label-text" htmlFor="docId">{this.props.IdStatusDocumentNumberLabel}</span>
                            <span className="form-section__input-container">
                                <span className={`form-section__message form-section__message--error${this.state.docNumError ? ' form-section__message--active' : ''}`}>{this.props.Translations.noDocNumError}</span>
                                <input id="docId" ref="docId" className={`form__textbox${this.state.docNumError ? ' form__input-error' : ''} id-status-service__input`} type="text" placeholder={this.props.Translations.docIdPlaceholder} onBlur={(e) => this.onDocumentNumberChange(e)} />
                            </span>
                        </label>
                    </fieldset>

                    <fieldset className="form-section__fieldset">
                        <span className={`form-section__message form-section__message--error${this.state.apiError ? ' form-section__message--active' : ''}`}>{this.props.Translations.apiError}</span>
                        <button type="submit" className={`btn btn--subtle id-status-service__submit${this.state.loading || !this.state.timerIsDone ? ' id-status-service__submit--loading' : ''}`} onClick={(e) => this.onSubmit(e)} disabled={this.state.loading || !this.state.timerIsDone ? true : false}>
                            <span className="id-status-service__submit-text">{this.props.Translations.submitBtnText}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 37 38" className="id-status-service__submit-loader">
                                <defs>
                                    <path id="a" d="M9.744 2.046V.223H.482v1.823h9.262z" />
                                    <path id="c" d="M2.782.77H.955v9.25h1.827V.77z" />
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                    <g transform="translate(27.038 17.73)">
                                        <mask id="b" fill="#fff">
                                            <use xlinkHref="#a" />
                                        </mask>
                                        <path fill="#E6E9F2" d="M1.394.223a.912.912 0 1 0 0 1.823h7.438a.913.913 0 0 0 .914-.912c0-.503-.41-.91-.914-.91H1.394z" mask="url(#b)" />
                                    </g>
                                    <path fill="#A1A8B5" d="M.913 17.954a.912.912 0 1 0 0 1.823H8.35a.913.913 0 0 0 .913-.912c0-.503-.41-.91-.913-.91H.913z" />
                                    <path fill="#EAEDF4" d="M27.543 23.09a.912.912 0 1 0-.912 1.58l6.442 3.711a.912.912 0 1 0 .911-1.578l-6.44-3.714z" />
                                    <path fill="#B0B6C2" d="M3.711 9.35a.91.91 0 1 0-.913 1.578L9.24 14.64a.912.912 0 1 0 .914-1.578L3.71 9.35z" />
                                    <path fill="#F1F2F8" d="M24.203 27.093a.913.913 0 0 0-1.582.911l3.72 6.433a.914.914 0 0 0 1.581-.911l-3.719-6.433z" />
                                    <path fill="#BFC4CE" d="M10.443 3.294a.912.912 0 1 0-1.582.912l3.72 6.432a.912.912 0 0 0 1.582-.912l-3.72-6.432z" />
                                    <path fill="#F6F8FC" d="M19.305 28.892a.913.913 0 0 0-1.827 0v7.428a.913.913 0 0 0 1.827 0v-7.428z" />
                                    <g transform="translate(16.524 -.269)">
                                        <mask id="d" fill="#fff">
                                            <use xlinkHref="#c" />
                                        </mask>
                                        <path fill="#C8CDD9" d="M2.782 1.68a.912.912 0 0 0-1.827 0v7.427a.913.913 0 0 0 1.827 0V1.681z" mask="url(#d)" />
                                    </g>
                                    <path fill="#FFF" d="M14.162 28.005a.913.913 0 0 0-1.582-.91l-3.718 6.431a.91.91 0 0 0 .334 1.245.915.915 0 0 0 1.247-.334l3.719-6.432z" />
                                    <path fill="#D1D6E1" d="M27.922 4.206a.912.912 0 1 0-1.582-.912l-3.718 6.432a.913.913 0 0 0 1.58.912l3.72-6.432z" />
                                    <path fill="#959DAE" d="M10.153 24.668a.911.911 0 1 0-.914-1.579L2.8 26.803a.912.912 0 0 0 .913 1.58l6.44-3.715z" />
                                    <path fill="#DCDFE8" d="M33.984 10.928a.912.912 0 0 0-.912-1.578l-6.442 3.712a.911.911 0 1 0 .913 1.58l6.441-3.714z" />
                                </g>
                            </svg>
                        </button>
                    </fieldset>
                </form>
                <div className={`id-status-service__status${this.state.loading || !this.state.timerIsDone ? ' id-status-service__status--hidden' : ''}`}>
                    <div id="id-status-serice__print-area" className="id-status-serice__print-area">
                        <header className="id-status-service__receipt-header id-status-serice__print-only">
                            <img className="id-status-serice__receipt-logo" src="/Static/images/politiet_logo.svg" alt="Politiets logo" />
                        </header>
                        <h2 className="id-status-serice__receipt-title id-status-serice__print-only" id={idHelper.createId(this.props.Translations.receiptHeading)}>{this.props.Translations.receiptHeading}</h2>
                        <h3 className="weight--bold id-status-serice__print-only" id={idHelper.createId(this.props.Translations.timestampHeading)}>{this.props.Translations.timestampHeading}</h3>
                        <p className="id-status-serice__print-only">{Moment(this.state.requestTimestamp).format('DD.MM.YY [kl.] HH:mm')}</p>
                        <h3 className="weight--bold id-status-serice__print-only" id={idHelper.createId(this.props.Translations.documentNumberHeading)}>{this.props.Translations.documentNumberHeading}</h3>
                        <p className="id-status-serice__print-only">{this.state.documentNumber}</p>
                        <h3 className="weight--bold id-status-serice__print-only" id={idHelper.createId(this.props.Translations.statusHeading)}>{this.props.Translations.statusHeading}</h3>
                        <Notification
                            Type={this.state.notificationType}
                            Heading={this.state.notificationHeading}
                            Message={this.state.notificationMsg}
                            Hidden={this.state.isHidden}
                            Modifier="id-status-service__notification" />
                        <h3 className="id-status-serice__print-only" id={idHelper.createId(this.props.Translations.referenceNumberHeading)}>{this.props.Translations.referenceNumberHeading}</h3>
                        <p className="id-status-serice__receipt-number id-status-serice__print-only">{this.state.referenceNumber}</p>
                    </div>
                    {!this.state.isHidden || this.state.loading ?
                        <>
                            <h3 className="h4 print--hidden" id={this.props.IdStatusServiceRefNumberHeading}>{this.props.IdStatusServiceRefNumberHeading}</h3>
                            <a href="" role="button" className="id-status-service__pdf-download print--hidden" onClick={(e) => this.printPdf(e, this.props.Translations.receiptTitle, this.state.requestTimestamp)}>{this.props.Translations.pdfBtnText}</a>
                        </>
                        : null}
                </div>
            </section>
        );
    }
}
