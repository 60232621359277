import React from 'react';
import FilterPanelCategory from './filter-panel-category.jsx';
import FilterPanelUnit from './filter-panel-unit.jsx';
import FilterPanelDate from './filter-panel-date.jsx';
import FilterPanelYear from './filter-panel-year.jsx';
import FilterPanelFunction from './filter-panel-function.jsx';
import FilterPanelVVR from './filter-panel-vvr.jsx';
import Keyboard from '../../02-tools/keyboard/keyboard';
import Params from '../../02-tools/params/params';
import idHelper from '../../02-tools/id-helper/idHelper.js';

/*type Props = {
  FilterItems: Array<Object>;
  Translations: Array<Object>;
  Loading: Boolean;
  IsVVR: Boolean;
	ServiceAreasParam: String;
};*/

export default class FilterPanel extends React.Component {
  constructor(props) {
    super(props);

    this.tabs = Object.keys(props.FilterItems);

    this.lockFocus = false;

    this.state = {
      selected: false,
      expanded: false,
      showUnit: props.IsVVR ? false : true,
      showVVR: props.IsVVR ? false : true
    };
  }

  filter = (name, value, param, isChecked) => {
    this.lockFocus = true;
    this.props.filter(name, value, param, isChecked);
  }

  previousTab = (keyName) => {
    const index = this.activeTabs.indexOf(keyName);
    if (index > 0) this.selectTab(this.activeTabs[index - 1])
  }

  nextTab = (keyName) => {
    const index = this.activeTabs.indexOf(keyName);
    if (index < this.activeTabs.length - 1) this.selectTab(this.activeTabs[index + 1])
  }

  componentDidUpdate() {
    if (this.activeLink && !this.lockFocus) {
      this.activeLink.focus()
    }
  }

  closeTabs = () => {
    if (this.activeLink.offsetHeight !== 0){
      this.activeLink.focus();
    } else {
      document.getElementById(this.activeLink.dataset.mobileTab).focus();
    }

    this.setState({
      selected: false
    });
  }

  updateParams = (params) => {
    this.lockFocus = true;
    this.props.updateParams(params);
  }

  selectTab = (index) => {
    this.lockFocus = false;

    if (this.state.selected === index) {
      this.setState({
        selected: false
      });
    } else {
      this.setState({
        selected: index
      });
    }
  }

  handleKeyPress = (keyIndex, e) => {
    if (e.which === Keyboard.right) {
      this.nextTab(keyIndex)
    } else if (e.which === Keyboard.left) {
      this.previousTab(keyIndex)
    }
  }

  toggleFilter = (state, e) => {
    this.setState({
      expanded: state
    });
  }

  componentDidMount() {
    if (this.props.IsVVR) {
	    this.setState({
		    showUnit: !Params.getParams().hideUnit,
		    showVVR: !Params.getParams().hideVVR,
	    });
    }
	}

  render() {
    const p = this.props;
    let buttons = [];
    let panels = [];
    this.activeTabs = [];

    this.tabs.map(function (keyName, keyIndex) {
      if (keyName === 'VVRBusinessList' && this.state.showVVR) {
        let key = keyIndex + 666;
        let activeClass = (this.state.selected === p.FilterItems[keyName].ParameterName ? 'filter__item-button filter__item-button--active' : 'filter__item-button');
        let tabIndex = (this.state.selected === p.FilterItems[keyName].ParameterName || (key === 0 && !this.state.selected) ? 0 : -1);
        this.activeTabs.push(p.FilterItems[keyName].ParameterName);

	        buttons.push(
	          <li className="filter__item" key={key} role="presentation">
	            <button
                id={`${p.FilterItems[keyName].ParameterName}Tab`}
                aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
                aria-selected={p.FilterItems[keyName].ParameterName === this.state.selected}
	              className={activeClass}
	              role="tab"
                onClick={this.selectTab.bind(this, p.FilterItems[keyName].ParameterName)}
                onKeyUp={this.handleKeyPress.bind(this, p.FilterItems[keyName].ParameterName)}
	              tabIndex={tabIndex}
                ref={link => { if (p.FilterItems[keyName].ParameterName === this.state.selected) this.activeLink = link }}
                data-mobile-tab={`${p.FilterItems[keyName].ParameterName}TabMobile`}>
                <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
	              <span className="filter__item-icon"></span>
	            </button>
	          </li>
	        );
	        panels.push(
	          <div key={key} role="tablist">
	            <button
                id={`${p.FilterItems[keyName].ParameterName}TabMobile`}
                aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
                aria-selected={p.FilterItems[keyName].ParameterName === this.state.selected}
	              className={`${activeClass} filter__item-button--mobile`}
	              role="tab"
                onClick={this.selectTab.bind(this, p.FilterItems[keyName].ParameterName)}>
                <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
	              <span className="filter__item-icon"></span>
	            </button>
              <FilterPanelVVR Label={p.FilterItems[keyName].Label} Parameter={p.FilterItems[keyName].ParameterName} CloseTabs={this.closeTabs} Items={p.FilterItems[keyName].Items} ServiceAreasParam={p.ServiceAreasParam} selected={p.FilterItems[keyName].ParameterName === this.state.selected} filter={this.filter} Translations={this.props.Translations} Loading={p.Loading} />
	          </div>
	        );
      } else if (keyName === 'ListOfCategoryFilterItemLists') {

        p.FilterItems[keyName].map((result, index) => {
          let key = keyIndex + (index / 10);
          let activeClass = (this.state.selected === result.ParameterName ? 'filter__item-button filter__item-button--active' : 'filter__item-button');
          let tabIndex = (this.state.selected === result.ParameterName || (key === 0 && !this.state.selected) ? 0 : -1);
          this.activeTabs.push(result.ParameterName);

          buttons.push(
            <li className="filter__item" key={key} role="presentation">
              <button
                id={`${result.ParameterName}Tab`}
                aria-controls={`${result.ParameterName}Panel`}
                aria-selected={result.ParameterName === this.state.selected}
                className={activeClass}
                role="tab"
                onClick={this.selectTab.bind(this, result.ParameterName)}
                onKeyUp={this.handleKeyPress.bind(this, result.ParameterName)}
                tabIndex={tabIndex}
                ref={link => { if (result.ParameterName === this.state.selected) this.activeLink = link }}
                data-mobile-tab={`${result.ParameterName}TabMobile`}>
                <span className="filter__item-label">{result.Label}</span>
                <span className="filter__item-icon"></span>
              </button>
            </li>
          );
          panels.push(
            <div key={key} role="tablist">
              <button
                id={`${result.ParameterName}TabMobile`}
                aria-controls={`${result.ParameterName}Panel`}
                aria-selected={result.ParameterName === this.state.selected}
                className={`${activeClass} filter__item-button--mobile`}
                role="tab"
                onClick={this.selectTab.bind(this, result.ParameterName)}>
                <span className="filter__item-label">{result.Label}</span>
                <span className="filter__item-icon"></span>
              </button>
              <FilterPanelCategory Label={result.ParameterName} CloseTabs={this.closeTabs} Items={result.Items} selected={result.ParameterName === this.state.selected} filter={this.filter} Translations={this.props.Translations} Loading={p.Loading} />
            </div>
          );
        });

      } else if (keyName === 'ResponsibleFilterItemList' && this.state.showUnit) {
        let activeClass = (this.state.selected === keyName ? 'filter__item-button filter__item-button--active' : 'filter__item-button');
        let tabIndex = (this.state.selected === keyName || (keyIndex === 0 && !this.state.selected) ? 0 : -1);
        this.activeTabs.push(keyName);
        buttons.push(
          <li className="filter__item" key={keyIndex} role="presentation">
            <button
              id={`${p.FilterItems[keyName].ParameterName}Tab`}
              aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
              aria-selected={keyName === this.state.selected}
              className={activeClass}
              role="tab"
              onClick={this.selectTab.bind(this, keyName)}
              onKeyUp={this.handleKeyPress.bind(this, keyName)}
              tabIndex={tabIndex}
              ref={link => { if (keyName === this.state.selected) this.activeLink = link }}
              data-mobile-tab={`${p.FilterItems[keyName].ParameterName}TabMobile`}>
              <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
              <span className="filter__item-icon"></span>
            </button>
          </li>
        );
        panels.push(
          <div key={keyIndex} role="tablist">
            <button
              id={`${p.FilterItems[keyName].ParameterName}TabMobile`}
              aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
              aria-selected={keyName === this.state.selected}
              className={`${activeClass} filter__item-button--mobile`}
              role="tab"
              onClick={this.selectTab.bind(this, keyName)}>
              <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
              <span className="filter__item-icon"></span>
            </button>
            <FilterPanelUnit IsVVR={p.IsVVR} Label={p.FilterItems[keyName].ParameterName} CloseTabs={this.closeTabs} Items={p.FilterItems[keyName].Items} selected={keyName === this.state.selected} filter={this.filter} Translations={this.props.Translations} Loading={p.Loading} />
          </div>
        );
      } else if (keyName === 'DateFilterItem') {
        let activeClass = (this.state.selected === keyName ? 'filter__item-button filter__item-button--active' : 'filter__item-button');
        let tabIndex = (this.state.selected === keyName || (keyIndex === 0 && !this.state.selected) ? 0 : -1);
        this.activeTabs.push(keyName);
        buttons.push(
          <li className="filter__item" key={keyIndex} role="presentation">
            <button
              id={`${p.FilterItems[keyName].ParameterName}Tab`}
              aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
              aria-selected={keyName === this.state.selected}
              className={activeClass}
              role="tab"
              onClick={this.selectTab.bind(this, keyName)}
              onKeyUp={this.handleKeyPress.bind(this, keyName)}
              tabIndex={tabIndex}
              ref={link => { if (keyName === this.state.selected) this.activeLink = link }}
              data-mobile-tab={`${p.FilterItems[keyName].ParameterName}TabMobile`}>
              <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
              <span className="filter__item-icon"></span>
            </button>
          </li>
        );
        panels.push(
          <div key={keyIndex} role="tablist">
            <button
              id={`${p.FilterItems[keyName].ParameterName}TabMobile`}
              aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
              aria-selected={keyName === this.state.selected}
              className={`${activeClass} filter__item-button--mobile`}
              role="tab"
              onClick={this.selectTab.bind(this, keyName)}>
              <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
              <span className="filter__item-icon"></span>
            </button>
            <FilterPanelDate Label={p.FilterItems[keyName].ParameterName} CloseTabs={this.closeTabs} updateParams={this.updateParams} FilterDateYearsAndNumbers={p.FilterItems[keyName].FilterDateYearsAndNumbers} selected={keyName === this.state.selected} filter={this.filter} Translations={this.props.Translations} />
          </div>
        );
      } else if (keyName === 'YearFilterItemList') {
        let activeClass = (this.state.selected === keyName ? 'filter__item-button filter__item-button--active' : 'filter__item-button');
        let tabIndex = (this.state.selected === keyName || (keyIndex === 0 && !this.state.selected) ? 0 : -1);
        this.activeTabs.push(keyName);
        buttons.push(
          <li className="filter__item" key={keyIndex} role="presentation">
            <button
              id={`${p.FilterItems[keyName].ParameterName}Tab`}
              aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
              aria-selected={keyName === this.state.selected}
              className={activeClass}
              role="tab"
              onClick={this.selectTab.bind(this, keyName)}
              onKeyUp={this.handleKeyPress.bind(this, keyName)}
              tabIndex={tabIndex}
              ref={link => { if (keyName === this.state.selected) this.activeLink = link }}
              data-mobile-tab={`${p.FilterItems[keyName].ParameterName}TabMobile`}>
              <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
              <span className="filter__item-icon"></span>
            </button>
          </li>
        );
        panels.push(
          <div key={keyIndex} role="tablist">
            <button
              id={`${p.FilterItems[keyName].ParameterName}TabMobile`}
              aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
              aria-selected={keyName === this.state.selected}
              className={`${activeClass} filter__item-button--mobile`}
              role="tab"
              onClick={this.selectTab.bind(this, keyName)}>
              <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
              <span className="filter__item-icon"></span>
            </button>
            <FilterPanelYear Label={p.FilterItems[keyName].ParameterName} CloseTabs={this.closeTabs} updateParams={this.updateParams} Items={p.FilterItems[keyName].Items} selected={keyName === this.state.selected} filter={this.filter} Translations={this.props.Translations} Loading={p.Loading} />
          </div>
        );
      } else if (keyName === 'FunctionFilterItemList') {
        let activeClass = (this.state.selected === keyName ? 'filter__item-button filter__item-button--active' : 'filter__item-button');
        let tabIndex = (this.state.selected === keyName || (keyIndex === 0 && !this.state.selected) ? 0 : -1);
        this.activeTabs.push(keyName);
        buttons.push(
          <li className="filter__item" key={keyIndex} role="presentation">
            <button
              id={`${p.FilterItems[keyName].ParameterName}Tab`}
              aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
              aria-selected={keyName === this.state.selected}
              className={activeClass}
              role="tab"
              onClick={this.selectTab.bind(this, keyName)}
              onKeyUp={this.handleKeyPress.bind(this, keyName)}
              tabIndex={tabIndex}
              ref={link => { if (keyName === this.state.selected) this.activeLink = link }}
              data-mobile-tab={`${p.FilterItems[keyName].ParameterName}TabMobile`}>
              <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
              <span className="filter__item-icon"></span>
            </button>
          </li>
        );
        panels.push(
          <div key={keyIndex} role="tablist">
            <button
              id={`${p.FilterItems[keyName].ParameterName}TabMobile`}
              aria-controls={`${p.FilterItems[keyName].ParameterName}Panel`}
              aria-selected={keyName === this.state.selected}
              className={`${activeClass} filter__item-button--mobile`}
              role="tab"
              onClick={this.selectTab.bind(this, keyName)}>
              <span className="filter__item-label">{p.FilterItems[keyName].Label}</span>
              <span className="filter__item-icon"></span>
            </button>
            <FilterPanelFunction Label={p.FilterItems[keyName].ParameterName} CloseTabs={this.closeTabs} updateParams={this.updateParams} Items={p.FilterItems[keyName].Items} selected={keyName === this.state.selected} filter={this.filter} Translations={this.props.Translations} Loading={p.Loading} />
          </div>
        );
      }

    }.bind(this));

    return (
	    <div className="row">
		    <div className="filter-panels xsmall-12 medium-12 column">
			    <h2 className="filter__headline" id={idHelper.createId(p.Translations.Filter)}>
				    <span className="filter__headline-text">{p.Translations.Filter}</span>
				    <button className={this.state.expanded ? 'filter__filter-button filter__filter-button--expanded' : 'filter__filter-button'} onClick={this.toggleFilter.bind(this, this.state.expanded ? false : true)}>{p.Translations.Filter}<span className="filter__toggle-icon"></span></button>
			    </h2>
			    <SelectedFilters {...this.props.SelectedFilterItems} filter={this.filter} resetFilter={p.resetFilter} Translations={p.Translations} />
			    <ul className={"filter__items list--reset-style-type " + (this.state.selected ? "filter__items--active" : "")} role="tablist">
				    {buttons}
			    </ul>
			    <div className={this.state.expanded ? 'filter__panels-container filter__panels-container--expanded' : 'filter__panels-container'}>
				    {panels}
			    </div>
		    </div>
	    </div>
    );
  }
}

class SelectedFilters extends React.Component {
  constructor(props) {
    super(props);
  }

  removeFilter = (name, value, param) => {
    this.props.filter(name, value + '', param, false);
  }

  capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  render() {
    const p = this.props;
    let filterArrays = [];

    if (p.StartDate && p.StartDate.Date) {
      filterArrays.push({
	      Label: p.StartDate.Label,
	      ParameterName: p.StartDate.ParameterName,
	      Items: [{
	          Id: p.StartDate.Date.FormatedTime,
	          Name: p.StartDate.Date.FormatedTime
	        }]
	    });
    }
    if (p.EndDate && p.EndDate.Date) {
      filterArrays.push({
	      Label: p.EndDate.Label,
	      ParameterName: p.EndDate.ParameterName,
	      Items: [{
	          Id: p.EndDate.Date.FormatedTime,
	          Name: p.EndDate.Date.FormatedTime
	        }]
	    });
    }
    if (p.YearFilterItemList) {
	    filterArrays.push({
        Label: p.YearFilterItemList.Label,
        ParameterName: p.YearFilterItemList.ParameterName,
        Items: p.YearFilterItemList.Items
	    });
    }
    if (p.ResponsibleFilterItemList) {
	    filterArrays.push({
        Label: p.ResponsibleFilterItemList.Label,
        ParameterName: p.ResponsibleFilterItemList.ParameterName,
        Items: p.ResponsibleFilterItemList.Items
	    });
    }
    if (p.FunctionFilterItemList) {
	    filterArrays.push({
        Label: p.FunctionFilterItemList.Label,
        ParameterName: p.FunctionFilterItemList.ParameterName,
        Items: p.FunctionFilterItemList.Items
	    });
    }
    if (p.ListOfCategoryFilterItemLists) {
      this.props.ListOfCategoryFilterItemLists.map((result) => {
        filterArrays.push({
          Label: result.Label,
          ParameterName: result.ParameterName,
          Items: result.Items
        });
      });
    }
		if (p.VVRFilterList) {
			filterArrays.push({
				Label: p.VVRFilterList.Label,
				ParameterName: p.VVRFilterList.ParameterName,
				Items: p.VVRFilterList.Items
			});
    }

    if (filterArrays.length) {

      return (
        <section className="filter__selected">
          <div className="row">
            <div className="column" aria-atomic="true" aria-live="polite">
              <h2 className="filter__selected-headline" id={idHelper.createId(p.Translations.Choices)}>{p.Translations.Choices}:</h2>
              {filterArrays.map((filter, i) => (
                <ul key={i} className="filter__tag-list tag-list tag-list--inline tag-list--buttons tag-list--no-icon list--reset-style-type">
                  <li className="tag-list__group-label">{this.capitalize(filter.Label)}</li>
                  {filter.Items.map((result, i) => (
                    <li key={i} className="tag-list__tag"><button className="tag-list__link tag-list__link--removable" onClick={this.removeFilter.bind(this, filter.ParameterName, result.Id, result.Name)}>{result.Name}<span className="show-for-sr">{p.Translations.RemoveFilter}</span></button></li>
                  ))}
                </ul>
              ))}
            </div>
            <div className="column shrink">
              <button className="filter__reset-btn" onClick={p.resetFilter}>{p.Translations.Reset}</button>
            </div>
          </div>
        </section>
      )
    } else {
      return null;
    }

  }
}
