import Keyboard from '../../02-tools/keyboard/keyboard';

export default class ImageModal {
  constructor(element) {
    const DOMbody = document.getElementsByTagName('body')[0];

    this.id = Math.floor((1 + Math.random()) * 0x10000).toString(16);

    if (element.dataset.id) {
      this.id = element.dataset.id;
      element.id = `${this.id}-modal-btn`;
    }

    element.setAttribute('aria-controls', `${this.id}-modal`);
    element.setAttribute('aria-expanded', false);

    this.element = element;
    this.modal = this.createModal(element);
    this.imageContainer = this.modal.getElementsByTagName('img')[0];

    this.closeFunc = (evt) => {
      if (evt.type === 'keyup' && evt.which === Keyboard.esc) {
        this.closeModal(evt);
      } else if (evt.type === 'click' && evt.target !== this.imageContainer) {
        this.closeModal(evt);
      }
    };

    element.addEventListener('click', (evt) => {
      evt.preventDefault();
      this.openModal(evt);
    });

    this.modal.addEventListener('click', this.closeFunc);

    DOMbody.appendChild(this.modal);
  }

  createModal(element) {
    const modal = document.createElement('div');
    modal.setAttribute('class', 'modal modal--image');
    modal.setAttribute('aria-expanded', 'false');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('role', 'dialog');
    modal.setAttribute('aria-labelledby', `${this.id}-modal-btn`);
    modal.id = `${this.id}-modal`;
    const row = document.createElement('div');
    row.setAttribute('class', 'row align-center');

    const column = document.createElement('div');
    column.setAttribute('class', 'xsmall-12 column modal__column modal__column--center');

    const closeButton = document.createElement('button');
    closeButton.setAttribute('class', 'modal__close-btn modal__close-btn--offset');
    const closeButtonText = document.createTextNode(element.dataset.closebtn);
    closeButton.appendChild(closeButtonText);

    closeButton.addEventListener('click', this.closeFunc);

    const image = document.createElement('img');
    image.setAttribute('class', 'modal__image');
    image.setAttribute('src', element.href);
    image.setAttribute('alt', element.dataset.alt);

    column.appendChild(closeButton);
    column.appendChild(image);
    row.appendChild(column);
    modal.appendChild(row);
    return modal;
  }

  openModal() {
    document.querySelector('html').classList.add('html--modal-open');
    this.modal.classList.add('modal--open');
    this.modal.setAttribute('aria-expanded', 'true');
    this.modal.setAttribute('aria-hidden', 'false');
    this.modal.tabIndex = -1;
    this.modal.focus();
    this.element.setAttribute('aria-expanded', 'true');

    document.addEventListener('keyup', this.closeFunc);
  }

  closeModal() {
    this.modal.classList.remove('modal--open');
    this.modal.setAttribute('aria-expanded', 'false');
    this.modal.setAttribute('aria-hidden', 'true');
    this.element.setAttribute('aria-expanded', 'false');
    this.element.focus();
    document.removeEventListener('keyup', this.closeFunc);
    document.querySelector('html').classList.remove('html--modal-open');
  }
}
