import React from 'react';
import OpeningHoursTableBody from './opening-hours-table-body.jsx';


/*type Props = {
  Translations: Array<Object>;
  OpeningHours: Array<Object>;
  HideTitles: Boolean;
};*/

export default class OpeningHoursTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const p = this.props;
    const appointmentsTh = p.HasAppointments ? <th className="opening-hours__table-th" scope="row"><span className={p.HideTitles ? 'opening-hours__table-th-text--hidden' : 'opening-hours__table-th-text'}>{p.Translations.Appointment}</span></th> : null;
    const phoneHoursTh = p.HasPhoneHours ? <th className="opening-hours__table-th" scope="row"><span className={p.HideTitles ? 'opening-hours__table-th-text--hidden' : 'opening-hours__table-th-text'}>{p.Translations.PhoneHours}</span></th> : null;
    const dropInTh = p.HasDropIn ? <th className="opening-hours__table-th" scope="row"><span className={p.HideTitles ? 'opening-hours__table-th-text--hidden' : 'opening-hours__table-th-text'}>{p.Translations.DropIn}</span></th> : null;
    const passportTh = p.HasPassport ? <th className="opening-hours__table-th" scope="row"><span className={p.HideTitles ? 'opening-hours__table-th-text--hidden' : 'opening-hours__table-th-text'}>{p.Translations.Passport}</span></th> : null;
    const primaryTh = p.HasPrimary ? <th className="opening-hours__table-th" scope="row"><span className={p.HideTitles ? 'opening-hours__table-th-text--hidden' : 'opening-hours__table-th-text'}>{p.Translations.Primary}</span></th> : null;

    return (
      <table className="opening-hours__table">
        <thead className="opening-hours__table-thead">
          <tr className="opening-hours__table-tr">
            <th className="opening-hours__table-th" scope="row">
              <span className="opening-hours__calendar-icon" role="presentation"></span>
            </th>
            {primaryTh}
            {appointmentsTh}
            {phoneHoursTh}
            {dropInTh}
            {passportTh}
          </tr>
        </thead>
        <OpeningHoursTableBody OpeningHours={p.OpeningHours} Translations={p.Translations} HasAppointments={p.HasAppointments} HasPhoneHours={p.HasPhoneHours} HasDropIn={p.HasDropIn} HasPassport={p.HasPassport} HasPrimary={p.HasPrimary} HideTitles={p.HideTitles} IsPlainMode={p.IsPlainMode} />
      </table>
    );
  }
}
