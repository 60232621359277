class Insight {
    track(type, data) {
        if (type && data && type.toLowerCase() === "gtm") {
            // Google Tag Manager
            // Google Analytics is no longer in use as of 28.03.2023
            return;
            /*if (dataLayer) {
          dataLayer.push(data);
        }*/
        } else if (type && data && type.toLowerCase() === "ga") {
            // Google Analytics
            // Google Analytics is no longer in use as of 28.03.2023
            return;

            /*if (!window.ga) return;
        if (data.isEvent) {
          // Google Analytics Event Tracking
          window.ga(
            'gtm2.send',
            'event',
            data.category,
            data.action,
            data.label,
            data.value
          );

        } else {
          // Google Analytics Page Tracking
          window.ga('gtm2.set', 'page', data.data);
          window.ga('gtm2.send', 'pageview');
        }*/

            /*
        Insight.track('matomo', {
          isEvent: true,
          category: string,
          action: string,
          name: string,
          value: 0,
        });
        */
        } else if (type && data && type.toLowerCase() === "matomo") {
            if (window.Matomo?.initialized) {
                if (data.isEvent) {
                    //console.log(data.category, ' - ', data.name, ' - ', data.action);
                    window._paq.push([
                        "trackEvent",
                        data.category,
                        data.action,
                        data.name,
                        typeof data.value === "undefined" || data.value === null
                            ? 0
                            : data.value,
                    ]);
                } else {
                    window._paq.push([
                        "deleteCustomVariables",
                        "page",
                        data.data,
                    ]);
                    window._paq.push(["setCustomUrl", "/" + data.data]);
                    window._paq.push(["setDocumentTitle", data.title]);
                    window._paq.push(["trackPageView"]);
                }
            } else {
                console.warn("Matomo not initialized");
            }
        }
    }
}

export default new Insight();
