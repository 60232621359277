import GlobalText from '../../02-tools/global-text/global-text';
import idHelper from '../../02-tools/id-helper/idHelper';
import Insight from '../../02-tools/insight/insight';

export default class FormSummary {
  constructor(element) {
    const formGuide = document.querySelector('.form-guide');
    const formSection = document.querySelector('.form-section');
    const form = formSection.querySelector('.form-section__form');

    const pageHeading = document.querySelector('.page__heading');

    const summaryFormGuideList = element.querySelector('.js-form-summary-form-guide-list');
    const summaryFormSections = element.querySelector('.js-form-summary-form-sections');
    const summaryConfirmBtn = element.querySelector('.form-summary__confirm-btn');
    const summaryEditBtn = element.querySelector('.form-summary__edit-btn');
    const summaryCancelBtn = element.querySelector('.form-summary__cancel-btn');

    this.holdItems = [];

    let languageResources;

    pageHeading.innerHTML = pageHeading.dataset.summaryHeading;

    GlobalText.get().then((data) => {
      languageResources = data;

      let subHeading = document.createElement('h2');
      subHeading.classList.add('form-summary__sub-heading');
      const subHeadingText = document.createTextNode(this.getFormTitle(formSection.querySelector('.form-section__headline:not(.form-section--hidden)'), languageResources));
      subHeading.appendChild(subHeadingText);
      subHeading.setAttribute('id', idHelper.createId(subHeading.innerHTML));
      this.insertAfter(subHeading, document.querySelector('.js-form-summary-form-heading'));
      summaryFormGuideList.innerHTML = this.getFormGuideValues(formGuide.querySelectorAll('.form-guide__list-item--inactive'));
      summaryFormSections.innerHTML = this.getFormSections(formSection.querySelectorAll('.form-section__form-group:not(.form-section--hidden) .js-form-summary-section'), languageResources);

      element.classList.remove('form-summary--hidden');
      element.setAttribute('aria-hidden', false);
      formGuide.classList.add('form-guide--hidden');
      formGuide.setAttribute('aria-hidden', true);
      formSection.classList.add('form-section--hidden');
      formSection.setAttribute('aria-hidden', true);

      window.scrollTo(0, 0);

      Insight.track('gtm', { 'event': 'AnmeldelseVisSammendrag', 'category': 'Anmeldelse', 'data': location.pathname + 'oversikt' });
    }).catch((error) => {
      console.log('error', error);
    });

    summaryConfirmBtn.addEventListener('click', (e) => {
      e.preventDefault();
      Insight.track('gtm', { 'event': 'AnmeldelseTypeAnmeldelse', 'category': 'Anmeldelse', 'data': this.getFormTitle(formSection.querySelector('.form-section__headline:not(.form-section--hidden)'), languageResources) });
      form.submit();
    });

    summaryEditBtn.addEventListener('click', (e) => {
      e.preventDefault();

      const subHeading = element.querySelector('.form-summary__sub-heading');
      subHeading.parentNode.removeChild(subHeading);

      element.classList.add('form-summary--hidden');
      element.setAttribute('aria-hidden', true);
      formGuide.classList.remove('form-guide--hidden');
      formGuide.setAttribute('aria-hidden', false);
      formSection.classList.remove('form-section--hidden');
      formSection.setAttribute('aria-hidden', false);
      pageHeading.innerHTML = pageHeading.dataset.formHeading;

      window.scrollTo(0, 0);
    });

    summaryCancelBtn.addEventListener('click', (e) => {
      e.preventDefault();
      location.reload();
    });
  }

  insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }

  getFormTitle(elem, languageResources) {
    return elem.innerHTML;
  }

  getFormGuideValues(elements) {
    let markup = '';

    for (let i = 0; i < elements.length; i++) {
      const elem = elements[i];
      const title = elem.querySelector('h2').innerHTML;
      const value = elem.querySelector('.form__label:not(.form-guide__hidden) .form__label-text').innerHTML;

      markup += '<li class="form-summary__form-guide-list-item">' + '<span class="form-summary__label">' + title + '</span>' + '<span class="form-summary__value">' + value + '</span></li>';
    }

    return markup;
  }

  getFormSections(summarySections, languageResources) {
    let markup = '';
    for (let i = 0; i < summarySections.length; i++) {
      const section = summarySections[i];
      const sectionTitle = section.querySelector('.form-section__section-title').innerHTML;

      markup += '<div class="form-summary__form-section">' + '<h3 class="form-summary__form-section-title" id="'+ idHelper.createId(sectionTitle) + '">' + sectionTitle + '</h3>' + '<ul class="list--reset-style form-summary__form-section-list">' + this.getFormSectionItems(section, languageResources) + '</ul>' + '</div>';
    }

    return markup;
  }

  getFormSectionItems(summarySection, languageResources) {
    const summaryItems = summarySection.querySelectorAll('.js-form-summary-item');
    let markup = '';

    for (let i = 0; i < summaryItems.length; i++) {
      const item = summaryItems[i];

      if (this.isElementVisible(item)) {
        const itemTitle = this.getText(item.querySelector('.form-section__heading, .form__label-text'));
        const itemInputs = item.querySelectorAll('.form__textarea, .form__textbox, .form__date, .form__time, .form__select, .form__radio, .form__checkbox');

        markup += '<li class="form-summary__form-section-list-item"><span class="form-summary__label">' + itemTitle + '</span><span class="form-summary__value">' + this.getInputValues(itemInputs, languageResources) + '</span></li>';
      }
    }

    return markup;
  }

  getInputValues(inputs, languageResources) {
    let output = [];

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];

      switch (input.dataset.summaryType) {
        case 'text':
          output.push(input.value);
          break;
        case 'textarea':
          output.push('<br>' + input.value);
          break;
        case 'select':
          output.push(input.options[input.selectedIndex].value);
          break;
        case 'radio':
          if (input.checked) {
            output.push(input.value);
          }
          break;
        case 'checkbox':
          if (input.checked) {
            output.push(languageResources.policeReportPage.checked);
          } else {
            output.push(languageResources.policeReportPage.unchecked);
          }
          break;
        case 'startdate':
          output.push('<br>' + languageResources.policeReportPage.starttime + ': ' + (input.value ? input.value : "—————"));
          this.holdItems.startDate = input.value;
          break;
        case 'enddate':
          output.push('<br>' + languageResources.policeReportPage.endtime + ': ' + (input.value ? input.value : "—————"));
          this.holdItems.endDate = input.value;
          break;
        case 'starttime':
          if (this.holdItems.startDate) {
            output.push(input.options[input.selectedIndex].value);
          }
          break;
        case 'endtime':
          if (this.holdItems.endDate) {
            output.push(input.options[input.selectedIndex].value);
          }
          break;
        case 'nok':
          if (input.value) {
            output.push('NOK ' + input.value);
            break;
          }
          output.push(input.value);
          break;
        default:
          break;
      }
    }

    output = output.join(' ').trim();

    return output;
  }

  isElementVisible(elem) {
    if (elem.offsetHeight > 0) {
      return true;
    }

    return false;
  }

  getText(el) {
    const textNodes = Array.prototype.slice.call(el.childNodes, 0).filter((node) => {
      const text = node.innerText !== undefined ? node.innerText : node.textContent;

      return node.nodeType === 3 && !/^\s*$/.test(text);
    });

    let text = '';

    for (let i = 0; i < textNodes.length; i++) {
      const node = textNodes[i];

      if (text) {
        text += '\n';
      }

      text += node.innerText !== undefined ? node.innerText : node.textContent;
    }

    return text.trim();
  }
}
