import RWD from '../../02-tools/rwd/rwd';
import Keyboard from '../../02-tools/keyboard/keyboard';

export default class SiteNavigation {
  constructor(element) {
    const sectionButtons = element.querySelectorAll('.site-navigation__section-btn');
    const btnOpenClassName = 'site-navigation__section-btn--open';
    const siblingOpenClassName = 'site-navigation__section-items--open';
    const menuColumns = element.querySelectorAll('.js-menu-column');
    let isDesktopMenu = RWD.resize().xlarge;

    function isStateChanged(stored) {
      const rwd = RWD.resize();
      const current = rwd.xlarge;

      if (stored !== current) {
        isDesktopMenu = rwd.xlarge;
        return true;
      }

      return false;
    }

    function setAriaStates(open, button, list) {
      const isOpen = open;

      if (isOpen) {
        button.setAttribute('aria-expanded', true);
        list.setAttribute('aria-expanded', true);
        list.setAttribute('aria-hidden', false);
      } else {
        button.setAttribute('aria-expanded', false);
        list.setAttribute('aria-expanded', false);
        list.setAttribute('aria-hidden', true);
      }
    }

    if (sectionButtons) {
      if (isDesktopMenu) {
        for (const button of sectionButtons) {
          const list = button.nextElementSibling;

          if (isDesktopMenu) {
            button.classList.add(btnOpenClassName);
            list.classList.add(siblingOpenClassName);
          } else {
            button.classList.remove(btnOpenClassName);
            list.classList.remove(siblingOpenClassName);
          }

          setAriaStates(isDesktopMenu, button, list);
        }
      }

      window.addEventListener('resize', () => {
        if (isStateChanged(isDesktopMenu)) {
          for (const button of sectionButtons) {
            const list = button.nextElementSibling;

            if (isDesktopMenu) {
              button.classList.add(btnOpenClassName);
              list.classList.add(siblingOpenClassName);
            } else {
              button.classList.remove(btnOpenClassName);
              list.classList.remove(siblingOpenClassName);
            }

            setAriaStates(isDesktopMenu, button, list);
          }
        }
      });

      for (const button of sectionButtons) {
        const list = button.nextElementSibling;
        button.addEventListener('click', (e) => {
          e.target.focus();
          button.classList.toggle(btnOpenClassName);
          list.classList.toggle(siblingOpenClassName);
          setAriaStates(button.classList.contains(btnOpenClassName), button, list);
        });
      }
    }

    // Menu keyboard navigation
    const menuMap = [];

    for (const column of menuColumns) {
      const columnLinks = column.querySelectorAll('.site-navigation__link');

      menuMap.push(columnLinks);
    }

    menuMap.map((item) => {
      for (const link of item) {
        link.addEventListener('keydown', (e) => {
          const evt = e || window.event;
          const currentPosition = link.getAttribute('data-position').split('|');
          const currentCol = parseInt(currentPosition[0]);
          const currentLink = parseInt(currentPosition[1]);
          let nextCol;
          let previousCol;
          let nextLink;
          let previousLink;
          let neighbourLeft;
          let neighbourRight;

          if (evt.which === Keyboard.up || evt.which === Keyboard.down) {
            evt.preventDefault();
          }

          if (evt.which === Keyboard.up || evt.which === Keyboard.down ||
            evt.which === Keyboard.left || evt.which === Keyboard.right) {
            nextCol = menuColumns.item(currentCol + 1) ?
              menuColumns.item(currentCol + 1) :
              nextCol = menuColumns.item(0);

            previousCol = menuColumns.item(currentCol - 1) ?
              menuColumns.item(currentCol - 1) :
              menuColumns.item(menuColumns.length - 1);

            nextLink = menuColumns.item(currentCol).querySelectorAll('.site-navigation__link')[currentLink + 1] ?
              menuColumns.item(currentCol).querySelectorAll('.site-navigation__link')[currentLink + 1] :
              nextCol.querySelectorAll('.site-navigation__link')[0];

            previousLink = menuColumns.item(currentCol).querySelectorAll('.site-navigation__link')[currentLink - 1] ?
              menuColumns.item(currentCol).querySelectorAll('.site-navigation__link')[currentLink - 1] :
              previousCol.querySelectorAll('.site-navigation__link')[previousCol.querySelectorAll('.site-navigation__link').length - 1];

            neighbourLeft = previousCol.querySelectorAll('.site-navigation__link')[currentLink] ?
              previousCol.querySelectorAll('.site-navigation__link')[currentLink] :
              previousCol.querySelectorAll('.site-navigation__link')[previousCol.querySelectorAll('.site-navigation__link').length - 1];

            neighbourRight = nextCol.querySelectorAll('.site-navigation__link')[currentLink] ?
              nextCol.querySelectorAll('.site-navigation__link')[currentLink] :
              nextCol.querySelectorAll('.site-navigation__link')[nextCol.querySelectorAll('.site-navigation__link').length - 1];
          }

          if (evt.which === Keyboard.up) {
            previousLink.focus();
          } else if (evt.which === Keyboard.down) {
            nextLink.focus();
          } else if (evt.which === Keyboard.left) {
            neighbourLeft.focus();
          } else if (evt.which === Keyboard.right) {
            neighbourRight.focus();
          }
        });
      }
    });
  }
}
