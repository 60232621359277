// Cookies.js
// A complete cookies reader/writer framework with full Unicode support.

function makeSetterString(sKey, sValue, vEnd, sPath, sDomain, bSecure, vSameSite) {
  let sExpires = "";

  if (vEnd) {
      switch (vEnd.constructor) {
          case Number:
              sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
              break;
          case String:
              sExpires = "; expires=" + vEnd;
              break;
          case Date:
              sExpires = "; expires=" + vEnd.toUTCString();
              break;
      }
  }

  return encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires +
      (sDomain ? "; domain=" + sDomain : "") +
      (sPath ? "; path=" + sPath : "") +
      (bSecure ? "; secure" : "") +
      (!vSameSite || vSameSite.toString().toLowerCase() === "no_restriction" ? "" :
          vSameSite.toString().toLowerCase() === "lax" || Math.ceil(vSameSite) === 1 || vSameSite === true ? "; samesite=lax" :
          vSameSite.toString().toLowerCase() === "none" || vSameSite < 0 ? "; samesite=none" : "; samesite=strict");
}

const reURIAllowed = /[\-\.\+\*]/g;
const reCNameAllowed = /^(?:expires|max\-age|path|domain|secure|samesite|httponly)$/i;

const docCookies = {
  getItem: function(sKey) {
      if (!sKey) { return null; }
      return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/\\/g, "\\\\").replace(reURIAllowed, "\\$&") + "\\s*=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },

  setItem: function(sKey, sValue, vEnd, sPath, sDomain, bSecure, vSameSite) {
      if (!sKey || reCNameAllowed.test(sKey)) { return false; }
      document.cookie = makeSetterString(sKey, sValue, vEnd, sPath, sDomain, bSecure, vSameSite);
      return true;
  },

  removeItem: function(sKey, sPath, sDomain, bSecure, vSameSite) {
      if (!this.hasItem(sKey)) { return false; }
      document.cookie = makeSetterString(sKey, "", "Thu, 01 Jan 1970 00:00:00 GMT", sPath, sDomain, bSecure, vSameSite);
      return true;
  },

  hasItem: function(sKey) {
      if (!sKey || reCNameAllowed.test(sKey)) { return false; }
      return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(new RegExp(reURIAllowed, 'g'), "\\$&") + "\\s*=\\s*")).test(document.cookie);
  },

  keys: function() {
    return document.cookie.split(';').map(cookie => {
        return cookie.trim().split('=')[0];
    });
},

  clear: function(sPath, sDomain, bSecure, vSameSite) {
      const aKeys = this.keys();
      for (let nIdx = 0, nLen = aKeys.length; nIdx < nLen; nIdx++) {
          this.removeItem(aKeys[nIdx], sPath, sDomain, bSecure, vSameSite);
      }
  }
};

export default docCookies;
