/* eslint-disable react/jsx-indent-props */
import React from 'react';
import Moment from 'moment';
import Autocomplete from '../autocomplete/autocomplete.jsx';
import Accordion from '../accordion/accordion.jsx';
import OpeningHours from '../opening-hours/opening-hours.jsx';
import GeoLocation from '../../02-tools/geolocation/geolocation';
import OrgUnits from '../../02-tools/org-units/org-units';
import ErrorHandler from '../../02-tools/error-handler/error-handler';
import Html from '../../02-tools/html/html';
import Insight from '../../02-tools/insight/insight';
import idHelper from '../../02-tools/id-helper/idHelper.js';

export default class FindDistrictService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      SearchTitle: null,
      Loading: false,
      ResetAccordion: false,
      Results: [],
      Error: null,
      SearchText: '',
      id: null
    };

    this.page = 1;
    this.pages = 0;
  }

  componentDidUpdate() {
    if (!this.state.Loading) {
      const results = document.querySelector(".find-org-service__accordion");

      const length = (this.page - 1) * (this.props.PageSize || 5);
      const accordion = results?.children[length];
      accordion?.querySelector("button")?.focus();
    }
  }

  onGpsClick = (cumulate = false) => {
    this.setState({
      Loading: true,
    });

    cumulate ? (this.page = this.page) : (this.page = 1);

    GeoLocation.getGeoLocation()
      .then((position) => {
        const params =
          'latitude=' +
          position.coords.latitude +
          '&longitude=' +
          position.coords.longitude +
          '&services=' +
          this.props.Services +
          '&pageSize=' +
          this.props.PageSize +
          '&page=' +
          this.page;
        this._runPromise(OrgUnits.findDistrictService(params), cumulate);
        Insight.track('matomo', {
          isEvent: true,
          category: 'Finn',
          action: 'Finn - Søk',
          name: 'Finn - Søk - Din posisjon',
          value: 0,
        });

        Insight.track('ga', {
          isEvent: true,
          category: 'Finn',
          action: 'Finn - Søk',
          label: 'Finn - Søk - Din posisjon',
          value: 0,
        });
      })
      .catch((error) => {
        this.setState({
          Loading: false,
          Results: [],
          Error: ErrorHandler.handle({
            data: error,
            globalText: this.props.Translations.Errors,
          }),
        });
      });
  };

  onSubmit = (value, id, cumulate = false) => {
    this.setState({
      Loading: true,
      ResetAccordion: true,
      id
    });

    cumulate ? (this.page = this.page) : (this.page = 1);

    if (id && id !== '') {
      this._runPromise(
        OrgUnits.findDistrictServiceFromId(
          id +
            '?services=' +
            this.props.Services +
            '&pageSize=' +
            this.props.PageSize +
            '&page=' +
            this.page
        ),
        cumulate
      );
    } else {
      this._runPromise(
        OrgUnits.findDistrictServiceFromAddress(
          value +
            '?services=' +
            this.props.Services +
            '&pageSize=' +
            this.props.PageSize +
            '&page=' +
            this.page
        ),
        cumulate
      );
    }

    Insight.track('ga', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Søk',
      label: 'Finn - Søk - ' + value,
      value: 0,
    });
    Insight.track('matomo', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Søk',
      name: 'Finn - Søk - ' + value,
      value: 0,
    });
  };

  onShowMore = () => {
    this.page = this.page + 1;
    this.state.SearchText
      ? this.onSubmit(this.state.SearchText, this.state.id, true)
      : this.onGpsClick(true);

    Insight.track('ga', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Vis flere',
      label: 'Finn - Vis flere - ' + this.page,
      value: 0,
    });
    Insight.track('matomo', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Vis flere',
      name: 'Finn - Vis flere - ' + this.page,
      value: 0,
    });
  };

  _runPromise = (promise, cumulate = false) => {
    promise
      .then((data) => {
        let json = JSON.parse(data);
        let results = this.state.Results;

        this.noHitServices = this.props.Services.split(',');

        if (cumulate) {
          results.orgUnits = results.orgUnits.concat(json.orgUnits);
        }

        this.setState({
          Loading: false,
          SearchTitle: json.searchAddress,
          ResetAccordion: false,
          Results: cumulate ? results : json,
          Error: null,
        });
      })
      .catch((error) => {
        this.setState({
          Loading: false,
          ResetAccordion: false,
          Results: [],
          Error: ErrorHandler.handle({
            data: error,
            globalText: this.props.Translations.Errors,
          }),
        });
      });
  };

  getDistance = (distanceInMeters) => {
    const showAsMeters = distanceInMeters < 1000;
    let distance = showAsMeters
      ? Math.round(distanceInMeters) + ' m'
      : Math.round(distanceInMeters / 1000) + ' km';
    if (distanceInMeters === 0) {
      distance = '–';
    }

    return distance;
  };

  updateSearchText = (value) => {
    this.setState({
      SearchText: value,
    });
  };
  appointmentClicked = (e) => {
    Insight.track('matomo', {
      isEvent: true,
      category: 'Pass',
      action: 'klikk timebestilling',
      name: e.target.href,
      value: window.location.href,
    });
  };

  render() {
    let offices = [];
    if (this.state.Results.orgUnits) {
      this.state.Results.orgUnits.map((office, index) => {
        let hasSummerHours = Moment(
          office.openingHoursSummerWinter.summerStart
        ).isAfter('1900-01-01');
        let isSummerTime = false;
        let blocks = [];
        let freetextBlocks = [];

        if (hasSummerHours) {
          let year = Moment();
          let now = year.format('YYYY') + '' + Moment().format('MMDD');
          let start =
            year.format('YYYY') +
            '' +
            Moment(office.openingHoursSummerWinter.summerStart).format('MMDD');
          let end =
            year.format('YYYY') +
            '' +
            Moment(office.openingHoursSummerWinter.summerEnd).format('MMDD');

          if (start > end) {
            end =
              year.add(1, 'y').format('YYYY') +
              '' +
              Moment(office.openingHoursSummerWinter.summerEnd).format('MMDD');
          }

          if (now >= start && now <= end) {
            isSummerTime = true;
          }
        }
        offices.push(
          <Accordion
            Translations={this.props.Translations}
            key={index}
            index={index}
            label={''/*this.getDistance(office.distance)*/}
            title={office.name}
            ResetAccordion={this.state.ResetAccordion}
          >
            {office.info ? (
              <section
                className='notification notification--alert notification--has-icon notification__icon--bell'
                role='alert'
              >
                <div className='notification__message'>
                  <h2 className='h5 weight--medium' id={idHelper.createId(this.props.Translations.DeviationMessage)}>
                    {this.props.Translations.DeviationMessage}
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: Html.decode(office.info),
                    }}
                  ></p>
                </div>
              </section>
            ) : null}
            {office.blocks
              ? office.blocks.map((block, index) => {
                  blocks.push(
                    <section
                      key={'not-sec-' + index}
                      className='notification notification--alert notification--has-icon notification__icon--bell'
                      role='alert'
                    >
                      <div className='notification__message'>
                        {block.title ? (
                          <h2 className='h5 weight--medium' id={idHelper.createId(block.title)}>{block.title}</h2>
                        ) : null}
                        {block.text ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: Html.decode(block.text),
                            }}
                          ></p>
                        ) : null}
                      </div>
                    </section>
                  );
                })
              : null}
            {blocks}
            {office.showAppointmentLink && office.promotedAppointmentLink ? (
              office.linkAppointment ? (
                <a
                  href={office.linkAppointment}
                  className='call-to-action call-to-action--button link--no-underline'
                  data-gtm-component='appointment-link2'
                  onClick={this.appointmentClicked}
                >
                  {office.linkTitleAppointment}
                </a>
              ) : (
                <div className='notification notification--inverted notification--small'>
                  <p>{office.appointmentInfoText}</p>
                </div>
              )
            ) : null}
            <h2 className='h4' id={idHelper.createId(this.props.Translations.Contact)}>{this.props.Translations.Contact}</h2>
            <StructuredInfo
              Translations={this.props.Translations}
              Data={office}
              index={index}
            />
            {office.showAppointmentLink && !office.promotedAppointmentLink ? (
              office.linkAppointment ? (
                <a
                  href={office.linkAppointment}
                  className='call-to-action call-to-action--button link--no-underline'
                  data-gtm-component='appointment-link2'
                  onClick={this.appointmentClicked}
                >
                  {office.linkTitleAppointment}
                </a>
              ) : (
                <div className='notification notification--inverted notification--small'>
                  <p>{office.appointmentInfoText}</p>
                </div>
              )
            ) : null}
            <OpeningHours
              Translations={this.props.Translations}
              Data={office.openingHoursSummerWinter}
              hasSummerHours={hasSummerHours}
              isSummerTime={isSummerTime}
            />
            {office.serviceDescription ? (
              <div
                className='stack-large-fixed'
                dangerouslySetInnerHTML={{
                  __html: Html.decode(office.serviceDescription),
                }}
              ></div>
            ) : null}
            {office.freetextBlocks
              ? office.freetextBlocks.map((block) => {
                  freetextBlocks.push(block.text);
                })
              : null}
            {freetextBlocks.length > 0 ? (
              <div
                className='stack-large-fixed'
                dangerouslySetInnerHTML={{
                  __html: Html.decode(freetextBlocks.join('')),
                }}
              ></div>
            ) : null}
            {office.serviceLink ? (
              <a
                href={office.serviceLink}
                className='call-to-action call-to-action--constrained link--no-underline'
              >
                {office.serviceLinkTitle}
                <span
                  className='call-to-action__icon'
                  role='presentation'
                ></span>
              </a>
            ) : null}
          </Accordion>
        );
      });
    }

    return (
      <div className='row stack-large-fixed layout--alignment'>
        <div className={this.props.GridClasses}>
          <h2 className={this.props.HeadingSize} id={idHelper.createId(this.props.Title)}>{this.props.Title}</h2>
          <section
            className='find-district-service'
            data-gtm-component='find'
            data-gtm-category={this.props.GTM.Category}
            data-gtm-action={this.props.GTM.Action}
            data-testid='find-district-service'
          >
            {this.props.Summary ? (
              <p className='find-org-unit__summary'>{this.props.Summary}</p>
            ) : null}
            <Autocomplete
              Translations={this.props.Translations}
              SearchTitle={this.state.SearchTitle}
              onGpsClick={this.onGpsClick}
              onSubmit={this.onSubmit}
              onShowMore={this.onShowMore}
              updateSearchText={this.updateSearchText}
              Loading={this.state.Loading}
              Error={this.state.Error}
              HasPositionOption={true}
            />
            <div
              className={`find-org-service__results ${
                offices.length ? '' : 'find-org-service__results--no-results'
              }`}
              aria-live='polite'
            >
              {offices.length ? (
                <div className='find-org-service__result'>
                  <h3 className='list-group__legend' id={idHelper.createId(this.props.Translations.Place)}>
                    {this.props.Translations.Place}
                    {/*
                    <span className='list-group__label'>
                      {this.props.Translations.Distance}
                    </span>
                    */}
                  </h3>
                  <section className='accordion find-org-service__accordion'>
                    {offices}
                  </section>
                  {this.pages > this.page ||
                  (this.page > 1 && this.state.Loading) ? (
                    <button
                      className={`show-more ${
                        this.state.Loading ? 'show-more--loading' : ''
                      }`}
                      disabled={this.state.Loading ? true : false}
                      aria-busy={this.state.Loading ? true : false}
                      onClick={this.onShowMore}
                    >
                      <span
                        className='show-more__loading-icon'
                        role='presentation'
                      ></span>
                      <span
                        className='show-more__more-icon'
                        role='presentation'
                      ></span>
                      <span className='show-more__btn-text'>
                        {this.props.Translations.ShowMore}
                      </span>
                    </button>
                  ) : null}
                </div>
              ) : null}
            </div>
          </section>
        </div>
      </div>
    );
  }
}

class StructuredInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  removeSpaces = (data) => {
    return data.split(' ').join('');
  };

  render() {
    const p = this.props;
    let structuredInfo = [];

    if (p.Data.phone) {
      structuredInfo.push(
        <dt className='structured-info__key' key={p.Translations.Phone}>
          {p.Translations.Phone}
        </dt>
      );
      structuredInfo.push(
        <dd
          className='structured-info__value'
          key={p.Translations.Phone + p.Data.phone}
        >
          <a href={'tel:' + this.removeSpaces(p.Data.phone)}>{p.Data.phone}</a>
        </dd>
      );
    }
    if (p.Data.mobile) {
      structuredInfo.push(
        <dt className='structured-info__key' key={p.Translations.Mobile}>
          {p.Translations.Mobile}
        </dt>
      );
      structuredInfo.push(
        <dd
          className='structured-info__value'
          key={p.Translations.Mobile + p.Data.mobile}
        >
          <a href={'tel:' + this.removeSpaces(p.Data.mobile)}>
            {p.Data.mobile}
          </a>
        </dd>
      );
    }
    if (p.Data.address) {
      structuredInfo.push(
        <dt
          className='structured-info__key'
          key={p.Translations.VisitorAddress}
        >
          {p.Translations.VisitorAddress}
        </dt>
      );
      if (p.Data.mapUrl) {
        structuredInfo.push(
          <dd
            className='structured-info__value'
            key={p.Translations.VisitorAddress + p.Data.address}
          >
            <span className='inline-regular-fixed'>{p.Data.address}</span>
            <a
              href={p.Data.mapUrl}
              className='structured-info__link'
              target='_blank'
            >
              {p.Translations.Map}
            </a>
          </dd>
        );
      } else {
        structuredInfo.push(
          <dd
            className='structured-info__value'
            key={p.Translations.VisitorAddress + p.Data.address}
          >
            <span className='inline-regular-fixed'>{p.Data.address}</span>
          </dd>
        );
      }
    }
    if (p.Data.mailAddress) {
      structuredInfo.push(
        <dt className='structured-info__key' key={p.Translations.MailAddress}>
          {p.Translations.MailAddress}
        </dt>
      );
      structuredInfo.push(
        <dd
          className='structured-info__value'
          key={p.Translations.MailAddress + p.Data.mailAddress}
        >
          {p.Data.mailAddress}
        </dd>
      );
    }
    if (p.Data.email) {
      structuredInfo.push(
        <dt className='structured-info__key' key={p.Translations.Email}>
          {p.Translations.Email}
        </dt>
      );
      structuredInfo.push(
        <dd
          className='structured-info__value'
          key={p.Translations.Email + p.Data.email}
        >
          <a href={'mailto:' + p.Data.email}>{p.Data.email}</a>
        </dd>
      );
    }
    if (p.Data.emailInformation) {
      structuredInfo.push(
        <dt
          className='structured-info__key show-for-sr'
          key={p.Translations.EmailInformation}
        >
          {p.Translations.EmailInformation}
        </dt>
      );
      structuredInfo.push(
        <dd
          className='structured-info__value clearfix'
          key={p.Translations.EmailInformation + '1'}
        >
          {p.Data.emailInformation}
        </dd>
      );
    }

    return structuredInfo.length ? (
      <dl className='structured-info' key={p.index}>
        {structuredInfo}
      </dl>
    ) : null;
  }
}
