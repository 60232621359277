import Stjs from 'stjs';

export default function VVRTransformJson(data, districtsParam, serviceAreasParam, labels) {
  const districtFacets = data.facets[0];
  const businessFieldFacets = data.facets[1];

  const districtFacetsMapped = Stjs.select(districtFacets).transformWith({
    ResponsibleFilterItemList: {
      Label: labels.distrikter,
      ParameterName: districtsParam,
      Items: {
        '{{#each terms}}': {
          Name: '{{name}}',
          Id: '{{term}}',
          NumberOfItems: '{{count}}',
          Selected: false,
          VisbleInMenu: true,
          ContentType: 'DistrictPage',
        },
      },
    },
  }).root();

  const businessFieldFacetsMapped = Stjs.select(businessFieldFacets).transformWith({
    VVRBusinessList: {
      Label: labels.virkeomrader,
      ParameterName: serviceAreasParam,
      Items: {
        '{{#each terms}}': {
          Name: '{{name}}',
          Id: '{{term}}',
          NumberOfItems: '{{count}}',
          Selected: false,
          Group: '{{group.toLowerCase()}}',
        },
      },
    },
  }).root();

  const filterItems = {
    VVRFilters: {
        ...districtFacetsMapped,
        ...businessFieldFacetsMapped
    },
  };

  const filterResult = Stjs.select(data).transformWith({
    filterResult: {
      TotalResults: data.totalMatching,
      ResultList: {
        '{{#each filteredItems}}': {
          Item: '{{this}}',
        },
      },
    }}).root();


  const filterPagination = Stjs.select(data.pagnation).transformWith({
    filterPagination: {
      ActivePage: '{{activePage}}',
      FirstPage: '{{firstPage}}',
      LastPage: '{{lastPage}}',
      TotalPages: '{{totalPages}}',
      PageSize: '{{pageSize}}',
      Parameter: '{{parameter}}'
    }}).root();

  const selectedFilterItems = {
    SelectedFilterItems: {
      ResponsibleFilterItemList: null,
      VVRFilterList: null
    }
  };

  const districts = data.searchFilter.distrikt ? data.searchFilter.distrikt.split('|') : [];
  const services = data.searchFilter.services ? data.searchFilter.services.split('|') : [];

  if (districts.length) {
    selectedFilterItems.SelectedFilterItems.ResponsibleFilterItemList = selectedFilterItems.SelectedFilterItems.ResponsibleFilterItemList ? selectedFilterItems.SelectedFilterItems.ResponsibleFilterItemList : {};
    selectedFilterItems.SelectedFilterItems.ResponsibleFilterItemList.Label = labels.distrikter;
    selectedFilterItems.SelectedFilterItems.ResponsibleFilterItemList.ParameterName = districtsParam;
    selectedFilterItems.SelectedFilterItems.ResponsibleFilterItemList.Items = selectedFilterItems.SelectedFilterItems.ResponsibleFilterItemList.Items ? selectedFilterItems.SelectedFilterItems.ResponsibleFilterItemList.Items : [];
  }

  if (services.length) {
    selectedFilterItems.SelectedFilterItems.VVRFilterList = selectedFilterItems.SelectedFilterItems.VVRFilterList ? selectedFilterItems.SelectedFilterItems.VVRFilterList : {};
    selectedFilterItems.SelectedFilterItems.VVRFilterList.Label = labels.virkeomrader;
    selectedFilterItems.SelectedFilterItems.VVRFilterList.ParameterName = serviceAreasParam;
    selectedFilterItems.SelectedFilterItems.VVRFilterList.Items = selectedFilterItems.SelectedFilterItems.VVRFilterList.Items ? selectedFilterItems.SelectedFilterItems.VVRFilterList.Items : [];
  }

  filterItems.VVRFilters.ResponsibleFilterItemList.Items.map((item) => {
    districts.map((district) => {
      if (item.Id.toLowerCase() === district.toLowerCase()) {
        item.Selected = true;
        selectedFilterItems.SelectedFilterItems.ResponsibleFilterItemList.Items.push(
          { Name: item.Name, Id: district }
        );
      }
    });
  });

  filterItems.VVRFilters.VVRBusinessList.Items.map((item) => {
    services.map((service) => {
      if (item.Id.toLowerCase() === service.toLowerCase()) {
        item.Selected = true;
        selectedFilterItems.SelectedFilterItems.VVRFilterList.Items.push(
          { Name: item.Name, Id: service }
        );
      }
    });
  });

  return {...filterItems, ...filterResult, ...filterPagination, ...selectedFilterItems};
}