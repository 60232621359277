import Keyboard from '../../02-tools/keyboard/keyboard';
import SCROLLTO from '../../02-tools/scroll-to/scroll-to';

export default class SkipToContent {
  constructor(element) {
    const target = document.querySelector(element.getAttribute('href'));

    if (target) {
      element.addEventListener('click', (e) => {
        e.preventDefault();
        SCROLLTO.y(target.offsetTop, 500, 'easeInOutQuint');
        target.focus();
      });

      element.addEventListener('keydown', (e) => {
        const evt = e || window.event;
        if (evt.which === Keyboard.space) {
          evt.preventDefault();
          element.click();
        } if (evt.which === Keyboard.enter) {
          evt.preventDefault();
          element.click();
        }
      });
    }
  }
}
