import React from 'react';

/*type Props = {
  Hidden: Boolean;
	IsHigh: Boolean;
  Translations: Array<Object>;
};*/

export default class EventSonar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const p = this.props;

    return (
      <div className={`shrink column event__label-column ${p.IsHigh ? 'event__label-column--major' : ''}`}>
        <span className={`event__label event__label--inline ${p.Hidden ? 'event__label--hidden' : ''}`} aria-hidden={p.Hidden}>
          <span className="event__sonar"></span>
          <span className="event__label-text">{p.Translations.ThePoliceInforms}</span>
        </span>
      </div>
    )
  }
}