import React from 'react';
import idHelper from '../../02-tools/id-helper/idHelper';

export default class FormSummaryStep extends React.Component {
	constructor(props) {
		super(props);
    }

	changeInput = (e) => {
		e.preventDefault();
		this.props.navigateToStep(this.props.StepIndex, false);
	}

    render() {
        let formDataCopy = [...this.props.FormData];
        // Reverse array and filter out items with name properties that already exists.
	    let formDataCopyFiltered = formDataCopy.reverse().filter((item, index, self) =>
		    index === self.findIndex((t) => (
			    t.name === item.name
		    ))
        )

        return (
            <li className="form-summary__step">
                <h3 className="form-summary__step-title" id={idHelper.createId(this.props.FormStep.title)}>{this.props.FormStep.title}</h3>
				<ul className="form-summary__input-list list--reset-style">
                    {formDataCopyFiltered.reverse().map((step, index) => {
                        if (step.value !== '' && step.type !== 'hidden') {
                            return (
                                <li className="form-summary__input-list-item" key={index}>
                                    <span className="form-summary__label">{step.label}</span> <span className="form-summary__value">{step.value}</span>
                                </li>
                            )
                        }
	                })}
                </ul>
                {this.props.navigateToStep ?
                    <a onClick={(e) => this.changeInput(e)} className="form-summary__edit-step-btn" href="#" role="button">Endre svar</a>
                : null}
            </li>
		);
	}
}
