import React from "react";

import XHR from "../../02-tools/xhr/xhr";
import Params from "../../02-tools/params/params";
import Insight from "../../02-tools/insight/insight";
import VVRTransformJson from "../vvr/vvr-transform-json.js";

import FilterPanel from "./filter-panel.jsx";
import FilterPaging from "./filter-paging.jsx";
import { toPascalCase } from "../../02-tools/xhr/xhr-helper.js";

/*type Props = {
  API: String;
  SelectedFilterItems: Array<Object>;
  FilterItems: Array<Object>;
  FilterResult: Array<Object>;
  PaginationFilterItem: Array<Object>;
  SortItems: Array<Object>;
  SortParam: String;
	ServiceAreasParam: String;
	DistrictsParam: String;
  Translations: Array<Object>;
	IsVVR: Boolean;
};*/

const CONSTANTS = {
    funksjon: "FunctionFilterItemList",
    enhet: "ResponsibleFilterItemList",
    distrikter: "ResponsibleFilterItemList",
    virkeomrader: "VVRFilterList",
    tema: "ListOfCategoryFilterItemLists",
    innholdstype: "ListOfCategoryFilterItemLists",
    aar: "YearFilterItemList",
    fradato: "StartDate",
    tildato: "EndDate",
};

export default class Filter extends React.Component {
    constructor(props) {
        super(props);

        this.loading = false;

        this.state = {
            results: props.FilterResult.ResultList,
            totalResults: props.FilterResult.TotalResults,
            selectedFilterItems: props.SelectedFilterItems,
            filterItems: props.FilterItems,
            paginationFilterItem: props.PaginationFilterItem,
        };
    }

    filter = (name, value, param, isChecked) => {
        this.loading = true;
        const pagingParameter = this.state.paginationFilterItem.Parameter;
        const hasPaging = Params.getParams()[pagingParameter];
        const labels = this.props.Translations.Labels;
        let params = Params.getParams();
        let newSelectedFilterItems = this.state.selectedFilterItems;

        name = name ? name?.toLowerCase() : name;
        if (value) {
            if (parseInt(value)) {
                value = parseInt(value);
            } else {
                value = value?.toLowerCase();
            }
        }

        if (isChecked) {
            if (name === "tema" || name === "innholdstype") {
                if (!newSelectedFilterItems[CONSTANTS[name]]) {
                    newSelectedFilterItems[CONSTANTS[name]] = [];
                }

                let indexOfList = newSelectedFilterItems[
                    CONSTANTS[name]
                ].findIndex((i) => i.ParameterName == name);

                if (indexOfList < 0) {
                    indexOfList = newSelectedFilterItems[CONSTANTS[name]].push({
                        Label: labels[name],
                        ParameterName: name,
                        Items: [],
                    });
                    indexOfList--;
                }
                newSelectedFilterItems[CONSTANTS[name]][indexOfList].Items.push(
                    {
                        Name: param,
                        Id: value,
                    },
                );
            } else {
                if (!newSelectedFilterItems[CONSTANTS[name]]) {
                    newSelectedFilterItems[CONSTANTS[name]] = {
                        Label: labels[name],
                        ParameterName: name,
                        Items: [],
                    };
                }
                newSelectedFilterItems[CONSTANTS[name]].Items.push({
                    Name: param,
                    Id: value,
                });
            }

            params[name] = Params.addParam(value, params[name]);
        } else {
            if (name === "tema" || name === "innholdstype") {
                let indexOfList = newSelectedFilterItems[
                    CONSTANTS[name]
                ].findIndex((i) => i.ParameterName == name);
                let indexOfItem = newSelectedFilterItems[CONSTANTS[name]][
                    indexOfList
                ].Items.findIndex((i) => i.Id == value);
                newSelectedFilterItems[CONSTANTS[name]][
                    indexOfList
                ].Items.splice(indexOfItem, 1);

                if (
                    newSelectedFilterItems[CONSTANTS[name]][indexOfList].Items
                        .length === 0
                ) {
                    newSelectedFilterItems[CONSTANTS[name]].splice(
                        indexOfList,
                        1,
                    );
                }
            } else if (name === "tildato" || name === "fradato") {
                newSelectedFilterItems[CONSTANTS[name]].Date = null;
                value = param;
            } else {
                let indexOfItem = newSelectedFilterItems[
                    CONSTANTS[name]
                ].Items.findIndex((i) => i.Id == value);
                newSelectedFilterItems[CONSTANTS[name]].Items.splice(
                    indexOfItem,
                    1,
                );

                if (
                    newSelectedFilterItems[CONSTANTS[name]].Items.length === 0
                ) {
                    newSelectedFilterItems[CONSTANTS[name]] = null;
                }
            }

            params[name] = Params.removeParam(value + "", params[name]);
        }

        this.setState({
            selectedFilterItems: newSelectedFilterItems,
        });

        // If paging page has been set we always want to reset it to first page when filtering is done
        if (hasPaging) {
            params[pagingParameter] = "1";
        }

        let result = Params.createParams(params);

        this._resultsQuery("?" + result.substring(1));

        Insight.track("ga", {
            isEvent: false,
            data: location.pathname + location.search,
        });
        Insight.track("matomo", {
            isEvent: false,
            data: location.pathname + location.search,
            title: document.title,
        });
    };

    updateParams = (params) => {
        this.loading = true;
        let newSelectedFilterItems = this.state.selectedFilterItems;
        let translations = this.props.Translations;
        let result = Params.createParams(params);

        if (params.fradato) {
            newSelectedFilterItems[CONSTANTS["fradato"]] = {
                Date: { FormatedTime: params.fradato },
                Label: translations.Labels.fradato,
                ParameterName: "fradato",
            };
        } else {
            newSelectedFilterItems[CONSTANTS["fradato"]]
                ? (newSelectedFilterItems[CONSTANTS["fradato"]].Date = null)
                : null;
        }
        if (params.tildato) {
            newSelectedFilterItems[CONSTANTS["tildato"]] = {
                Date: { FormatedTime: params.tildato },
                Label: translations.Labels.tildato,
                ParameterName: "tildato",
            };
        } else {
            newSelectedFilterItems[CONSTANTS["tildato"]]
                ? (newSelectedFilterItems[CONSTANTS["tildato"]].Date = null)
                : null;
        }

        this.setState({
            selectedFilterItems: newSelectedFilterItems,
        });

        this._resultsQuery("?" + result.substring(1));
    };

    resetFilter = () => {
        this.loading = true;
        let newSelectedFilterItems = this.state.selectedFilterItems;
        const params = Params.getParams();

        Object.keys(newSelectedFilterItems).map((item) => {
            newSelectedFilterItems[item] = null;
        });

        this.setState({
            selectedFilterItems: newSelectedFilterItems,
        });

        if (params[this.props.SortParam]) {
            const result = Params.createParams({
                sortering: params[this.props.SortParam],
            });
            this._resultsQuery("?" + result.substring(1));
        } else {
            this._resultsQuery("?");
        }

        Insight.track("ga", {
            isEvent: false,
            data: location.pathname + location.search,
        });
        Insight.track("matomo", {
            isEvent: false,
            data: location.pathname + location.search,
            title: document.title,
        });
    };

    removeParamAndUpdateQueryString = (name, value) => {
        let params = Params.getParams();

        name = name?.toLowerCase();
        value = value?.toLowerCase();

        params[name] = Params.removeParam(value, params[name]);

        let result = Params.createParams(params);

        if (typeof window === "object") {
            window.history.replaceState({}, "", "?" + result.substring(1));
        }
    };

    _resultsQuery = (params) => {
        this.loading = true;
        if (typeof window === "object") {
            window.history.replaceState({}, "", params);
        }

        XHR.get(this.props.API, params)
            .then((data) => {
                let json = JSON.parse(data, toPascalCase);
                this.loading = false;

                if (this.props.IsVVR) {
                    const transformedJson = VVRTransformJson(
                        json,
                        this.props.DistrictsParam,
                        this.props.ServiceAreasParam,
                        this.props.Translations.Labels,
                    );

                    this.setState({
                        filterItems: transformedJson.VVRFilters,
                        results: transformedJson.filterResult.ResultList,
                        totalResults: transformedJson.filterResult.TotalResults,
                        paginationFilterItem: transformedJson.filterPagination,
                    });
                } else {
                    this.setState({
                        filterItems: {
                            ResponsibleFilterItemList:
                                json.FilterItems.ResponsibleFilterItemList,
                            ListOfCategoryFilterItemLists:
                                json.FilterItems.ListOfCategoryFilterItemLists,
                            DateFilterItem: json.FilterItems.DateFilterItem,
                            YearFilterItemList:
                                json.FilterItems.YearFilterItemList,
                            FunctionFilterItemList:
                                json.FilterItems.FunctionFilterItemList,
                        },
                        results: json.FilterResult.ResultList,
                        totalResults: json.FilterResult.TotalResults,
                        paginationFilterItem: json.PagnationFilterItem,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.loading = false;
            });
    };

    render() {
        const p = this.props;
        return (
            <section className="filter stack-large">
                <FilterPanel
                    FilterItems={this.state.filterItems}
                    SelectedFilterItems={this.state.selectedFilterItems}
                    filter={this.filter}
                    updateParams={this.updateParams}
                    resetFilter={this.resetFilter}
                    Translations={this.props.Translations}
                    Loading={this.loading}
                    IsVVR={this.props.IsVVR}
                    ServiceAreasParam={this.props.ServiceAreasParam}
                />
                <div className="row row--relative">
                    <FilterResultOverlay Hidden={!this.loading} />
                    <span
                        className="show-for-sr"
                        aria-atomic="false"
                        aria-live="polite"
                    >
                        {this.state.totalResults
                            ? `${this.state.totalResults} ${this.props.Translations.AvailableResults}`
                            : `0 ${this.props.Translations.AvailableResults}`}
                    </span>
                    {React.Children.map(this.props.children, (child) => {
                        return React.cloneElement(child, {
                            Items: this.state.results,
                            TotalItems: this.state.totalResults,
                            SelectedFilterItems: this.state.selectedFilterItems,
                            Translations: this.props.Translations,
                            updateParams: this.updateParams,
                        });
                    })}
                    <span
                        className="show-for-sr"
                        aria-atomic="false"
                        aria-live="polite"
                    >
                        {this.props.Translations.PresentingPage}{" "}
                        {this.state.paginationFilterItem.ActivePage}{" "}
                        {this.props.Translations.Of}{" "}
                        {this.state.paginationFilterItem.TotalPages}
                    </span>
                    <FilterPaging
                        Translations={this.props.Translations}
                        PaginationFilterItem={this.state.paginationFilterItem}
                        updateParams={this.updateParams}
                        removeParamAndUpdateQueryString={
                            this.removeParamAndUpdateQueryString
                        }
                        filter={this.filter}
                    />
                </div>
            </section>
        );
    }
}

class FilterResultOverlay extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const p = this.props;

        return (
            <div
                className={
                    this.props.Hidden
                        ? "filter__result-overlay filter__result-overlay--hidden"
                        : "filter__result-overlay"
                }
                aria-hidden={this.props.Hidden}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 37 38"
                    className="filter__result-overlay-icon"
                >
                    <defs>
                        <path id="a" d="M9.744 2.046V.223H.482v1.823h9.262z" />
                        <path id="c" d="M2.782.77H.955v9.25h1.827V.77z" />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(27.038 17.73)">
                            <mask id="b" fill="#fff">
                                <use xlinkHref="#a" />
                            </mask>
                            <path
                                fill="#E6E9F2"
                                d="M1.394.223a.912.912 0 1 0 0 1.823h7.438a.913.913 0 0 0 .914-.912c0-.503-.41-.91-.914-.91H1.394z"
                                mask="url(#b)"
                            />
                        </g>
                        <path
                            fill="#A1A8B5"
                            d="M.913 17.954a.912.912 0 1 0 0 1.823H8.35a.913.913 0 0 0 .913-.912c0-.503-.41-.91-.913-.91H.913z"
                        />
                        <path
                            fill="#EAEDF4"
                            d="M27.543 23.09a.912.912 0 1 0-.912 1.58l6.442 3.711a.912.912 0 1 0 .911-1.578l-6.44-3.714z"
                        />
                        <path
                            fill="#B0B6C2"
                            d="M3.711 9.35a.91.91 0 1 0-.913 1.578L9.24 14.64a.912.912 0 1 0 .914-1.578L3.71 9.35z"
                        />
                        <path
                            fill="#F1F2F8"
                            d="M24.203 27.093a.913.913 0 0 0-1.582.911l3.72 6.433a.914.914 0 0 0 1.581-.911l-3.719-6.433z"
                        />
                        <path
                            fill="#BFC4CE"
                            d="M10.443 3.294a.912.912 0 1 0-1.582.912l3.72 6.432a.912.912 0 0 0 1.582-.912l-3.72-6.432z"
                        />
                        <path
                            fill="#F6F8FC"
                            d="M19.305 28.892a.913.913 0 0 0-1.827 0v7.428a.913.913 0 0 0 1.827 0v-7.428z"
                        />
                        <g transform="translate(16.524 -.269)">
                            <mask id="d" fill="#fff">
                                <use xlinkHref="#c" />
                            </mask>
                            <path
                                fill="#C8CDD9"
                                d="M2.782 1.68a.912.912 0 0 0-1.827 0v7.427a.913.913 0 0 0 1.827 0V1.681z"
                                mask="url(#d)"
                            />
                        </g>
                        <path
                            fill="#FFF"
                            d="M14.162 28.005a.913.913 0 0 0-1.582-.91l-3.718 6.431a.91.91 0 0 0 .334 1.245.915.915 0 0 0 1.247-.334l3.719-6.432z"
                        />
                        <path
                            fill="#D1D6E1"
                            d="M27.922 4.206a.912.912 0 1 0-1.582-.912l-3.718 6.432a.913.913 0 0 0 1.58.912l3.72-6.432z"
                        />
                        <path
                            fill="#959DAE"
                            d="M10.153 24.668a.911.911 0 1 0-.914-1.579L2.8 26.803a.912.912 0 0 0 .913 1.58l6.44-3.715z"
                        />
                        <path
                            fill="#DCDFE8"
                            d="M33.984 10.928a.912.912 0 0 0-.912-1.578l-6.442 3.712a.911.911 0 1 0 .913 1.58l6.441-3.714z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}
