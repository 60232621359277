import React, { Component } from "react";
import Insight from "../insight/insight";

class ABRouter extends Component {
    constructor(props) {
        super(props);

        let selectedVariant = null;

        if (typeof window !== "undefined") {
            const savedVariant = localStorage.getItem(props.experimentName);
            selectedVariant = savedVariant || null;
        }

        this.state = {
            selectedVariant,
        };
    }

    componentDidMount() {
        const { experimentName, variants } = this.props;

        if (!this.state.selectedVariant) {
            const chosenVariant = this.selectVariantByPercentage(variants);
            this.setState({ selectedVariant: chosenVariant });

            if (typeof window !== "undefined") {
                localStorage.setItem(experimentName, chosenVariant);

                Insight.track("matomo", {
                    isEvent: true,
                    category: `A/B Test`,
                    action: experimentName,
                    name: chosenVariant,
                });
            }
        }
    }

    selectVariantByPercentage(variants) {
        const sumOfPercentages = variants.reduce(
            (sum, variant) => sum + variant.percentage,
            0,
        );
        const random = Math.random() * sumOfPercentages;
        let cumulativePercentage = 0;

        for (let i = 0; i < variants.length; i++) {
            cumulativePercentage += variants[i].percentage;
            if (random < cumulativePercentage) {
                return variants[i].name;
            }
        }

        // Fallback
        return variants[0].name;
    }

    render() {
        const { variants } = this.props;
        const { selectedVariant } = this.state;

        const VariantComponent = variants.find(
            (v) => v.name === selectedVariant,
        )?.component;

        return VariantComponent ? VariantComponent : null;
    }
}

export default ABRouter;
