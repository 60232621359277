import React from "react";
import idHelper from "../../02-tools/id-helper/idHelper";

/*type Props = {
  Translations: Array<Object>;
  OpeningHours: Array<Object>;
  HasAppointments: Boolean;
  HasPhoneHours: Boolean;
  HasDropIn: Boolean;
  HasPassport: Boolean;
  HideTitles: Boolean;
};*/

export default class OpeningHoursList extends React.Component {
    constructor(props) {
        super(props);
    }

    getDayName = (day) => {
        let dayName = "";

        switch (day) {
            case 1:
                dayName = this.props.Translations.Monday;
                break;
            case 2:
                dayName = this.props.Translations.Tuesday;
                break;
            case 3:
                dayName = this.props.Translations.Wednesday;
                break;
            case 4:
                dayName = this.props.Translations.Thursday;
                break;
            case 5:
                dayName = this.props.Translations.Friday;
                break;
            case 6:
                dayName = this.props.Translations.Saturday;
                break;
            case 7:
                dayName = this.props.Translations.Sunday;
                break;
            default:
                return "";
        }

        return dayName;
    };

    formatOpeningHour = (item) => {
        if (item) {
            const isOpen24Hours = item === "00:00-00:00" ? true : false;
            const hasClosingHour = item.split("-").length > 1 ? true : false;

            if (isOpen24Hours) {
                return this.props.Translations.Open24Hours;
            } else if (!hasClosingHour) {
                return item + "–*";
            } else {
                return item.replace(/,/g, ", ");
            }
        } else {
            return this.props.Translations.Closed;
        }
    };

    generateOpeningHoursList = (key, week) => {
        let list = [];

        week.map((day, idx) => {
            if (
                (day.dayOfWeek === 6 || day.dayOfWeek === 7) &&
                day[key] === null
            ) {
                return;
            }
            list.push(
                <div className="structured-info__key-value--aligned" key={idx}>
                    <dt className="structured-info__key weight--normal">
                        {this.getDayName(day["dayOfWeek"])}
                    </dt>
                    <dd className="structured-info__value">
                        {this.formatOpeningHour(day[key])}
                    </dd>
                </div>,
            );
        });

        return list;
    };

    render() {
        const p = this.props;
        let lists = [];

        if (p.HasAppointments) {
            lists.push(
                <section
                    className="opening-hours__panel-section"
                    key="apppointments"
                >
                    <h2
                        className="h4 weight--medium"
                        id={idHelper.createId(p.Translations.Appointment)}
                    >
                        {p.Translations.Appointment}
                    </h2>
                    <dl className="structured-info structured-info--aligned">
                        {this.generateOpeningHoursList(
                            "appointment",
                            p.OpeningHours,
                        )}
                    </dl>
                </section>,
            );
        }

        if (p.HasDropIn) {
            lists.push(
                <section className="opening-hours__panel-section" key="dropin">
                    <h2
                        className="h4 weight--medium"
                        id={idHelper.createId(p.Translations.DropIn)}
                    >
                        {p.Translations.DropIn}
                    </h2>
                    <dl className="structured-info structured-info--aligned">
                        {this.generateOpeningHoursList(
                            "dropin",
                            p.OpeningHours,
                        )}
                    </dl>
                </section>,
            );
        }

        if (p.HasPassport) {
            lists.push(
                <section
                    className="opening-hours__panel-section"
                    key="passport"
                >
                    <h2
                        className="h4 weight--medium"
                        id={idHelper.createId(p.Translations.Passport)}
                    >
                        {p.Translations.Passport}
                    </h2>
                    <dl className="structured-info structured-info--aligned">
                        {this.generateOpeningHoursList(
                            "passport",
                            p.OpeningHours,
                        )}
                    </dl>
                </section>,
            );
        }

        if (p.HasPhoneHours) {
            lists.push(
                <section
                    className="opening-hours__panel-section"
                    key="phonehours"
                >
                    <h2
                        className="h4 weight--medium"
                        id={idHelper.createId(p.Translations.PhoneHours)}
                    >
                        {p.Translations.PhoneHours}
                    </h2>
                    <dl className="structured-info structured-info--aligned">
                        {this.generateOpeningHoursList("phone", p.OpeningHours)}
                    </dl>
                </section>,
            );
        }

        if (p.HasPrimary) {
            lists.push(
                <section className="opening-hours__panel-section" key="primary">
                    <h2
                        className="h4 weight--medium"
                        id={idHelper.createId(p.Translations.Primary)}
                    >
                        {p.Translations.Primary}
                    </h2>
                    <dl className="structured-info structured-info--aligned">
                        {this.generateOpeningHoursList(
                            "primary",
                            p.OpeningHours,
                        )}
                    </dl>
                </section>,
            );
        }

        return <>{lists}</>;
    }
}
