import React from "react";
import ABRouter from "../../02-tools/ab-router/ab-router.jsx";
import NewsList from "./news-list.jsx";

export default class NewsListABTester extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const variants = [
            {
                name: "Original",
                percentage: 50,
                component: <NewsList {...this.props} />,
            },
            {
                name: "Challenger",
                percentage: 50,
                component: <></>,
            },
        ];

        return (
            <ABRouter
                variants={variants}
                experimentName="AB-Nyhetsutlisting-Forside"
            />
        );
    }
}
