export function toPascalCase(key, value) {
    if (value && typeof value === 'object'){
      for (var k in value) {
        if (/^[a-z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toUpperCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }

