import Keyboard from '../../02-tools/keyboard/keyboard';

export default class Modal {
    constructor(element) {
        const html = document.querySelector('html');
        const header = document.querySelector('.pno-header');
        const footer = document.querySelector('.pno-footer');
        const main = document.querySelector('main');
        const skipContent = document.querySelector('.skip-to-content');
        const modalCloseBtn = element.querySelector('.js-close-modal');
        const modalTriggerBtn = document.querySelector(`[aria-controls="${element.id}"]:not(.modal__close-btn)`);

        if (modalCloseBtn && modalTriggerBtn) {
            modalCloseBtn.addEventListener('click', () => {
                skipContent.setAttribute('tabindex', '0');
                html.classList.remove('html--modal-open');
                if (header) header.classList.toggle('header--modal-open');
                if (main) main.classList.toggle('main-content--modal-open');
                if (footer) footer.classList.toggle('footer--modal-open');
                element.classList.remove('modal--open');
                element.setAttribute('aria-hidden', true);
                element.setAttribute('aria-expanded', false);
                modalCloseBtn.setAttribute('aria-expanded', false);
                modalTriggerBtn.setAttribute('aria-expanded', false);
                modalTriggerBtn.focus();
            });

            modalTriggerBtn.addEventListener('click', () => {
                skipContent.setAttribute('tabindex', '-1');
                html.classList.toggle('html--modal-open');
                if (header) header.classList.toggle('header--modal-open');
                if (main) main.classList.toggle('main-content--modal-open');
                if (footer) footer.classList.toggle('footer--modal-open');
                element.classList.toggle('modal--open');

                if (element.classList.contains('modal--open')) {
                    element.setAttribute('aria-hidden', false);
                    element.setAttribute('aria-expanded', true);
                    modalCloseBtn.setAttribute('aria-expanded', true);
                    modalTriggerBtn.setAttribute('aria-expanded', true);
                } else {
                    element.setAttribute('aria-hidden', true);
                    element.setAttribute('aria-expanded', false);
                    modalCloseBtn.setAttribute('aria-expanded', false);
                    modalTriggerBtn.setAttribute('aria-expanded', false);
                }
            });
        }

        // Keyboard Esc closes modal
        element.addEventListener('keyup', (e) => {
            const evt = e || window.event;
            if (evt.which === Keyboard.esc) {
                modalCloseBtn.click();
            }
        });
    }
}
