import React from 'react';
import Insight from '../../02-tools/insight/insight';

export default class FormStepIndicator extends React.Component {
	constructor(props) {
		super(props);
    }

    clickHandler = (e, step) => {
        e.preventDefault();
	    this.props.setCurrentStep(step);
        
        Insight.track('matomo', { data: location.pathname + 'steg-' + step });
    }

    render() {
		return (
            <nav className="form-step-indicator">
	            <ol className="form-step-indicator__list list--reset-style list--inline-block">
		            {this.props.Steps.map((step, index) => {
                        if (index + 1 < this.props.CurrentStep) {
	                        return (
                                <li className="form-step-indicator__list-item" key={`step-${index}`}>
                                    <button className="form-step-indicator__btn" type="button" onClick={(e) => this.clickHandler(e, index + 1)}>
                                        <span className="form-step-indicator__step-title">{step.title}</span>
                                    </button>
		                        </li>
	                        )
                        } else {
                            return (
                                <li className="form-step-indicator__list-item" key={`step-${index}`}>
                                    <button className={`form-step-indicator__btn ${index + 1 == this.props.CurrentStep ? 'form-step-indicator__btn--current' : 'form-step-indicator__btn--disabled'}`} tabIndex="-1">
			                            <span className="form-step-indicator__step-title">{step.title}</span>
			                        </button>
			                    </li>
                            )
		                }
                    })}
	            </ol>
            </nav>
		);
	}
}
