import React from 'react';
import Autocomplete from '../autocomplete/autocomplete.jsx';
import GeoLocation from '../../02-tools/geolocation/geolocation';
import OrgUnits from '../../02-tools/org-units/org-units';
import ErrorHandler from '../../02-tools/error-handler/error-handler';
import Html from '../../02-tools/html/html';
import Insight from '../../02-tools/insight/insight';
import idHelper from '../../02-tools/id-helper/idHelper.js';

export default class FindGeneralOfficeService extends React.Component {
  constructor(props) {
    super(props);

    this.noHitServices = [];

    this.state = {
      SearchTitle: null,
      Loading: false,
      ResetAccordion: false,
      Results: [],
      Error: null,
      SearchText: '',
    };
  }

  componentDidUpdate() {
    if (!this.state.Loading) {
      const result = document.querySelector(".find-org-unit__results");
      result?.querySelector("a")?.focus();
    }
  }

  onGpsClick = (e) => {
    this.setState({
      Loading: true,
    });
    GeoLocation.getGeoLocation()
      .then((position) => {
        const params =
          'latitude=' +
          position.coords.latitude +
          '&longitude=' +
          position.coords.longitude +
          '&services=' +
          this.props.Services;
        this._runPromise(OrgUnits.findGeneralOfficeServiceFromAddress(params));
        Insight.track('ga', {
          isEvent: true,
          category: 'Finn',
          action: 'Finn - Søk',
          label: 'Finn - Søk - Din posisjon',
          value: 0,
        });
        Insight.track('matomo', {
          isEvent: true,
          category: 'Finn',
          action: 'Finn - Søk',
          name: 'Finn - Søk - Din posisjon',
          value: 0,
        });
      })
      .catch((error) => {
        this.setState({
          Loading: false,
          Results: [],
          Error: ErrorHandler.handle({
            data: error,
            globalText: this.props.Translations.Errors,
          }),
        });
      });
  };

  onSubmit = (value, id) => {
    this.setState({
      Loading: true,
      ResetAccordion: true,
    });

    if (id && id !== '') {
      this._runPromise(
        OrgUnits.findGeneralOfficeServiceFromId(
          id + '?services=' + this.props.Services
        )
      );
    } else {
      this._runPromise(
        OrgUnits.findGeneralOfficeServiceFromAddress(
          value + '?services=' + this.props.Services
        )
      );
    }

    Insight.track('ga', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Søk',
      label: 'Finn - Søk - ' + value,
      value: 0,
    });
    Insight.track('matomo', {
      isEvent: true,
      category: 'Finn',
      action: 'Finn - Søk',
      name: 'Finn - Søk - ' + value,
      value: 0,
    });
  };

  _runPromise = (promise) => {
    promise
      .then((data) => {
        let json = JSON.parse(data);
        this.noHitServices = this.props.Services.split(',');
        this.setState({
          Loading: false,
          SearchTitle: json.searchAddress,
          ResetAccordion: false,
          Results: json.services,
          Error: null,
        });
      })
      .catch((error) => {
        this.setState({
          Loading: false,
          ResetAccordion: false,
          Results: [],
          Error: ErrorHandler.handle({
            data: error,
            globalText: this.props.Translations.Errors,
          }),
        });
      });
  };

  getDistance = (distanceInMeters) => {
    const showAsMeters = distanceInMeters < 1000;
    let distance = showAsMeters
      ? Math.round(distanceInMeters) + ' m'
      : Math.round(distanceInMeters / 1000) + ' km';
    if (distanceInMeters === 0) {
      distance = '–';
    }
    return distance;
  };

  updateSearchText = (value) => {
    this.setState({
      SearchText: value,
    });
  };

  render() {
    const p = this.props;
    const services = [];

    this.state.Results.map((service) => {
      const offices = [];

      service.orgUnits.map((office, index) => {
        let municipalities = [];
        office.municipalities.map((municipality, index) => {
          municipalities.push('<b>' + municipality + '</b>');
        });

        const coverageText =
          municipalities.length > 1
            ? p.Translations.MunicipalityCoverage
            : p.Translations.MunicipalityCoverageSingle;
        const markup =
          coverageText +
          ' ' +
          municipalities
            .join(', ')
            .replace(/, ([^,]*)$/, ' ' + p.Translations.DelimiterWord + ' $1') +
          '.';

        offices.push(
          <li key={'office' + index} className='list-group__item'>
            {/*
            <span className='list-group__label list-group__item--label'>
              {this.getDistance(office.distance)}
            </span>
            */}
            <a
              href={`${office.url}#${encodeURI(service.service)}`}
              className='list-group__button'
              aria-label={`${this.getDistance(office.distance)} ${p.Translations.To} ${office.name}`}
            >
              {office.name}
            </a>
            <p
              className='find-org-unit__office-coverage'
              dangerouslySetInnerHTML={{ __html: markup }}
            ></p>
          </li>
        );
      });

      services.push({
        name: service.service,
        offices: offices,
      });
    });

    return (
      <div className='row stack-large-fixed layout--alignment'>
        <div className={p.GridClasses}>
          <h2 className={p.HeadingSize} id={idHelper.createId(p.Title)}>{p.Title}</h2>
          <section
            className='find-org-unit'
            data-gtm-component='find'
            data-gtm-category={this.props.GTM.Category}
            data-gtm-action={this.props.GTM.Action}
            data-testid='find-municipality-service'
          >
            {p.Summary ? (
              <p className='find-org-unit__summary'>{p.Summary}</p>
            ) : null}
            <Autocomplete
              Translations={p.Translations}
              SearchTitle={this.state.SearchTitle}
              onGpsClick={this.onGpsClick}
              onSubmit={this.onSubmit}
              updateSearchText={this.updateSearchText}
              Loading={this.state.Loading}
              Error={this.state.Error}
              HasPositionOption={false}
              Placeholder={p.Translations.PlaceholderText}
            />
            {services.length ? (
              <div className='find-org-unit__results'>
                {services.map((office, index) => {
                  return (
                    <div
                      key={'service' + index}
                      className='find-org-service__result'
                    >
                      <h3 className='list-group__legend' id={idHelper.createId(p.Translations.Place)}>
                        {p.Translations.Place}
                        {/*
                        <span className='list-group__label'>
                          {p.Translations.Distance}
                        </span>
                        */}
                      </h3>
                      <ol className='list-group list--reset-style'>
                        {office.offices}
                      </ol>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </section>
        </div>
      </div>
    );
  }
}
