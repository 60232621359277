import React from 'react';
import Guid from '../../../02-tools/guid/guid';

export default class FormInputHidden extends React.Component {
	constructor(props) {
        super(props);

        this.guid = Guid.create();
        this.sanitizedValue = props.Value.startsWith('#mfreg') ? '' : props.Value;
    }

    render() {
		return (
            <input
                id={this.props.Name}
                className={`form__textbox`}
                type={this.props.Type} name={this.props.Name}
                value={this.sanitizedValue}
                readOnly={true}
				maxLength="320" />
		);
	}
}
