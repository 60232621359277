import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@politiet/pds';
import Insight from '../../02-tools/insight/insight';

export default function Task({ Link, Title, Microtext, Gtm }) {
  const handleClick = () => {
    Gtm && Gtm.Category && Gtm.Label && Insight.track('matomo', {
      isEvent: true,
      category: Gtm.Category,
      action: Gtm.Label,
      name: location.pathname,
      value: 0,
    });
  }

  return (
    <Card href={Link} header={Title} onClick={handleClick}>
      {Microtext}
    </Card>
  );
}

Task.propTypes = {
  Link: PropTypes.string.isRequired,
  Title: PropTypes.string.isRequired,
  Microtext: PropTypes.string.isRequired,
  Gtm: PropTypes.shape({
    Category: PropTypes.string,
    Label: PropTypes.string
  })
};
