import React from 'react';
import Notification from '../notification/notification.jsx';
import XHR from '../../02-tools/xhr/xhr';
import Moment from 'moment';
import idHelper from '../../02-tools/id-helper/idHelper.js';

export default class IdStatusNotorieytService extends React.Component {
    constructor(props) {
        super(props);

	    this.state = {
            isHidden: true,
            loading: false,
            timerIsDone: true,
            documentNumber: '',
            originalTimestamp: '',
            referenceNumber: '',
            notificationHidden: true,
            notificationType: null,
            notificationHeading: null,
            notificationMsg: null,
            notificationModifier: '',
            refIsEmpty: false,
            docIsEmpty: false,
            apiError: false
	    }
    }

    showForm = (e) => {
        e.preventDefault();
	    this.setState({
		    isHidden: false
	    });
    }

	formatMsg = (msg, data, translations) => {
		return `${msg.replace('#docnum#', data.documentNumber)}`;
	}

	doCountdown = (timeout) => {
		setTimeout(() => {
			this.setState({
				timerIsDone: true
			});
		}, timeout);
	}

	doStatusVerification = (docNum, refNum) => {
		let CONSTANTS = {
			type: {
				MATCH: 'warning',
				NO_MATCH: 'regular',
				UNKNOWN_DOCUMENT: 'warning'
			},
			heading: {
				MATCH: this.props.IdNegativeHeading,
				NO_MATCH: this.props.IdPositiveHeading,
				UNKNOWN_DOCUMENT: this.props.IdUnknownHeading,
			},
			msg: {
				MATCH: this.props.IdNegative,
				NO_MATCH: this.props.IdPositive,
				UNKNOWN_DOCUMENT: this.props.IdUnknown
			}
		}

        XHR.post('/api/idstatus/verify', '{"receipt": "' + refNum.replace(/\r?\n|\r/g, '').replace(/\s/g, '') + '", "documentNumber": "' + docNum.toUpperCase() + '"}', 'application/json').then((data) => {
			const json = JSON.parse(data);
			this.setState({
				documentNumber: json.documentNumber,
				originalTimestamp: Moment(json.originalTimestamp).format('DD.MM.YY [kl.] HH:mm'),
                referenceNumber: json.referenceNumber,
                notificationHidden: false,
				notificationType: CONSTANTS.type[json.status],
				notificationHeading: CONSTANTS.heading[json.status],
				notificationMsg: this.formatMsg(CONSTANTS.msg[json.status], json, this.props.Translations),
				loading: false
			});
		}).catch((error) => {
			this.setState({
				notificationHidden: false,
				loading: false,
                apiError: true,
				notificationType: 'error',
				notificationHeading: this.props.Translations.apiErrorHeading,
                notificationMsg: this.props.Translations.apiErrorMsg,
				notificationModifier: 'id-status-service__notification--unknown-combo'
			});
			console.log(error);
		});
	}

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            notificationHidden: true,
            notificationModifier: '',
            refIsEmpty: false,
            docIsEmpty: false,
            isEmpty: false,
            apiError: false
	    });
        let refNum = this.refs.refNumber.value;
        let docNum = this.refs.docNumber.value;

        if (refNum === '' || docNum === '') {
            this.setState({
                refIsEmpty: refNum === '',
                docIsEmpty: docNum === '',
                notificationHidden: true,
            });
        } else {
	        this.setState({
		        loading: true,
		        timerIsDone: false
	        });
	        this.doCountdown(750);
            this.doStatusVerification(docNum, refNum);
        }
    }

    render() {
        return (
            <aside className="id-status-service__notoriety-service">
	            <h2 className="h4" id={idHelper.createId(this.props.IdStatusServiceNotorietyHeading)}>{this.props.IdStatusServiceNotorietyHeading}</h2>
	            <div className="id-status-service__reference-intro" dangerouslySetInnerHTML={{ __html: this.props.IdStatusServiceNotorietyText }}></div>
	            {this.state.isHidden ?
                    <a href="#id-status-service-notoriety-form" className="id-status-service__notoriety-toggler" role="button" onClick={(e) => this.showForm(e)}>{this.props.Translations.notorietyBtn}</a>
	            : null}
	            {!this.state.isHidden ?
                <form id="id-status-service-notoriety-form" className="id-status-service__form--notoriety" autoComplete="off">
					<label htmlFor="docNumber" className="form__label">
                        <span className="form__label-text">{this.props.Translations.docIdLabel}</span>
	                    {this.state.docIsEmpty ?
							<span className="form-section__message form-section__message--error form-section__message--active">{this.props.Translations.docError}</span>
                        : null}
                        <input id="docNumber" ref="docNumber" className={`form__textbox ${this.state.docIsEmpty ? 'form__input-error ' : ''}id-status-service__input--notoriety`} type="text" placeholder={this.props.Translations.docIdPlaceholder} />
                    </label>
                    <label htmlFor="refNumber" className="form__label">
                        <span className="form__label-text">{this.props.Translations.refNumberLabel}</span>
                        {this.state.refIsEmpty ?
                            <span className="form-section__message form-section__message--error form-section__message--active">{this.props.Translations.refError}</span>
                        : null}
						<input id="refNumber" ref="refNumber" className={`form__textbox ${this.state.refIsEmpty ? 'form__input-error ' : ''}id-status-service__input--notoriety`} type="text" placeholder={this.props.Translations.refNumPlaceholder} />
					</label>

                    <button type="submit" className={`btn btn--subtle id-status-service__submit id-status-service__submit--notoriety${this.state.loading || !this.state.timerIsDone ? ' id-status-service__submit--loading' : ''}`} onClick={(e) => this.onSubmit(e)} disabled={this.state.loading || !this.state.timerIsDone ? true : false}>
                        <span className="id-status-service__submit-text">{this.props.Translations.submitBtnText}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 37 38" className="id-status-service__submit-loader">
                            <defs>
                                <path id="a" d="M9.744 2.046V.223H.482v1.823h9.262z" />
                                <path id="c" d="M2.782.77H.955v9.25h1.827V.77z" />
                            </defs>
                            <g fill="none" fillRule="evenodd">
                                <g transform="translate(27.038 17.73)">
                                    <mask id="b" fill="#fff">
                                        <use xlinkHref="#a" />
                                    </mask>
                                    <path fill="#E6E9F2" d="M1.394.223a.912.912 0 1 0 0 1.823h7.438a.913.913 0 0 0 .914-.912c0-.503-.41-.91-.914-.91H1.394z" mask="url(#b)" />
                                </g>
                                <path fill="#A1A8B5" d="M.913 17.954a.912.912 0 1 0 0 1.823H8.35a.913.913 0 0 0 .913-.912c0-.503-.41-.91-.913-.91H.913z" />
                                <path fill="#EAEDF4" d="M27.543 23.09a.912.912 0 1 0-.912 1.58l6.442 3.711a.912.912 0 1 0 .911-1.578l-6.44-3.714z" />
                                <path fill="#B0B6C2" d="M3.711 9.35a.91.91 0 1 0-.913 1.578L9.24 14.64a.912.912 0 1 0 .914-1.578L3.71 9.35z" />
                                <path fill="#F1F2F8" d="M24.203 27.093a.913.913 0 0 0-1.582.911l3.72 6.433a.914.914 0 0 0 1.581-.911l-3.719-6.433z" />
                                <path fill="#BFC4CE" d="M10.443 3.294a.912.912 0 1 0-1.582.912l3.72 6.432a.912.912 0 0 0 1.582-.912l-3.72-6.432z" />
                                <path fill="#F6F8FC" d="M19.305 28.892a.913.913 0 0 0-1.827 0v7.428a.913.913 0 0 0 1.827 0v-7.428z" />
                                <g transform="translate(16.524 -.269)">
                                    <mask id="d" fill="#fff">
                                        <use xlinkHref="#c" />
                                    </mask>
                                    <path fill="#C8CDD9" d="M2.782 1.68a.912.912 0 0 0-1.827 0v7.427a.913.913 0 0 0 1.827 0V1.681z" mask="url(#d)" />
                                </g>
                                <path fill="#FFF" d="M14.162 28.005a.913.913 0 0 0-1.582-.91l-3.718 6.431a.91.91 0 0 0 .334 1.245.915.915 0 0 0 1.247-.334l3.719-6.432z" />
                                <path fill="#D1D6E1" d="M27.922 4.206a.912.912 0 1 0-1.582-.912l-3.718 6.432a.913.913 0 0 0 1.58.912l3.72-6.432z" />
                                <path fill="#959DAE" d="M10.153 24.668a.911.911 0 1 0-.914-1.579L2.8 26.803a.912.912 0 0 0 .913 1.58l6.44-3.715z" />
                                <path fill="#DCDFE8" d="M33.984 10.928a.912.912 0 0 0-.912-1.578l-6.442 3.712a.911.911 0 1 0 .913 1.58l6.441-3.714z" />
                            </g>
                        </svg>
                    </button>
                </form>
                    : null}
	            {!this.state.notificationHidden && !this.state.apiError ?
                    <h3 className="h4 id-status-service__notoriety-date-stamp" id={idHelper.createId(this.props.Translations.notorietyDateMsg)}>{this.props.Translations.notorietyDateMsg} {this.state.originalTimestamp}</h3>
	            : null}
	            <Notification
                    Type={this.state.notificationType}
                    Heading={this.state.notificationHeading}
                    Hidden={this.state.notificationHidden}
                    Modifier={this.state.notificationModifier} />
            </aside>
		);
    }
}
