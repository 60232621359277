import React from 'react';
import idHelper from '../../02-tools/id-helper/idHelper';

/*type Props = {
    HasDistrictAsTitle: Boolean;
    HasFunctionAsTitle: Boolean;
    HasFunctionAsSubTitle: Boolean;
    HasDistrictAsStructuredData: Boolean;
    LinkUrl: String;
    ResponsibleUnit: String;
    Function: String;
    Responsibility: String;
    Email: String;
    Phone: String;
    PhoneDisplay: String;
    Mobile: String;
    MobileDisplay: String;
    Availability: String;
    NoSMS: Boolean;
    Translations: Array<Object>;
};*/

export default class RoleCard extends React.Component {
    constructor(props) {
        super(props);
    }

    formatTitle = (data) => {
        let title = '';
        let titlePrefix = (data.HasDistrictAsTitle && data.ResponsibleUnit) ? `${data.ResponsibleUnit} – ` : '';

        if (data.HasFunctionAsTitle) {
            title = `${data.Function} ${data.Responsibility ? data.Responsibility.toLowerCase() : ''}`;
        } else {
            title = data.Responsibility ? data.Responsibility : data.Function;
        }

        return titlePrefix + title.trim();
    }

    render() {
        const p = this.props;
        const title = this.formatTitle(p);
        const emailKey = p.Email ? <dt className="structured-info__key">{p.Translations.Email}</dt> : null;
        const emailValue = p.Email ? <dd className="structured-info__value"><a href={`mailto:${p.Email}`}>{p.Email}</a></dd> : null;
        const phoneKey = p.Phone ? <dt className="structured-info__key">{p.Translations.Phone}</dt> : null;
        const phoneValue = p.Phone ? <dd className="structured-info__value"><a href={`tel:${p.Phone}`}>{p.PhoneDisplay}</a></dd> : null;
        const mobileKey = p.Mobile ? <dt className="structured-info__key">{p.Translations.Mobile}</dt> : null;
        const mobileValue = p.Mobile ? <dd className="structured-info__value"><a href={`tel:${p.Mobile}`}>{p.MobileDisplay}</a> {p.NoSMS ? `(${p.Translations.NoSMS})` : null}</dd> : null;
        const availabilityKey = p.Availability ? <dt className="structured-info__key">{p.Translations.Availability}</dt> : null;
        const availabilityValue = p.Availability ? <dd className="structured-info__value">{p.Availability}</dd> : null;
        const districtKey = p.HasDistrictAsStructuredData ? <dt className="structured-info__key">{p.Translations.District}</dt> : null;
        const districtValue = p.HasDistrictAsStructuredData ? <dd className="structured-info__value">{p.ResponsibleUnit}</dd> : null;

        return (
            <article className="role--card background--white-2">
	            <div className="role__info--card">
		            <h2 className={`role__title`} id={idHelper.createId(title)}>
			            <a className="role__title-link" href={p.LinkUrl}>{title}</a>
		            </h2>
		            {(p.HasFunctionAsSubTitle && p.Function) ? <h2 className="role__contact-point-resposibility">{p.Function}</h2> : null}
		            {(emailKey || phoneKey || mobileKey || availabilityKey) ?
                        <dl className="role__structured-info structured-info">
                            {districtKey}{districtValue}
                            {emailKey}{emailValue}
                            {phoneKey}{phoneValue}
                            {mobileKey}{mobileValue}
                            {availabilityKey}{availabilityValue}
                        </dl>
                        : null}
	            </div>
            </article>
        );
    }
}
