export default class ErrorHandler {
    static handle(error) {
        let errorMessage = '';

        if (error.data instanceof Object) {
            if (error.data.toString() === '[object PositionError]' || error.data.code) {
                switch (error.data.code) {
                case error.data.PERMISSION_DENIED:
                    errorMessage = error.globalText ? error.globalText.deniedGeolocation : error.data.message;
                    break;
                case error.data.POSITION_UNAVAILABLE:
                    errorMessage = error.globalText
                        ? error.globalText.locationUnavailable
                        : 'Location information is unavailable.';
                    break;
                case error.data.TIMEOUT:
                    errorMessage = error.globalText
                        ? error.globalText.timeout
                        : 'The request to get user location timed out.';
                    break;
                case error.data.UNKNOWN_ERROR:
                    errorMessage = error.globalText ? error.globalText.unknown : 'An unknown error occurred.';
                    break;
                default:
                    errorMessage = error.globalText ? error.globalText.unknown : 'An unknown error occurred.';
                }
            } else {
                switch (error.data.status) {
                case 400:
                    errorMessage = error.globalText ? error.globalText.badRequest : error.data.statusText;
                    break;
                case 404:
                    errorMessage = error.globalText ? error.globalText.notFound : error.data.statusText;
                    break;
                case 406:
                    errorMessage = error.globalText ? error.globalText.outsideNorway : error.data.statusText;
                    break;
                case 417:
                    errorMessage = error.globalText ? error.globalText.outsideNorway : error.data.statusText;
                    break;
                case 500:
                    errorMessage = error.globalText ? error.globalText.serverError : error.data.statusText;
                    break;
                case 204:
                    errorMessage = error.globalText ? error.globalText.noService : error.data.statusText;
                    break;
                default:
                    errorMessage = error.globalText ? error.globalText.unknown : 'An unknown error occurred.';
                }
            }
        } else {
            errorMessage = error.data;
        }
        console.log(errorMessage);

        return errorMessage;
    }
}
