import React from "react";

/*type Props = {
  Translations: Array<Object>;
  OpeningHours: Array<Object>;
  HasAppointments: Boolean;
  HasPhoneHours: Boolean;
  HasDropIn: Boolean;
  HasPassport: Boolean;
  HideTitles: Boolean;
};*/

export default class OpeningHoursTableBody extends React.Component {
    constructor(props) {
        super(props);
    }

    createCell = (
        targetArray,
        cellDataArray,
        index,
        cellClass,
        label,
        cellData,
    ) => {
        targetArray.push(
            <td
                className={cellClass}
                data-category-label={label}
                key={"cell" + index}
            >
                <span className="opening-hours__time">
                    {this.createCellData(cellData, cellDataArray, index)}
                </span>
            </td>,
        );
    };

    createCellData = (item, items, index) => {
        let elements = [];

        if (item) {
            const isOpen24Hours = item === "00:00-00:00" ? true : false;
            const hasClosingHour = item.split("-").length > 1 ? true : false;
            if (isOpen24Hours) {
                elements.push(
                    <span
                        className="opening-hours__time-span"
                        key={"data" + index}
                    >
                        {this.props.Translations.Open24Hours}
                    </span>,
                );
            } else if (!hasClosingHour) {
                elements.push(
                    <span
                        className="opening-hours__time-span"
                        key={"data" + index}
                    >
                        {item}–*
                    </span>,
                );
            } else {
                item.split(",").map((item, index) => {
                    elements.push(
                        <span
                            className="opening-hours__time-span"
                            key={"data" + index}
                        >
                            {item.split("-").join("–").trim()}
                        </span>,
                    );
                });
            }
        } else {
            elements.push(
                <span className="opening-hours__time-span" key={"data" + index}>
                    {this.props.Translations.Closed}
                </span>,
            );
        }

        items.push(elements);
        return items;
    };

    getDayName = (day) => {
        const dayNames = [
            this.props.Translations.Monday,
            this.props.Translations.Tuesday,
            this.props.Translations.Wednesday,
            this.props.Translations.Thursday,
            this.props.Translations.Friday,
            this.props.Translations.Saturday,
            this.props.Translations.Sunday,
        ];

        return dayNames[day - 1] || "";
    };

    render() {
        const p = this.props;

        let rows = [];

        p.OpeningHours.map((item, index) => {
            if (
                (item.dayOfWeek === 6 || item.dayOfWeek === 7) &&
                !item.primary &&
                !item.appointment &&
                !item.phone &&
                !item.dropin &&
                !item.passport
            ) {
                return null;
            }

            let appointments = [];
            let phoneHours = [];
            let dropins = [];
            let passports = [];
            let primaries = [];
            let appointmentCell = [];
            let phoneHoursCell = [];
            let dropInCell = [];
            let passportCell = [];
            let primaryCell = [];
            const day = this.getDayName(item.dayOfWeek);
            const cellClass = p.HideTitles
                ? "opening-hours__table-td opening-hours__table-td--no-titles"
                : "opening-hours__table-td";

            if (p.HasPrimary) {
                this.createCell(
                    primaryCell,
                    primaries,
                    index,
                    cellClass,
                    p.Translations.Primary,
                    item.primary,
                );
            }

            if (p.HasAppointments) {
                this.createCell(
                    appointmentCell,
                    appointments,
                    index,
                    cellClass,
                    p.Translations.Appointment,
                    item.appointment,
                );
            }

            if (p.HasPhoneHours) {
                this.createCell(
                    phoneHoursCell,
                    phoneHours,
                    index,
                    cellClass,
                    p.Translations.PhoneHours,
                    item.phone,
                );
            }

            if (p.HasDropIn) {
                this.createCell(
                    dropInCell,
                    dropins,
                    index,
                    cellClass,
                    p.Translations.DropIn,
                    item.dropin,
                );
            }

            if (p.HasPassport) {
                this.createCell(
                    passportCell,
                    passports,
                    index,
                    cellClass,
                    p.Translations.Passport,
                    item.passport,
                );
            }

            rows.push(
                <tr className="opening-hours__table-tr" key={index}>
                    <th
                        className="opening-hours__table-th"
                        scope="col"
                        data-day-label={day}
                    >
                        <span className="show-for-sr">{day}</span>
                        <span
                            className="opening-hours__day-label"
                            role="presentation"
                        >
                            {day?.substring(0, 2)}
                        </span>
                    </th>
                    {primaryCell}
                    {appointmentCell}
                    {phoneHoursCell}
                    {dropInCell}
                    {passportCell}
                </tr>,
            );
        });

        return <tbody className="opening-hours__table-tbody">{rows}</tbody>;
    }
}
