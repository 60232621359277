import React from 'react';
import Guid from '../../../02-tools/guid/guid';
import FormHint from '../form-hint.jsx';
import FormMoreInfo from '../form-more-info.jsx';
import FieldFormError from '../form-error.jsx';

export default class FormInputText extends React.Component {
	constructor(props) {
        super(props);
        this.guid = Guid.create();

        let sanitizedValue = '';

        if (props.Value) {
	        sanitizedValue = props.Value.startsWith('#mfreg') ? '' : props.Value;
        }

        this.state = {
	        showInfo: false,
            value: props.UserInputValue ? props.UserInputValue : sanitizedValue
        }
    }

    onChange = (e) => {
        this.setState({
            value: e.target.value
        }, (e) => {
            if (this.props.HasError) this.props.ResetError(true);
            this.props.OnChange(this.props.Name, this.state.value);
        });
    }
   
    toggleInfo = (e) => {
        e.preventDefault();
	    this.setState(prevState => ({
		    showInfo: !prevState.showInfo
	    }));
    }

    render() {
        const errorClass = "form-section__message form-section__message--error form-section__message--active";
		
        return (
            <fieldset className={`form-section__fieldset${this.props.ReadOnly ? ' form-section__fieldset--readonly' : ''}${this.props.HideLabel ? ' form-section__fieldset--hidden-label' : ''}`}>
                <label className={`form__label${this.props.HideLabel ? ' form__label--hidden' : ''}`} htmlFor={this.props.Name}><span className="form__label-text">{this.props.Required ? this.props.Label : this.props.Label + ' ' + this.props.Translations.Optional}</span></label>
                {this.props.Hint ? 
                    <FormHint Hint={this.props.Hint} />
					: null}
                {this.props.InfoTrigger && this.props.Info ?
                    <FormMoreInfo Active={this.state.showInfo} Id={`more-info-${this.guid}`} Info={this.props.Info} BtnText={this.props.InfoTrigger} toggleInfo={this.toggleInfo} Translations={this.props.Translations} />
					: null}
                {this.props.HasError ?
                    <FieldFormError errorMessage={this.props.ErrorMessage} stylename={errorClass} />
                    : null}
                <span className={`form-section__input-container${this.props.ReadOnly ? ' form-section__input-container--readonly' : ''}`}>
                    <input
                        id={this.props.Name}
                        className={`form__textbox${this.props.HasError ? ' form__input-error' : ''}`}
                        type={this.props.Type} 
                        name={this.props.Name}
                        value={this.state.value}
                        placeholder={this.props.Placeholder}
                        required={this.props.Required}
                        readOnly={this.props.ReadOnly}
                        onChange={(e) => { this.onChange(e) }}
                        onBlur={(e) => { !this.props.ReadOnly ? this.props.ValidateField(e) : null }}
                        data-label={this.props.Label}
                        data-validation={this.props.Validation}
                        tabIndex={this.props.ReadOnly ? -1 : 0}
						maxLength="320" />
                </span>
            </fieldset>
		);
	}
}
