import Keyboard from '../../02-tools/keyboard/keyboard';
import SCROLLTO from '../../02-tools/scroll-to/scroll-to';
import RWD from '../../02-tools/rwd/rwd';

export default class Process {
    constructor(element) {
        const headers = element.querySelectorAll('.process__step-header');
        const list = element.querySelectorAll('.process__step-list');
        const defaultHideClass = element.querySelector('.process__list--hide-default');

        const headerExpandedClass = 'process__step-header--expanded';
        const listExpandedClass = 'process__step-list--expanded';
        const regionExpandedClass = 'process__step-content--expanded';
        const itemExpandedClass = 'process__step-list-item--expanded';

        const buttonClass = '.process__step-button';
        const toggleIconClass = '.process__toggle-icon';

        let isDesktop = (RWD.resize().large || RWD.resize().xlarge);

        function setStates(header, button, region, toggleIcon, listItem) {
            if (isDesktop && !defaultHideClass) {
                header.classList.add(headerExpandedClass);
                region.classList.add(regionExpandedClass);
                list[0].classList.add(listExpandedClass);
                listItem.classList.add(itemExpandedClass);
                toggleIcon.style.display = 'none';
                button.setAttribute('aria-expanded', true);
                region.setAttribute('aria-hidden', false);
            } else {
                if (defaultHideClass) return;
                header.classList.remove(headerExpandedClass);
                region.classList.remove(regionExpandedClass);
                list[0].classList.remove(listExpandedClass);
                listItem.classList.remove(itemExpandedClass);
                toggleIcon.style.display = 'block';
                button.setAttribute('aria-expanded', false);
                region.setAttribute('aria-hidden', true);
            }
        }

        function isStateChanged(stored) {
            const rwd = RWD.resize();
            const current = (rwd.large || rwd.xlarge);

            if (stored !== current) {
                isDesktop = (rwd.large || rwd.xlarge);
                return true;
            }

            return false;
        }

        if (headers) {
            window.addEventListener('resize', () => {
                if (isStateChanged(isDesktop)) {
                    for (const header of headers) {
                        const button = header.querySelector(buttonClass);
                        const region = header.nextElementSibling;
                        const toggleIcon = header.querySelector(toggleIconClass);
                        const listItem = header.parentNode;

                        setStates(header, button, region, toggleIcon, listItem);
                    }
                }
            });

            for (const header of headers) {
                const button = header.querySelector(buttonClass);
                const region = header.nextElementSibling;

                button.addEventListener('click', (e) => {
                    e.preventDefault();

                    if (!isDesktop || defaultHideClass) {
                        header.classList.toggle(headerExpandedClass);
                        region.classList.toggle(regionExpandedClass);
                    } else {
                        SCROLLTO.y(header.offsetParent.offsetTop - 8, 500, 'easeInOutQuint');
                    }

                    if (header.classList.contains(headerExpandedClass)) {
                        button.setAttribute('aria-expanded', true);
                        region.setAttribute('aria-hidden', false);
                    } else {
                        button.setAttribute('aria-expanded', false);
                        region.setAttribute('aria-hidden', true);
                    }
                });

                button.addEventListener('keyup', (e) => {
                    const evt = e || window.event;
                    if (evt.which === Keyboard.right) {
                        button.click();
                    }
                });
            }
        }
    }
}