import React from "react";
import EventSonar from "./event-sonar.jsx";
import EventActionEntries from "./event-action-entries.jsx";
import idHelper from "../../02-tools/id-helper/idHelper.js";

/*type Props = {
  Events: Array<Object>;
  Event: Array<Object>;
  RootClass: String;
  Translations: Array<Object>;
};*/

export default class EventMessageHigh extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const p = this.props;
        let isSingleEvent = p.Events.length > 1 ? false : true;
        let isSingleEventAndMultipleIncident =
            isSingleEvent && p.Events[0].incidents.length > 1 ? true : false;

        if (isSingleEvent) {
            return isSingleEventAndMultipleIncident ? (
                <EventMessageSingleEventMultiIncident
                    Event={p.Events[0]}
                    Translations={p.Translations}
                    RootClass={p.RootClass}
                />
            ) : (
                <EventMessageSingleEventSingleIncident
                    Event={p.Events[0]}
                    Translations={p.Translations}
                    RootClass={p.RootClass}
                />
            );
        } else {
            return (
                <EventMessageMultiEvents
                    Events={p.Events}
                    Translations={p.Translations}
                    RootClass={p.RootClass}
                />
            );
        }
    }
}

class EventMessageSingleEventSingleIncident extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const p = this.props;
        const incident = p.Event.incidents[0];

        if (!incident) {
            return null;
        }

        return (
            <section className={p.RootClass}>
                <div className="row event__content-row">
                    <EventSonar
                        Translations={p.Translations}
                        Hidden={false}
                        IsHigh={true}
                    />
                    <div className="column event__content-column">
                        <div className="row">
                            <div className="column event__content-column">
                                <div className="event__content">
                                    <a
                                        className="event__link link--no-underline"
                                        href={incident.url}
                                    >
                                        <h2
                                            className="event__title event__title--incident"
                                            id={idHelper.createId(
                                                incident.incidentTitle
                                            )}
                                        >
                                            {incident.incidentTitle}
                                        </h2>
                                        <p className="event__summary">
                                            <span className="event__summary-text">
                                                {incident.mainIntro}
                                            </span>
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(incident.blocks && incident.blocks.length) ||
                !incident.hideNextOfKinPhone ? (
                    <EventActionEntries
                        Entries={incident.blocks}
                        NextOfKin={
                            incident.hideNextOfKinPhone
                                ? null
                                : incident.nextOfKinPhone
                        }
                        Translations={p.Translations}
                    />
                ) : null}
            </section>
        );
    }
}

class EventMessageSingleEventMultiIncident extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const p = this.props;
        let incidents = [];

        p.Event.incidents.map((incident, index) => {
            incidents.push(
                <div className="column xsmall-12 medium-6 large-4" key={index}>
                    <a
                        className="event__link event__link--expand event__link--flipped link--no-underline"
                        href={incident.url}
                    >
                        <h2
                            className="event__sub-title"
                            id={idHelper.createId(incident.incidentTitle)}
                        >
                            {incident.incidentTitle}
                        </h2>
                        <p className="event__summary">
                            <span className="event__summary-text">
                                {incident.mainIntro}
                            </span>
                        </p>
                    </a>
                </div>
            );
        });

        return incidents ? (
            <section className={p.RootClass}>
                <div className="row event__content-row">
                    <EventSonar
                        Translations={p.Translations}
                        Hidden={false}
                        IsHigh={true}
                    />
                    <div className="column event__content-column">
                        <div className="event__content">
                            <div className="row">
                                <div className="column xsmall-12">
                                    <h2
                                        className="event__title"
                                        id={idHelper.createId(
                                            p.Event.eventTitle
                                        )}
                                    >
                                        {p.Event.eventTitle}
                                    </h2>
                                </div>
                            </div>
                            <div className="row">{incidents}</div>
                        </div>
                    </div>
                </div>
            </section>
        ) : null;
    }
}

class EventMessageMultiEvents extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const p = this.props;
        let events = [];

        p.Events.map((event, index) => {
            const hasSingleIncident = event.incidents.length > 1 ? false : true;
            const isFirst = index === 0 ? true : false;

            events.push(
                <div className="row event__content-row" key={index}>
                    {isFirst ? (
                        <EventSonar
                            Translations={p.Translations}
                            Hidden={false}
                            IsHigh={false}
                        />
                    ) : null}
                    <div className="column event__content-column">
                        <div className="row">
                            <div className="column event__content-column">
                                {hasSingleIncident ? (
                                    <div className="event__content">
                                        <h2
                                            className="event__title"
                                            id={idHelper.createId(
                                                event.incidents[0].incidentTitle
                                            )}
                                        >
                                            {event.incidents[0].incidentTitle}
                                        </h2>
                                        <p className="event__summary">
                                            <span className="event__summary-text">
                                                {event.incidents[0].mainIntro}
                                            </span>
                                            <a
                                                className="event__read-more link--no-underline"
                                                href={event.incidents[0].url}
                                            >
                                                <span className="event__read-more-text">
                                                    {p.Translations.ReadMore}
                                                </span>
                                                <span
                                                    className="event__read-more-icon"
                                                    role="presentation"
                                                ></span>
                                            </a>
                                        </p>
                                    </div>
                                ) : (
                                    <div className="event__content">
                                        <div className="row">
                                            <div className="column xsmall-12">
                                                <h2
                                                    className="event__title"
                                                    id={idHelper.createId(
                                                        event.eventTitle
                                                    )}
                                                >
                                                    {event.eventTitle}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {event.incidents.map(
                                                (incident, index) => {
                                                    return (
                                                        <div
                                                            className="column xsmall-12 medium-6 large-4"
                                                            key={index}
                                                        >
                                                            <h3
                                                                className="event__sub-title"
                                                                id={idHelper.createId(
                                                                    incident.incidentTitle
                                                                )}
                                                            >
                                                                {
                                                                    incident.incidentTitle
                                                                }
                                                            </h3>
                                                            <p className="event__summary">
                                                                <span className="event__summary-text">
                                                                    {
                                                                        incident.mainIntro
                                                                    }
                                                                </span>
                                                                <a
                                                                    className="event__read-more link--no-underline"
                                                                    href={
                                                                        incident.url
                                                                    }
                                                                >
                                                                    <span className="event__read-more-text">
                                                                        {
                                                                            p
                                                                                .Translations
                                                                                .ReadMore
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        className="event__read-more-icon"
                                                                        role="presentation"
                                                                    ></span>
                                                                </a>
                                                            </p>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return <section className={p.RootClass}>{events}</section>;
    }
}
