import React from 'react';
import Form from './form.jsx';
import FormStepIndicator from '../../05-blocks/form-step-indicator/form-step-indicator.jsx';
import FormReceipt from './form-receipt.jsx';
import FormCancelModal from './form-cancel-modal.jsx';
import Notification from '../../05-blocks/notification/notification.jsx';
import SCROLLTO from '../../02-tools/scroll-to/scroll-to';
import idHelper from '../../02-tools/id-helper/idHelper.js';


export default class FormPage extends React.Component {
    constructor(props) {
        super(props);

        this.formData = JSON.parse(props.FormData);

	    this.state = {
		    currentStep: 1,
            totalSteps: this.formData.form.steps.length,
            showNavigation: true,
            showReceipt: false,
            receiptData: null,
            inputData: null,
            showSystemError: false
	    }
    }

    toggleNavigation = (form) => {
        let deadends = form.querySelectorAll('[data-deadend="true"]:checked');

	    this.setState({
            showNavigation: deadends.length === 0 ? true : false
	    });
    }

    setSystemError = (newState) => {
        this.setState({
            showSystemError: newState
        });
    }

    setCurrentStep = (step) => {
        this.setState({
            currentStep: step,
            showSystemError: false
        }, () => {
            SCROLLTO.y(0, 0, 'easeInOutQuint');
        });
    }

    showReceipt = (data, input) => {
        this.setState({
            receiptData: data,
            showReceipt: true,
            inputData: input
        }, () => {
	        SCROLLTO.y(0, 0, 'easeInOutQuint');
        });
    }

    componentDidMount() {
        this.formData.form.steps.map((step) => {
            if(step.deadEnd){
                this.setState({
                    showNavigation : false
                })
            }
        })
        ReactDOM.render(<FormCancelModal Title={this.props.CancelModal.Title} BodyText={this.props.CancelModal.BodyText} CancelUrl={this.formData.form.cancelUrl} Translations={this.props.Translations} />, document.getElementById('modal-2'));
    }

    render() {
        if (this.state.showReceipt) {
            return (
                <FormReceipt Data={this.state.receiptData}
                    FormSteps={this.formData.form.steps}
                    FormDataInput={this.state.inputData}
                    TotalSteps={this.formData.form.steps.length}
                    ReceiptEditorial={this.props.Receipt}
                    Translations={this.props.Translations}
                />
	        )
        } else {
	        return (
		        <>
					{this.formData.form.steps.length > 1 ?
						<div className="row">
							<div className="column">
								<FormStepIndicator Steps={this.formData.form.steps} CurrentStep={this.state.currentStep} TotalSteps={this.formData.form.steps.length} setCurrentStep={this.setCurrentStep} />
							</div>
						</div> : null}
					<header className="page__header">
						<div className="row">
							<div className="xsmall-12 medium-8 xlarge-6 column">
								<h1 className="page__heading" id={idHelper.createId(this.props.PageTitle)}>{this.props.PageTitle}</h1>
							</div>
						</div>
                        {this.props.PageSummary ?
	                    <div className="row stack-large-fixed">
							<div className="xsmall-12 medium-8 xlarge-6 column">
								<p className="page__ingress">{this.props.PageSummary}</p>
							</div>
						</div>
	                    : null}
					</header>

					{this.formData.form.importantMessages.map((item, index) => (
						<div className="row stack-large-fixed layout--alignment" key={index}>
							<div className="xsmall-12 medium-8 xlarge-6 column">
								<Notification Message={item.message} Heading={item.heading} Type="alert" />
							</div>
						</div>
					))}

                    <Form FormData={this.formData}
                        CurrentStep={this.state.currentStep}
                        TotalSteps={this.formData.form.steps.length}
                        setCurrentStep={this.setCurrentStep}
                        toggleNavigation={this.toggleNavigation}
                        ShowNavigation={this.state.showNavigation}
                        showReceipt={this.showReceipt}
                        Translations={this.props.Translations}
                        ShowSystemError={this.state.showSystemError}
                        setSystemError={this.setSystemError}
                    />
				</>
	        )
        }
    }
}
