import React from 'react';
import FormSummary from './form-summary.jsx';
import idHelper from '../../02-tools/id-helper/idHelper.js';

export default class FormReceipt extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let applicantEmail = this.props.Data.email ? this.props.Data.email : '';
        let referenceid = this.props.Data.referenceid ? this.props.Data.referenceid : '';
        let districtName = this.props.Data.district?.name ? this.props.Data.district.name : '';
        let districtAddress = this.props.Data.district?.address ? this.props.Data.district.address : '';
        let districtPhone = this.props.Data.district?.phone ? this.props.Data.district.phone : '';
	    let districtEmail = this.props.Data.district?.email ? this.props.Data.district.email : '';

        let placeholderReplacedText = this.props.ReceiptEditorial.BodyText
            .replaceAll('##applicantemail##', applicantEmail)
            .replaceAll('##referenceid##', referenceid)
            .replaceAll('##districtname##', districtName)
            .replaceAll('##districtaddress##', districtAddress)
            .replaceAll('##districtphone##', districtPhone)
            .replaceAll('##districtemail##', districtEmail)
        return (
            <>
				<header className="page__header">
					<div className="row">
						<div className="xsmall-12 medium-8 xlarge-6 column">
                            <h1 className="page__heading" id={idHelper.createId(this.props.ReceiptEditorial.Title)}>{this.props.ReceiptEditorial.Title}</h1>
						</div>
					</div>
                </header>

                <div className="row stack-large-fixed">
                    <div className="xsmall-12 medium-8 xlarge-6 column">
                        <div className="rich-text" dangerouslySetInnerHTML={{ __html: placeholderReplacedText }}></div>
                    </div>
                </div>
                {this.props.ReceiptEditorial.ProcessingTimeBtnText && this.props.ReceiptEditorial.ProcessingTimeBtnUrl ?
                <div className="row stack-large-fixed">
                    <div className="xsmall-12 medium-8 xlarge-6 column">
                        <a className="call-to-action call-to-action--button link--no-underline" href={this.props.ReceiptEditorial.ProcessingTimeBtnUrl}>{this.props.ReceiptEditorial.ProcessingTimeBtnText}<span className="call-to-action__icon" role="presentation"></span></a>
                    </div>
                </div> : null}
				<div className="row stack-large-fixed">
					<div className="xsmall-12 medium-8 xlarge-6 column">
                        <h2 id={idHelper.createId(this.props.ReceiptEditorial.SummaryTitle)}>{this.props.ReceiptEditorial.SummaryTitle}</h2>
						<FormSummary
							FormDataInput={this.props.FormDataInput}
							FormSteps={this.props.FormSteps}
							ActiveStep={this.props.TotalSteps + 1} />
					</div>
				</div>
            </>
	    )
	}
}
