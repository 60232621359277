import React from "react";
import Moment from "moment";
import Keyboard from "../../02-tools/keyboard/keyboard";
import InactivityListener from "inactivity-listener";
import IdPortenModal from "./id-porten-modal-content.jsx";
import IdPortenBarContent from "./id-porten-bar-content.jsx";
import ContactPoints from "../contact-points/contact-points.jsx";
import Insight from "../../02-tools/insight/insight";

export default class IdPortenBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            persData: null,
            isLoaded: false,
        };
        this.serviceIdleLimit;
        this.timeUntilServiceIdleLimit;
        this.modalTimer;
        this.modal;
    }

    initModal = (modal = this.modal) => {
        if (modal) {
            ReactDOM.render(
                <IdPortenModal
                    Title={this.props.ModalTitle}
                    BodyText={this.props.ModalBodyText}
                    Translations={this.props.Translations}
                />,
                modal,
                () => {
                    let modalContent =
                        this.modal.querySelector(".modal-2__content");

                    // Open modal
                    this.openModal(modal);

                    // Add event listeners
                    modal.addEventListener("click", (e) => {
                        if (e.target.contains(modalContent)) {
                            this.closeModal(modal);
                        } else if (
                            e.target.contains(modal.querySelector(".btn")) ||
                            e.target.contains(
                                modal.querySelector(".btn--link__link-text"),
                            )
                        ) {
                            this.closeModal(modal);
                        }
                    });

                    document.addEventListener("keyup", (e) => {
                        const evt = e || window.event;
                        if (
                            evt.which === Keyboard.esc &&
                            modal.classList.contains("modal-2--open")
                        ) {
                            this.closeModal(modal);
                        }
                    });
                },
            );
        }
    };

    startIdleTimer = (millisecondsToTimeout, callback, useEvents) => {
        let events = ["invalid"];
        if (useEvents) {
            events = [
                "onmousemove",
                "onmousedown",
                "onclick",
                "onscroll",
                "scroll",
                "onkeydown",
                "ontouchstart",
                "wheel",
            ];
        }

        if (InactivityListener) {
            InactivityListener.destroy();
        }
        InactivityListener.start(millisecondsToTimeout, callback, events);
    };

    logout = () => {
        window.location.href = this.props.IdPortenLogoutUrl
            ? this.props.IdPortenLogoutUrl
            : window.location.origin;
    };

    openModal = (modal) => {
        let timeout = this.props.IdPortenModalLimit * 60 * 1000;
        this.modalTimer = setTimeout(this.logout, timeout);

        modal.classList.add("modal-2--open");
        modal.setAttribute("aria-hidden", false);
        modal.setAttribute("aria-expanded", true);
    };

    closeModal = (modal) => {
        clearTimeout(this.modalTimer);
        modal.classList.remove("modal-2--open");
        modal.setAttribute("aria-hidden", true);
        modal.setAttribute("aria-expanded", false);

        this.startIdleTimer(
            this.timeUntilServiceIdleLimit.asMilliseconds(),
            this.initModal,
            true,
        );
    };

    componentDidMount() {
        this.checkIfLoggedIn();
    }

    checkIfLoggedIn = () => {
        fetch(`${this.props.IdportenUrl}/userinfo`, {
            method: "get",
            mode: "cors",
            credentials: "include",
        })
            .then((response) => {
                return response
                    .clone()
                    .text()
                    .then((text) => {
                        if (text.startsWith("This session has been expired")) {
                            return this.checkIfLoggedIn();
                        } else {
                            return response.clone().json();
                        }
                    });
            })
            .then(({ isLoggedIn, name, pid }) => {
                this.setState({ isLoggedIn: isLoggedIn });
                this.setState({ isLoaded: true });

                if (isLoggedIn) {
                    this.setState({ persData: { name: name, pid: pid } });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                this.setState({ isLoaded: true });
            });
    };

    render() {
        let name;
        if (this.state.isLoggedIn) {
            if (this.state.persData && this.state.persData.name) {
                name = this.state.persData.name;
            } else {
                name = this.state.persData?.pid;
            }
        }

        if (this.state.isLoaded) {
            if (this.state.isLoggedIn) {
                Insight.track("matomo", {
                    isEvent: true,
                    category: "ID-Porten",
                    action: "Bruker er innlogget",
                    name: this.props.IdPortenLoggedIn
                        ? "Integrasjon via Epi"
                        : "Integrasjon via Innloggede Tjenester",
                    value: 0,
                });
                return (
                    <IdPortenBarContent
                        loggedInText={this.props.Translations.LoggedInAs}
                        userInfo={name}
                        onClick={this.logout}
                        loggOutText={this.props.Translations.BtnLogout}
                    />
                );
            } else {
                return (
                    <ContactPoints
                        phoneLabel={this.props.PhoneLabel}
                        phoneHref={this.props.PhoneHref}
                        phoneNumber={this.props.PhoneNumber}
                        emergencyLabel={this.props.EmergencyLabel}
                        emergencyHref={this.props.EmergencyHref}
                        emergencyNumber={this.props.EmergencyNumber}
                        findPoliceHref={this.props.FindPoliceHref}
                        findPoliceLabel={this.props.FindPoliceLabel}
                    />
                );
            }
        } else {
            return <div />;
        }
    }
}
