import React from 'react';
import MetaData from '../metadata/metadata.jsx';
import Guid from '../../02-tools/guid/guid';
import idHelper from '../../02-tools/id-helper/idHelper.js';

/*type Props = {
  Data: Array<Object>;
  Translations: Array<Object>;
};*/

export default class PageListing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.Data.showNumberOfItems
    }
  }

  showMore = (items, increment) => {
    this.setState({
      items: items + increment
    });
  }

  render() {
    const p = this.props;
    const incrementBy = p.Data.showNumberOfItems ? p.Data.showNumberOfItems : 5;
    const itemsToRender = p.Data.pages ? p.Data.pages.slice(0, this.state.items) : [];
    const listId = 'page-listing-' + Guid.create();

    return (
      p.Data.pages ?
      <ol className="page-listing page-listing--minimized-top list--reset-style-type" aria-live="polite" aria-relevant="additions" id={listId}>
          {itemsToRender.map((item, index) => (
            <li className="page-listing__item" key={index}>
              <article className="page-listing__article">
                <h2 className="h3 page-listing__title" id={idHelper.createId(item.title)}>
                  <a className="page-listing__link" href={item.url}>{item.title}</a>
                </h2>
                <MetaData RootClass="metadata metadata--subtle list--reset-style" PublishedDate={item.published} ChangedDate={item.changed} ContentType={item.contentType} ShowResponsibleUnits={true} ShowPublishedOrChangedDate={true} ShowDate={true} ResponsibleUnits={item.responsibleUnitReference} Translations={p.Translations} />
                <p className="page-listing__excerpt">{item.mainIntro}</p>
              </article>
            </li>
          ))}
          {this.state.items < p.Data.pages.length ?
            <li className="page-listing__item page-listing__item--wide">
            <button className="show-more" onClick={this.showMore.bind(this, this.state.items, incrementBy)} aria-controls={listId}>{p.Translations.ShowMore}</button>
            </li>
          : null}
        </ol>
      : null
    );
  }
}
