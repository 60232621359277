import React, { useState, useEffect } from "react";
import {
    getApiCode,
    isValidApiRoot,
    isValidLanguage,
    getUrlRoot,
} from "../../02-tools/utils/utils.js";
import Insight from "../../02-tools/insight/insight.js";

const Footer = ({ userlang, apiroot }) => {
    const [userLanguage, setUserLanguage] = useState(null);
    const [apiRoot, setApiRoot] = useState(null);
    const [environment, setEnvironment] = useState(null);

    const [footerData, setFooterData] = useState(null);

    useEffect(() => {
        setUserLanguage(userlang);
        setApiRoot(apiroot);
    }, [userlang, apiroot]);

    useEffect(() => {
        if (!apiRoot) return;
        if (!userLanguage) return;
        setEnvironment(isValidApiRoot(apiRoot) ? apiRoot : "production");

        if (!environment) return;
        fetchData();
    }, [userLanguage, apiRoot, environment]);

    const fetchData = () => {
        const fetchHeaders = new Headers({
            api_code: getApiCode(environment),
            api_id: "content-api-header-and-footer",
            "cache-control": "no-store",
        });

        const fetchConfig = {
            method: "GET",
            headers: fetchHeaders,
        };

        return fetch(
            `${getUrlRoot(environment)}/api/contentdata/footer?language=${
                isValidLanguage(userLanguage) ? userLanguage : "nb"
            }`,
            fetchConfig,
        )
            .then((response) => response.json())
            .then((data) => {
                setFooterData(data);
            })
            .catch((err) => console.error(err.message));
    };

    const renderFooterColumns = (data) => {
        if (data) {
            return data.map((column, index) => {
                return (
                    <ul
                        className="polno-footer__link-list list--reset-style-type"
                        key={index}
                    >
                        {column.map((link, index) => {
                            return (
                                <li
                                    className="polno-footer__link-list-item"
                                    key={index}
                                >
                                    <a
                                        onClick={footerLinkClickHandler}
                                        className="polno-footer__link"
                                        href={link.path}
                                    >
                                        <span className="polno-footer__link-underline">
                                            {link.title}
                                        </span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                );
            });
        }
    };

    const footerLinkClickHandler = (event) => {
        event.preventDefault();
        const linkText = event.target.textContent;
        const linkUrl = event.target.href
            ? event.target.href
            : event.target.closest("a").href;

        Insight.track("matomo", {
            isEvent: true,
            category: "Navigasjon",
            action: `Klikk link i footer - ${linkText}`,
            name: `${document.title} (${location.pathname})`,
            value: 0,
        });

        location.href = linkUrl;
    };

    return (
        <>
            <footer className="polno-footer skew--top">
                <div className="row">
                    <div className="xsmall-12 column">
                        <ul className="polno-footer__contact-points">
                            {footerData?.emergencyNumber ? (
                                <li className="polno-footer__contact-points-item">
                                    <a
                                        onClick={footerLinkClickHandler}
                                        className="polno-footer__contact-points-link link--no-underline"
                                        href={`tel:${footerData.emergencyNumber.phone}`}
                                        data-gtm-component="footer-emergency-phone"
                                    >
                                        <span className="polno-footer__contact-points-link-underline">
                                            {footerData.emergencyNumber.title}{" "}
                                            <strong>
                                                {
                                                    footerData.emergencyNumber
                                                        .phone
                                                }
                                            </strong>
                                        </span>
                                    </a>
                                </li>
                            ) : null}
                            {footerData?.phone ? (
                                <li className="polno-footer__contact-points-item">
                                    <a
                                        onClick={footerLinkClickHandler}
                                        className="polno-footer__contact-points-link link--no-underline"
                                        href={`tel:${footerData.phone.phone}`}
                                        data-gtm-component="footer-phone"
                                    >
                                        <span className="polno-footer__contact-points-link-underline">
                                            {footerData.phone.title}{" "}
                                            <strong>
                                                {footerData.phone.phone}
                                            </strong>
                                        </span>
                                    </a>
                                </li>
                            ) : null}
                            {footerData?.findPoliceLink ? (
                                <li className="polno-footer__contact-points-item">
                                    <a
                                        onClick={footerLinkClickHandler}
                                        className="polno-footer__contact-points-link link--no-underline"
                                        href={footerData.findPoliceLink.path}
                                        data-gtm-component="footer-find-police"
                                    >
                                        <span className="polno-footer__contact-points-link-underline">
                                            {footerData.findPoliceLink.title}
                                        </span>
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="xsmall-12 column">
                        <div className="polno-footer__link-lists">
                            {renderFooterColumns(footerData?.footerLinks)}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
