import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs as PdsBreadcrumbs } from '@politiet/pds';

export default function Breadcrumbs({ Paths, Negative, Standalone }) {
  return (
    <PdsBreadcrumbs
      breadcrumbs={JSON.parse(Paths)}
      className="list--reset-style"
      isNegative={Negative}
      isStandalone={Standalone}
    />
  );
}

Breadcrumbs.propTypes = {
  Negative: PropTypes.bool,
  Standalone: PropTypes.bool,
  Paths: PropTypes.string.isRequired
};
