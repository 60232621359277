import React from 'react';

export default class FormInputCheckboxOption extends React.Component {
	constructor(props) {
        super(props);

		this.state = {
            checked: props.UserInputValue ? 'true' : props.Checked
		}
    }

    onChange = (e) => {
		e.persist();
		
		this.setState(prevState => ({
			checked: !prevState.checked
        }), () => {
		    this.props.handleChange(e);
	    });
    }

    render() {
		return (
			<label className="form__label form-section__input-group-item" htmlFor={this.props.Name}>
                <input className="form__checkbox" id={this.props.Name} name={this.props.Name} type="checkbox" value={this.props.Value} checked={this.state.checked} onChange={(e) => this.onChange(e)} data-label={this.props.Label} data-validation="checkbox" />
				<span className="form__label-text form__label--offset">{this.props.Label}</span>
            </label>
	    );
	}
}