import React from 'react';
import idHelper from '../../02-tools/id-helper/idHelper';

export default class Accordion extends React.Component {
  gtmDataHook = 'accordion-btn';
  constructor(props) {
    super(props);

    this.id = Math.floor((1 + Math.random()) * 0x10000).toString(16);

    this.state = {
      Expanded: false,
    };
  }

  onClick = (e) => {
    e.preventDefault();
    this.setState({
      Expanded: !this.state.Expanded,
    });
    this.props.onClick && this.props.onClick(this.gtmDataHook);
  };

  componentDidUpdate() {
    if (this.state.Expanded && this.props.ResetAccordion) {
      this.setState({
        Expanded: false,
      });
    }
  }

  render() {
    const p = this.props;

    return (
      <div>
        <h2
          className={`accordion__heading ${
            this.state.Expanded ? 'accordion__heading--expanded' : ''
          }`}
          id={idHelper.createId(p.title)}
        >
          <button
            onClick={this.onClick}
            aria-expanded={this.state.Expanded}
            aria-controls={`${this.id}-accordion_block_${p.index}`}
            className='accordion__button'
            id={`${this.id}-accordion-item-${p.index}`}
            data-gtm-hook={this.gtmDataHook}
          >
            <span className='accordion__icon accordion__icon--water'></span>
            <span className='accordion__heading-label'>{p.label}</span>
            {p.title}
          </button>
        </h2>
        <div
          className={`accordion__region ${
            this.state.Expanded && 'accordion__region--expanded'
          }`}
          role='region'
          id={`${this.id}-accordion_block_${p.index}`}
          aria-labelledby={`${this.id}-accordion-item-${p.index}`}
          aria-hidden={!this.state.Expanded}
        >
          {p.children}
        </div>
      </div>
    );
  }
}
