//import hljs from 'highlight.js';

/*hljs.configure({
    languages: ['html', 'xml', 'css', 'scss', 'javascript']
});*/

export default class DesignSystemComponent {
    constructor(element) {
        const code = element.querySelector('pre');
        const codeText = code.querySelector('code').textContent;
        const codeCopier = element.querySelector('.js-design-system-copy-code');
        const codeToggler = document.querySelector('[data-target="' + element.id + '"]');

        /*if (!element.querySelector('code').classList.contains('cmd')) {
            hljs.highlightBlock(code);
        }*/

        if (codeToggler) {
            codeToggler.addEventListener('click', () => {
                element.classList.toggle('design-system-component__code-section--open');
                codeToggler.classList.toggle('design-system-component__toggler--open');
            });
        }

        if (codeCopier) {
            codeCopier.addEventListener('click', (e) => {
                navigator.clipboard.writeText(codeText);
            });
        }
    }
}