/* global navigator */
import XHR from '../../02-tools/xhr/xhr';

class GeoLocation {
  getGeoLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject('Geolocation is not available with this browser');
      }
    });
  }

  setGeoLocation() {
    const _this = this;
    return new Promise((resolve, reject) => {
      _this.getGeoLocation().then((position) => {
        const params = 'latitude=' + position.coords.latitude + '&longitude=' + position.coords.longitude;

        XHR.get('/api/geolocation/setlocation?', encodeURI(params)).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  setLocation(address) {
    return XHR.get('/api/geolocation/setlocation/', address);
  }

  setLocationId(id) {
    return XHR.get('/api/geolocation/setlocationid/', id);
  }
}

export default new GeoLocation();
