import Keyboard from '../../02-tools/keyboard/keyboard';

export default class ShowMore {
    constructor(element) {
        const icon = element.querySelector('.show-more__expand-collapse-icon');

        element.addEventListener('click', (e) => {
            e.preventDefault();
            icon.classList.toggle('show-more--expanded');

            if (element.getAttribute('aria-expanded') === true) {
                element.setAttribute('aria-expanded', false);
            } else {
                element.setAttribute('aria-expanded', true);
            }
        });

        element.addEventListener('keyup', (e) => {
            const evt = e || window.event;
            if (evt.which === Keyboard.right || evt.which === Keyboard.space) {
                element.click();
            }
        });
    }
}
