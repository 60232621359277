import React from "react";
import { LanguageSelector } from "@politiet/pds";

export default class LangSelector extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { CurrentLanguage: contentLanguage } = this.props;
        const pageLanguage = document.documentElement.lang;

        if (pageLanguage?.toLowerCase() !== contentLanguage?.toLowerCase()) {
            const main = document.getElementsByTagName("main")[0];
            const breadcrumbs = main?.getElementsByClassName("breadcrumbs")[0];
            const feedback =
                main?.getElementsByClassName("feedback-component")[0];

            main?.setAttribute("lang", contentLanguage);
            breadcrumbs?.setAttribute("lang", pageLanguage);
            feedback?.setAttribute("lang", pageLanguage);
        }
    }

    render() {
        return (
            <LanguageSelector
                languages={JSON.parse(this.props.LanguageData)}
                currentLanguage={{
                    id: "pds-language-selector-" + this.props.CurrentLanguage,
                    name: "",
                    href: "",
                    lang: this.props.CurrentLanguage,
                }}
            />
        );
    }
}
