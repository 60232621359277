export default class Video {
  constructor(element) {
    const videoUrl = element.dataset.videoUrl;
    const videoPlayer = element.querySelector('.video__player');

    if (videoPlayer) {
      this.updateEmbedPlayer(videoUrl, videoPlayer, element);
    }
  }

  updateEmbedPlayer(videoUrl, videoPlayer, video) {
    const player = videoPlayer;

    // Vimeo Embed Player Params
    // https://developer.vimeo.com/apis/oembed
    const vimeoEmbedParams = '?color=ffe100&title=0&byline=0&portrait=0';
    const vimeoEmbedUrl = 'https://player.vimeo.com/video/';

    // Youtube Embed Player Params
    // https://developers.google.com/youtube/player_parameters
    const youtubeEmbedParams = '?color=white&modestbranding=1&rel=0&showinfo=0';
    const youtubeEmbedUrl = 'https://www.youtube.com/embed/';

    const videoLoadingClass = 'video--loading';

    player.onload = () => {
      video.classList.remove(videoLoadingClass);
    };

    if (this.getVideoProvider(videoUrl) === 'vimeo') {
      player.setAttribute('src', vimeoEmbedUrl + this.getVideoId(videoUrl) + vimeoEmbedParams);
    } else if (this.getVideoProvider(videoUrl) === 'youtube') {
      player.setAttribute('src', youtubeEmbedUrl + this.getVideoId(videoUrl) + youtubeEmbedParams);
    }
  }

  getVideoId(videoUrl) {
    this.videoUrlRegExp = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    this.match = videoUrl.match(this.videoUrlRegExp);

    if (this.match && this.match[7]) {
      return this.match[7];
    }

    return false;
  }

  getVideoProvider(videoUrl) {
    this.vimeoRegexp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    this.youtubeRegexp = /^(https ?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

    if (this.vimeoRegexp.test(videoUrl)) {
      return 'vimeo';
    } else if (this.youtubeRegexp.test(videoUrl)) {
      return 'youtube';
    }

    return false;
  }
}
