/* global XMLHttpRequest */


class XHR {
    send(type, url, params, requestHeader) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(type, url);
            if (requestHeader != '') {
                xhr.setRequestHeader('Content-type', requestHeader);
            }
            xhr.send(params);

            xhr.onreadystatechange = () => {
                const DONE = 4;
                const OK = 200;
                if (xhr.readyState === DONE) {
                    if (xhr.status === OK) {
                        resolve(xhr.responseText);
                    } else {
                        if (xhr.statusText) {
                            reject({ 'status': xhr.status, 'statusText': xhr.statusText });
                        } else {
                            reject({ 'status': xhr.status });
                        }
                    }
                }
            };
        });
    }

    post(url, params, requestHeaders = 'application/x-www-form-urlencoded') {
        return this.send('POST', url, params, requestHeaders);
    }

    get(url, params) {
        return this.send('GET', url + params, '', '');
    }
}

export default new XHR();
