import Keyboard from '../../02-tools/keyboard/keyboard';
import SCROLLTO from '../../02-tools/scroll-to/scroll-to';

export default class Tabs {
    constructor(element) {
        const tablinks = element.querySelectorAll('.tabs__tab-link');
        const tabs = element.querySelectorAll('.tabs__tab');
        const panels = element.querySelectorAll('.tabs__panel');
        const msg = element.querySelector('.js-active-panel-message');
        const activeClass = 'tabs__tab--active';
        const hash = window.location.hash;
        const header = document.querySelector('.pno-header');
        let deepLink = false;

        try {
            deepLink = element.querySelector(hash);
        } catch (error) {
            // We should not output any errors in this case
        }

        /* if (window.history.pushState) {
          window.addEventListener('popstate', (e) => {
            console.log(e.state.panel);
          });
        } */

        for (const link of tablinks) {
            link.addEventListener('click', (e) => {
                e.preventDefault();

                const selectedPanelId = link.getAttribute('aria-controls');
                const selectedTab = link.parentNode;
                const selectedPanel = document.getElementById(selectedPanelId);

                for (const tab of tabs) {
                    tab.setAttribute('aria-selected', false);
                    tab.classList.remove(activeClass);
                }

                for (const panel of panels) {
                    panel.setAttribute('aria-hidden', true);
                }

                selectedTab.classList.add(activeClass);
                selectedTab.setAttribute('aria-selected', true);
                selectedPanel.setAttribute('aria-hidden', false);

                msg.innerHTML = msg.getAttribute(`data-msg-${selectedTab.getAttribute('id')}`);

                if (window.history.replaceState) {
                    window.history.replaceState({panel: selectedPanelId}, null, `#${selectedPanelId}`);
                } else {
                    window.location.hash = `#${selectedPanelId}`;
                }
            });

            if (deepLink) {
                element.querySelector(`#${deepLink.getAttribute('aria-labelledby')} .tabs__tab-link`).click();
                setTimeout(() => {
                    SCROLLTO.y(element.offsetTop - header.offsetHeight, 250, 'easeInOutQuint');
                }, 100);
            }

            link.addEventListener('keyup', (e) => {
                const evt = e || window.event;
                const selectedTab = link.parentNode;

                if (evt.which === Keyboard.right) {
                    if (selectedTab.nextElementSibling) {
                        link.setAttribute('tabindex', '-1');
                        const nextSiblingLink = selectedTab.nextElementSibling.querySelector('a');
                        nextSiblingLink.setAttribute('tabindex', '0');
                        nextSiblingLink.focus();
                    }
                } else if (evt.which === Keyboard.left) {
                    if (selectedTab.previousElementSibling) {
                        link.setAttribute('tabindex', '-1');
                        const prevSiblingLink = selectedTab.previousElementSibling.querySelector('a');
                        prevSiblingLink.setAttribute('tabindex', '0');
                        prevSiblingLink.focus();
                    }
                } else if (evt.which === Keyboard.enter || evt.which === Keyboard.space) {
                    link.click();
                }
            });
        }
    }
}
