import React from 'react';
import PropTypes from 'prop-types';
import XHR from '../../02-tools/xhr/xhr';
import Moment from 'moment';
import Autolinker from 'autolinker';
import idHelper from '../../02-tools/id-helper/idHelper';

export default class TwitterFeed extends React.Component {
    constructor(props) {
        super(props);
    }

    getData = () => {
	    return new Promise((resolve, reject) => {
            XHR.get('/globalassets/testfiler/tweet.json', encodeURI(address)).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    formatDate = (dateStr) => {
        let dateMoment = Moment(dateStr, "ddd MMM D HH:mm:ss ZZ YYYY");

        return dateMoment;
    }

    render() {
        const p = this.props;
        const twitterFeed = JSON.parse(p.Data) ? JSON.parse(p.Data) : [];
        const hashtagsToFilter = p.Hashtags ? p.Hashtags.replace(/[\s#]/g, '').split(',') : [];
        let tweetsToDisplay = [];

        if (p.Filter) {
            twitterFeed.map((tweet) => {
                let tweetHashtags = [];
                let hasMatch = false;

                tweet.entities.hashtags.map((hashtag) => {
                    tweetHashtags.push(hashtag.text.toLowerCase());
                });

                tweetHashtags = tweetHashtags.join();

                hashtagsToFilter.map((hashtag) => {
                    if (tweetHashtags.indexOf(hashtag.toLowerCase()) > -1) {
                        hasMatch = true;
                    }
                });

                if (hasMatch) {
                    tweetsToDisplay.push(tweet);
                }
            });

            tweetsToDisplay.length = p.Items;
        } else {
            tweetsToDisplay = twitterFeed;
            tweetsToDisplay.length = p.Items;
        }

        return (
            <ul className="twitter-feed__tweet-list list--reset-style-type">
                {tweetsToDisplay.map((tweet, index) => (
                    <li className="twitter-feed__tweet" key={index}>
                        <img className="twitter-feed__tweet-avatar" src={tweet.user.profile_image_url_https} alt={p.Translations.TwitterProfileImgAlt + ' ' + tweet.user.name} />
                        <h3 className="h5 twitter-feed__tweet-user-name" id={idHelper.createId(tweet.user.name)}><a className="twitter-feed__tweet-user-link" href={'https://twitter.com/' + tweet.user.screen_name}>{tweet.user.name}</a>{tweet.user.verified ? <span className="twitter-feed__tweet-verified-user" aria-label="This user is a verified user"></span> : null}</h3>
                        <span className="twitter-feed__tweet-screen-name">@{tweet.user.screen_name}</span>
                        <p className="twitter-feed__tweet-text" dangerouslySetInnerHTML={{
                            __html: Autolinker.link(tweet.text, {
                                urls: true, mention: 'twitter', hashtag: 'twitter', className: 'twitter-feed__tweet-content-link', replaceFn: (match) => {
                                    const tag = match.buildTag();
                                    if (tag.attrs.href.indexOf('t.co/') > 0) {
                                        tag.setAttr('aria-label', p.Translations.TwitterFullTweetLink);
                                    }

                                    return tag;
                                }
                            })
                        }}></p>
                        <ul className="twitter-feed__tweet-metadata list--reset-style">
                            <li className="twitter-feed__tweet-retweets-counter"><span className="show-for-sr">{p.Translations.TwitterRetweets}: </span>{tweet.retweet_count}</li>
                            <li className="twitter-feed__tweet-favorite-counter"><span className="show-for-sr">{p.Translations.TwitterFavorited}: </span>{tweet.favorite_count}</li>
                            <li className="twitter-feed__tweet-date"><span className="show-for-sr">{p.Translations.TwitterPublished} </span>{this.formatDate(tweet.created_at).format('DD.MM.YY [' + p.Translations.TwitterHour + '] HH:mm')}</li>
                        </ul>
                    </li>
                ))}
            </ul>
        );
    }
}

TwitterFeed.propTypes = {
	Data: PropTypes.string,
    Items: PropTypes.number,
    Hashtags: PropTypes.string,
    Filter: PropTypes.bool,
    Translations: PropTypes.array
};
