class Html {
  encode(str) {
    // List of HTML entities for escaping.
    const htmlEscapes = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#x27;',
      '/': '&#x2F;'
    };

    // Regex containing the keys listed immediately above.
    const htmlEscaper = /[&<>"'\/]/g;

    // Escape a string for HTML interpolation.
    return ('' + str).replace(htmlEscaper, (match) => {
      return htmlEscapes[match];
    });
  }

  decode(str) {
    if (typeof window !== 'undefined') {
      const e = document.createElement('div');
      e.innerHTML = str;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    return str;
  }

  unescapeHtml(str) {
    const convertedStr = str.replace(/&quot;/g, '"').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
    return convertedStr;
  }
}

export default new Html();
