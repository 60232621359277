import React from 'react';

export default class FormHint extends React.Component {
	constructor(props) {
		super(props);
    }
    render() {
        return (
            <p className="form-section__helper-text">{this.props.Hint}</p>
		);
	}
}