import React from 'react';

import Filter from '../filter/filter.jsx';
import DocumentFilterResult from './document-filter-result.jsx';

/*type Props = {
  API: String;
  SelectedFilterItems: Array<Object>;
  FilterItems: Array<Object>;
  FilterResult: Array<Object>;
  PaginationFilterItem: Array<Object>;
  SortItems: Array<Object>;
  SortParam: String;
  Translations: Array<Object>;
};*/

export default class NewsFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Filter {... this.props}>
        <DocumentFilterResult Translations={this.props.Translations} SortItems={this.props.SortItems} SortParam={this.props.SortParam}  updateParams={this.props.updateParams} />
      </Filter>
    );
  }
}
