import React from 'react';

/*type Props = {
  Label: ?string;
  Items: Array<Object>;
  Translations: Array<Object>;
  Loading: Boolean;
	IsVVR: Boolean;
};*/

export default class FilterPanelUnit extends React.Component {
  constructor(props) {
    super(props);
  }

  check = (e) => {
    let indexOfItem = this.props.Items.findIndex(i => i.Id == e.target.value);
    if (indexOfItem >= 0) {
      this.props.Items[indexOfItem].Selected = !(this.props.Items[indexOfItem].Selected);
    }

    this.props.filter(e.target.name, e.target.value, e.target.dataset.param, e.target.checked);
  }

  render() {
    const p = this.props;
    let contentTypes = [];

    p.Items.sort((a, b) => {
      const nameA = a.Name;
      const nameB = b.Name;

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });

    p.Items.map((result, index) => {
      if (!contentTypes[result.ContentType]) {
        contentTypes[result.ContentType] = [];
      }

      contentTypes[result.ContentType].push(
        <li className="xsmall-12 medium-4 column" key={result.Id} role="text">
          <label className="form__label" aria-hidden={result.NumberOfItems === 0 ? true : false}>
            <input type="checkbox" onChange={this.check.bind(this)} className="form__checkbox form__checkbox--filter" name={p.Label} value={result.Id} data-param={result.Name} id={`Filter-${p.Label}-${result.Id}`} checked={result.Selected ? 'checked' : ''} disabled={result.NumberOfItems === 0 || p.Loading ? 'disabled' : ''} />
            <span className="form__label-text form__label--offset">{result.Name}&nbsp;(<span aria-label={p.Translations.NumberOfItemsPrefix + ' ' + result.NumberOfItems + ' ' + p.Translations.NumberOfItemsPostfix}>{result.NumberOfItems}</span>)</span>
          </label>
        </li>
      );
    });

    return (
      <div className={`filter__panel${p.selected ? '' : ' filter__panel--hidden'}`}>
        <div className="xsmall-12 medium-12 column" id={`${p.Label}Panel`} role="tabpanel" aria-labelledby={`${p.Label}Tab ${p.Label}TabMobile`} aria-hidden={!this.props.selected}>
          {contentTypes['NationalUnitPage'] ? 
            <ul className="filter__item-choices filter__item-choices--sectioned list--reset-style-type row">
              {contentTypes['NationalUnitPage']}
            </ul>
            : null}
          {contentTypes['DistrictPage'] ? 
            <ul className="filter__item-choices filter__item-choices--sectioned list--reset-style-type row">
              {contentTypes['DistrictPage']}
            </ul>
	        : null}
          <div className="filter__btn-container">
            <button className="btn btn--positive btn--small" onClick={p.CloseTabs}>{p.Translations.Close}</button>
          </div>
        </div>
      </div>
    );
  }
}