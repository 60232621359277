import React from 'react';
import idHelper from '../../02-tools/id-helper/idHelper';

export default class IdPortenModalContent extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="row align-center align-middle modal-2__row">
				<div className="xsmall-12 medium-8 large-6 xlarge-5 column modal-2__content" tabIndex="-1">
					<section className="notification notification--alert" role="alert">
						<h2 className="notification__heading" id={idHelper.createId(this.props.Title)}>{this.props.Title}</h2>
						<div className="notification__message">
							<span dangerouslySetInnerHTML={{ __html: this.props.BodyText }}></span>
							<div className="notification__btn-row">
								<button className="btn btn--subtle btn--stackable">
									<span className="btn--link__link-text">{this.props.Translations.BtnProlong}</span>
								</button>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}
