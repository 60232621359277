import React from 'react';
import OpeningHoursList from './opening-hours-list.jsx';
import OpeningHoursTable from './opening-hours-table.jsx';
import Keyboard from '../../02-tools/keyboard/keyboard';
import Html from '../../02-tools/html/html';
import Moment from 'moment';
import idHelper from '../../02-tools/id-helper/idHelper.js';

/*type Props = {
  Data: Array<Object>;
  Translations: Array<Object>;
};*/

export default class OpeningHours extends React.Component {
  constructor(props) {
    super(props);

    this.dataSize = 0;
    this.id = Math.floor((1 + Math.random()) * 0x10000).toString(16);

    this.state = {
      selectedTab: 0
    }
  }

  selectTab = (index) => {
    this.setState({
      selectedTab: index
    });
  }

  previousTab = (index) => {
    if (index > 0) this.selectTab(index - 1)
  }

  nextTab = (index) => {
    if (index < this.dataSize - 1) this.selectTab(index + 1)
  }

  handleKeyPress = (keyIndex, e) => {
    if (e.which === Keyboard.right) {
      this.nextTab(keyIndex)
    } else if (e.which === Keyboard.left) {
      this.previousTab(keyIndex)
    }
  }

  componentDidUpdate() {
    if (this.activeLink) {
      this.activeLink.focus()
    }
  }

  isDeviationInPast = (start, stop) => {
	const begins = Moment(start);
    const ends = Moment(stop);
    const yesterday = Moment().subtract(1, 'days');

    if (stop == undefined) {
        return yesterday.diff(begins) > 0 ? true : false;
    } else if(ends.isValid()) {
      return yesterday.diff(ends) > 0 ? true : false;
    } else {
      return yesterday.diff(begins) > 0 ? true : false;
    }
  }

  formatDeviationDate = (start, stop) => {
    const begins = Moment(start);
    const ends = Moment(stop);

    if (!begins.isValid() && ! ends.isValid()) {
	    return '';
    } else if (begins.isSame(ends) || !ends.isValid() || stop == undefined) {
	    return begins.format('DD.MM');
    } else if (!begins.isValid() && ends.isValid()) {
	    return ends.format('DD.MM');
    }

    return begins.format('DD.MM') + '–' + ends.format('DD.MM');
  }

  render() {
    const p = this.props;

    let restructuredData = [];
    if (p.Data) {
      if (p.Data.winter) {
        let winterData = p.Data.winter;
        winterData.name = p.Translations.WinterHours;
        winterData.start = this.props.hasSummerHours ? Moment(p.Data.summerEnd).add(1, 'days').format('DD.MM') : '01.01';
        winterData.end = this.props.hasSummerHours ? Moment(p.Data.summerStart).subtract(1, 'days').format('DD.MM') : '31.12';

        restructuredData.push(winterData);
      }

      if (this.props.hasSummerHours) {
        if (p.Data.summer) {
          let summerData = p.Data.summer;
          summerData.name = p.Translations.SummerHours;
          summerData.start = Moment(p.Data.summerStart).format('DD.MM');
          summerData.end = Moment(p.Data.summerEnd).format('DD.MM');

          restructuredData.push(summerData);
        }
      }
    }

    let tabs = [];
    let panels = [];
    let deviationMsgs = [];
    let publicHolidayMsgs = [];
    this.dataSize = restructuredData.length;

    if (p.isSummerTime) {
      restructuredData.reverse();
    }

    restructuredData.map((item, index) => {
      let isTabActive = this.state.selectedTab === false ? (this.defaultTab == index) : (this.state.selectedTab == index);
      let tabIndex = (isTabActive ? 0 : -1);

      let appointments = 0;
      let phoneHours = 0;
      let dropins = 0;
      let passport = 0;
      let primary = 0;

      item.openingHours.map((item, index) => {
        item.appointment ? appointments++ : null;
        item.phone ? phoneHours++ : null;
        item.dropin ? dropins++ : null;
        item.passport ? passport++ : null;
	      item.primary ? primary++ : null;
      });

      const hasAppointments = appointments > 0 ? true : false;
      const hasPhoneHours = phoneHours > 0 ? true : false;
      const hasDropIn = dropins > 0 ? true : false;
      const hasPassport = passport > 0 ? true : false;
      const hasPrimary = primary > 0;

      if (hasAppointments || hasPhoneHours || hasDropIn || hasPassport || hasPrimary) {

        deviationMsgs.push(
          <div key={this.id + "-Dev" + index} className={`opening-hours__deviation-msg ${!isTabActive ? 'opening-hours__deviation-msg--hidden' : ''}`} aria-hidden={isTabActive ? false : true} dangerouslySetInnerHTML={item.deviationText ? { __html: Html.unescapeHtml(item.deviationText) } : null}></div>
        );

        publicHolidayMsgs.push(
          <p key={this.id + "-Msg" + index} className={`opening-hours__public-holiday-msg ${!isTabActive ? 'opening-hours__public-holiday-msg--hidden' : ''}`} aria-hidden={isTabActive ? false : true}>{item.closedPublicHoliday ? p.Translations.PublicHolidaysClosedMsg : null}</p>
        );

        if (p.isPlainMode) {
	        tabs.push(
		        <button
              className={`opening-hours__tab-btn--plain${isTabActive ? ' opening-hours__tab-btn--hidden' : ' opening-hours__tab-btn--plain'}`}
			        key={this.id + "-Btn" + index}
			        id={`${this.id}-tab${index}`}
			        aria-controls={`${this.id}-panel${index}`}
			        aria-selected={isTabActive}
			        role="tab"
			        onClick={this.selectTab.bind(this, index)}
			        ref={link => { if (index === this.state.selectedTab) this.activeLink = link }}
			        onKeyUp={this.handleKeyPress.bind(this, index)}>
              <span className="opening-hours__tab-btn-label">{isTabActive ? item.name.toLowerCase() : p.Translations.View + ' ' + item.name.toLowerCase()}</span>
		        </button>
	        );
        } else {
	        tabs.push(
	          <li className="opening-hours__tab" role="presentation" key={this.id + "-Tab" + index}>
	            <button
	              className={`opening-hours__tab-btn ${isTabActive ? 'opening-hours__tab-btn--active' : ''}`}
	              id={`${this.id}-tab${index}`}
	              aria-controls={`${this.id}-panel${index}`}
	              aria-selected={isTabActive}
	              tabIndex={tabIndex}
	              role="tab"
	              onClick={this.selectTab.bind(this, index)}
	              ref={link => { if (index === this.state.selectedTab) this.activeLink = link }}
	              onKeyUp={this.handleKeyPress.bind(this, index)}>
	              <span className="opening-hours__tab-btn-label">{item.name}</span> <span className="opening-hours__tab-btn-date">{item.start}–{item.end}</span>
	            </button>
	          </li>
	        );
        }

	      panels.push(
		      <div role="tablist" key={this.id + "-Panel" + index}>
			      <div className={`opening-hours__panel ${!isTabActive ? 'opening-hours__panel--hidden' : ''}`} id={`${this.id}-panel${index}`} role="tabpanel" aria-labelledby={`${this.id}-tab${index}`} aria-hidden={!isTabActive}>
				      {p.isPlainMode ?
	              <OpeningHoursList OpeningHours={item.openingHours} Translations={p.Translations} HideTitles={p.Data.hideTitle} HasAppointments={hasAppointments} HasPhoneHours={hasPhoneHours} HasDropIn={hasDropIn} HasPassport={hasPassport} HasPrimary={hasPrimary} /> :
	              <OpeningHoursTable OpeningHours={item.openingHours} Translations={p.Translations} HideTitles={p.Data.hideTitle} HasAppointments={hasAppointments} HasPhoneHours={hasPhoneHours} HasDropIn={hasDropIn} HasPassport={hasPassport} HasPrimary={hasPrimary} />
              }
				      <p className="opening-hours__no-closing-hour-msg">{item.noClosingHourMsg ? '* ' + item.noClosingHourMsg : null}</p>
			      </div>
		      </div>
	      );
      }
    });

  	let seasonTitle = '';
		let seasonRange = '';
    if (restructuredData.length > 1 && p.isPlainMode) {
      seasonTitle = restructuredData[this.state.selectedTab].name;
      seasonRange = seasonTitle + ' ' + restructuredData[this.state.selectedTab].start + '–' + restructuredData[this.state.selectedTab].end;
    }

    let deviationBlockContent = [];
    if (p.Data.deviationBlocks && p.Data.deviationBlocks.length) {
      p.Data.deviationBlocks.map((deviation, index) => {
        if (!this.isDeviationInPast(deviation.deviationStartTime, deviation.deviationEndTime)) {
          deviationBlockContent.push(<p className="spacing--no-margin" key={'deviation' + index}><span className="weight--bold">{this.formatDeviationDate(deviation.deviationStartTime, deviation.deviationEndTime)}</span> {deviation.deviationTitle}</p>);
        }
      })
    }

    return (
      panels.length ?
        <section className="opening-hours">
	        <header className={`opening-hours__header ${p.isPlainMode ? 'opening-hours__header--plain' : null}`}>
		        <h2 className="h4 opening-hours__headline opening-hours__headline--plain" aria-live="polite" id={idHelper.createId(p.Translations.OpeningHours)}>{p.Translations.OpeningHours} {seasonTitle ? seasonTitle.toLowerCase() : null}</h2>
		        {p.isPlainMode ? tabs : null}
	        </header>
	        <div className="opening-hours__notification">
		        {seasonRange ? <p className="spacing--no-margin">{seasonRange}</p> : null}
		        {deviationMsgs}
		        {publicHolidayMsgs}
	        </div>

          {deviationBlockContent.length ?
		        <section className="notification notification--warning notification--has-icon notification__icon--calendar">
			        <h3 className="notification__heading" id={idHelper.createId(p.Translations.DeviationTitle)}>{p.Translations.DeviationTitle}</h3>
			        <div className="notification__message">
                {deviationBlockContent}
			        </div>
		        </section>
		        : null}

	        {p.isPlainMode ? null : <ul className={tabs.length > 1 ? 'opening-hours__tabs list--reset-style' : 'opening-hours__tabs opening-hours__tabs--hidden list--reset-style'} role="tablist">{tabs}</ul>}
	        {panels}
        </section>
        : null
    );
  }
}
