import XHR from '../../02-tools/xhr/xhr';

export default class Dictionary {
    static lookup(phrase) {
        return new Promise((resolve, reject) => {
            XHR.get('/api/dictionary/search?phrase=', encodeURI(phrase)).then((data) => {
                if (JSON.parse(data)) {
                    resolve(data);
                } else {
                    reject(data);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }
}
