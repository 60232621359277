import React from "react";
const IdPortenBarContent = (props) => {
    return (
        <div className="id-porten-bar__content">
            <div className="id-porten-bar__user-info">
                {props.loggedInText}{" "}
                <span className="id-porten-bar__user-id">{props.userInfo}</span>
            </div>
            <button
                className="id-porten-bar__logout-btn"
                onClick={props.onClick}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="id-porten-bar__logout-btn-icon"
                >
                    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2m0 12H6V10h12zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2"></path>
                </svg>
                {props.loggOutText}
            </button>
        </div>
    );
};
export default IdPortenBarContent;
