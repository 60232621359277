import Hash from "content-hash";

export function empty(node) {
    while (node.firstChild) {
        node.removeChild(node.firstChild);
    }
}

// Util to get API code for use internally in content data APIs
export function getApiCode(env) {
    let hasValidEnv = isValidApiRoot(env);

    if (hasValidEnv) {
        switch (env) {
            case "utv.web":
            case "utv-web":
            case "dev":
            case "development":
                return "FCA8C7BD-4229-4804-8C01-8A8A56D4A766";
            case "test.web":
            case "test.edit":
            case "test-web":
            case "test-edit":
            case "test":
                return "F854BF7E-C84E-445B-AE1B-4402517F879F";
            case "stage.web":
            case "stage.edit":
            case "stage-web":
            case "stage-edit":
            case "stage":
                return "E73164E8-118B-42A5-B962-FF72F2A0A03F";
            case "www":
            case "edit":
            case "prod":
            case "production":
                return "5F47EB58-B777-41B0-A29F-92C3BD1A8012";
            default:
                return "FCA8C7BD-4229-4804-8C01-8A8A56D4A766";
        }
    }
}

export function isValidApiRoot(env) {
    const validEnvs = [
        "dev",
        "development",
        "local",
        "utv.web",
        "utv-web",
        "utv-edit",
        "test",
        "test.web",
        "test-web",
        "test.edit",
        "test-edit",
        "stage",
        "stage.web",
        "stage-web",
        "stage.edit",
        "stage-edit",
        "www",
        "edit",
        "prod",
        "production",
    ];
    const isValidEnv = validEnvs.includes(env);

    return isValidEnv;
}

export function isValidLanguage(lang) {
    const validLangs = ["nb", "en", "nn", "se"];
    const isValidLang = validLangs.includes(lang);

    return isValidLang;
}

export function getUrlRoot(env) {
    let hasValidEnv = isValidApiRoot(env);

    if (hasValidEnv) {
        switch (env) {
            case "local":
                return "https://localhost:16001";
            case "dev":
            case "development":
                return "https://utv-web.politiet.no";
            case "test":
                return "https://test-web.politiet.no";
            case "stage":
                return "https://stage-web.politiet.no";
            default:
                return "https://www.politiet.no";
        }
    }
}

export function getHash(value) {
    if (!value) {
        return null;
    }
    return Hash.encode("sha2-512", value);
}
