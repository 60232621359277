import Keyboard from '../../02-tools/keyboard/keyboard';
import XHR from '../../02-tools/xhr/xhr';

export default class Feedback {
  constructor(element) {
    const buttons = element.querySelectorAll('.feedback-component__btn-group button');
    const resetButtons = element.querySelectorAll('.notification .feedback-component__reset-btn');
    const fieldset = element.querySelector('.feedback-component__fieldset');
    const choiceValue = document.getElementById('choicevalue');
    const pageguid = document.getElementById('pageguid');
    const robot = document.getElementById('Robot');
    const activeBtnClass = 'feedback-component__btn--active';
    this.form = element.querySelector('.feedback-component__form');

    this.successResponse = element.querySelector('.notification--positive');
    this.errorResponse = element.querySelector('.notification--error');
    this.form.reset();

    for (let button of buttons) {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        for (const btn of buttons) {
          btn.classList.remove(activeBtnClass);
          btn.setAttribute('aria-pressed', false);
        }

        fieldset.classList.add('feedback-component__fieldset--open');
        choiceValue.value = button.getAttribute('data-value');
        button.classList.add(activeBtnClass);
        button.setAttribute('aria-pressed', true);
      });

      button.addEventListener('keydown', (e) => {
        e = e || window.event;
        if (e.which === Keyboard.space) {
          button.click();
        } else if (e.which === Keyboard.enter) {
          e.preventDefault();
          button.click();
        } else if (e.which === Keyboard.right || e.which === Keyboard.left) {
          button.classList.contains('feedback-component__btn--success') ? buttons[1].focus() : buttons[0].focus();
        }
      });
    }

    for (let reset of resetButtons) {
      reset.addEventListener('click', () => {
        this.resetForm();
      });
    }

    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      let text = element.querySelector('.feedback-component__textarea');
      if (!robot.value && pageguid && choiceValue.value) {
        let params = 'contentId=' + pageguid.value + '&text=' + text.value + '&feedbackType=' + choiceValue.value;

        XHR.post('/api/feedback', encodeURI(params)).then((data) => {
          this.form.classList.add('feedback-component__form--hidden');
          this.successResponse.classList.remove('notification--hidden');
        }).catch((error) => {
          this.form.classList.add('feedback-component__form--hidden');
          this.errorResponse.classList.remove('notification--hidden');
          console.log(error);
        });
      } else {
        this.form.classList.add('feedback-component__form--hidden');
        this.errorResponse.classList.remove('notification--hidden');
      }
    });

    setTimeout(() => {
      robot.tabIndex = -1;
    }, 3000);
  }

  resetForm() {
    this.form.classList.remove('feedback-component__form--hidden');
    this.successResponse.classList.add('notification--hidden');
    this.errorResponse.classList.add('notification--hidden');
  }
}
