import React from 'react';
import Notification from '../../05-blocks/notification/notification.jsx';

export default class FormStepNavigation extends React.Component {
	constructor(props) {
		super(props);
    }

    render() {
        return (
            <section className="form-section__section form-section__fieldset--actions">
	            {this.props.ShowSystemError ?
                    <Notification Type="error" Message={this.props.Translations.Errors.SystemError} />
                : null}
                {this.props.ActiveStep === this.props.TotalSteps && !this.props.NextButtonUrl ?
                    <button className={`btn btn--subtle btn--stackable${this.props.Loading ? ' btn--loading' : ''}`} type="submit" onClick={(e) => this.props.handleSubmit(e)}>
	                    <span className="btn__text">{this.props.Loading ? this.props.Translations.BtnLoading : this.props.Translations.BtnSend}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 37 38" className="btn__loader">
                            <defs>
                                <path id="a" d="M9.744 2.046V.223H.482v1.823h9.262z" />
                                <path id="c" d="M2.782.77H.955v9.25h1.827V.77z" />
                            </defs>
                            <g fill="none" fillRule="evenodd">
                                <g transform="translate(27.038 17.73)">
                                    <mask id="b" fill="#fff">
                                        <use xlinkHref="#a" />
                                    </mask>
                                    <path fill="#E6E9F2" d="M1.394.223a.912.912 0 1 0 0 1.823h7.438a.913.913 0 0 0 .914-.912c0-.503-.41-.91-.914-.91H1.394z" mask="url(#b)" />
                                </g>
                                <path fill="#A1A8B5" d="M.913 17.954a.912.912 0 1 0 0 1.823H8.35a.913.913 0 0 0 .913-.912c0-.503-.41-.91-.913-.91H.913z" />
                                <path fill="#EAEDF4" d="M27.543 23.09a.912.912 0 1 0-.912 1.58l6.442 3.711a.912.912 0 1 0 .911-1.578l-6.44-3.714z" />
                                <path fill="#B0B6C2" d="M3.711 9.35a.91.91 0 1 0-.913 1.578L9.24 14.64a.912.912 0 1 0 .914-1.578L3.71 9.35z" />
                                <path fill="#F1F2F8" d="M24.203 27.093a.913.913 0 0 0-1.582.911l3.72 6.433a.914.914 0 0 0 1.581-.911l-3.719-6.433z" />
                                <path fill="#BFC4CE" d="M10.443 3.294a.912.912 0 1 0-1.582.912l3.72 6.432a.912.912 0 0 0 1.582-.912l-3.72-6.432z" />
                                <path fill="#F6F8FC" d="M19.305 28.892a.913.913 0 0 0-1.827 0v7.428a.913.913 0 0 0 1.827 0v-7.428z" />
                                <g transform="translate(16.524 -.269)">
                                    <mask id="d" fill="#fff">
                                        <use xlinkHref="#c" />
                                    </mask>
                                    <path fill="#C8CDD9" d="M2.782 1.68a.912.912 0 0 0-1.827 0v7.427a.913.913 0 0 0 1.827 0V1.681z" mask="url(#d)" />
                                </g>
                                <path fill="#FFF" d="M14.162 28.005a.913.913 0 0 0-1.582-.91l-3.718 6.431a.91.91 0 0 0 .334 1.245.915.915 0 0 0 1.247-.334l3.719-6.432z" />
                                <path fill="#D1D6E1" d="M27.922 4.206a.912.912 0 1 0-1.582-.912l-3.718 6.432a.913.913 0 0 0 1.58.912l3.72-6.432z" />
                                <path fill="#959DAE" d="M10.153 24.668a.911.911 0 1 0-.914-1.579L2.8 26.803a.912.912 0 0 0 .913 1.58l6.44-3.715z" />
                                <path fill="#DCDFE8" d="M33.984 10.928a.912.912 0 0 0-.912-1.578l-6.442 3.712a.911.911 0 1 0 .913 1.58l6.441-3.714z" />
                            </g>
                        </svg>
                    </button>
                    :
                    <button className="btn btn--subtle btn--stackable" type="button" onClick={() => { this.props.navigateToStep(this.props.StepIndex + 1, true, this.props.NextButtonUrl) }}>{this.props.Translations.BtnNext}</button>
                }
                <button className="btn--subtle btn--link btn--stackable js-modal-2-trigger" type="button" onClick={(e) => { this.props.openModal(e) }} aria-controls="modal-2"><span className="btn--link__link-text">{this.props.Translations.BtnCancel}</span></button>
            </section>
		);
	}
}
