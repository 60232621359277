import React from 'react';
import EventSonar from './event-sonar.jsx';
import idHelper from '../../02-tools/id-helper/idHelper.js';

/*type Props = {
  Events: Array<Object>;
  Event: Array<Object>;
  RootClass: String;
  Translations: Array<Object>;
};*/

export default class EventMessage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const p = this.props;
    let isSingleEvent = p.Events.length > 1 ? false : true;
    let isSingleEventAndMultipleIncident = (isSingleEvent && p.Events[0].incidents.length > 1) ? true : false;

    if (isSingleEvent) {
      return isSingleEventAndMultipleIncident ? <EventMessageSingleEventMultiIncident Event={p.Events[0]} Translations={p.Translations} RootClass={p.RootClass} /> : <EventMessageSingleEventSingleIncident Event={p.Events[0]} Translations={p.Translations} RootClass={p.RootClass} />;
    } else {
      return <EventMessageMultiEvents Events={p.Events} Translations={p.Translations} RootClass={p.RootClass} />;
    }
  }
}

class EventMessageSingleEventSingleIncident extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const p = this.props;
    const incident = p.Event.incidents[0];

    if (!incident) {
      return null;
    }

    return (
      <section className={p.RootClass}>
        <div className="row event__content-row">
          <EventSonar Translations={p.Translations} Hidden={false} IsHigh={false} />
          <div className="column event__content-column">
            <div className="row">
              <div className="column event__content-column">
                <a className="event__link--single-line link--no-underline" href={incident.url}>
                  <div className="event__content-wrapper event__content-wrapper--ellipsis">
	                  <h2 className="event__title" id={idHelper.createId(incident.incidentTitle)}>{incident.incidentTitle}</h2>
                    <p className="event__summary">{incident.mainIntro}</p>
	                </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

class EventMessageSingleEventMultiIncident extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const p = this.props;
    let incidents = [];

    p.Event.incidents.map((incident, index) => {
      incidents.push(
        <div className="row" key={index}>
          <div className="column event__content-column">
            <a className="event__link--single-line event__link--padded link--no-underline" href={incident.url}>
              <h3 className="event__title event__title--inline" id={idHelper.createId(incident.incidentTitle)}>{incident.incidentTitle}</h3>
	            <p className="event__summary">{incident.mainIntro}</p>
            </a>
          </div>
        </div>
      );
    });

    return (
      <section className={p.RootClass}>
        <div className="row event__content-row">
          <EventSonar Translations={p.Translations} Hidden={false} IsHigh={false} />
          <div className="column event__content-column event__content-column--minor">
            <div className="event__content-wrapper">
		          <h2 className="event__title" id={idHelper.createId(p.Event.eventTitle)}>{p.Event.eventTitle}</h2>
              {incidents}
	          </div>
	        </div>
        </div>
      </section>
    )
  }
}

class EventMessageMultiEvents extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const p = this.props;
    let events = [];

    p.Events.map((event, index) => {
      const hasSingleIncident = event.incidents.length > 1 ? false : true;
      const isFirst = index === 0 ? true : false;

      events.push(
        hasSingleIncident ?
          <div className="row event__content-row" key={index}>
            <EventSonar Translations={p.Translations} Hidden={!isFirst} IsHigh={false} />
            <div className="column event__content-column event__content-column--minor">
              <div className="row">
                <div className="column event__content-column">
                  <a className="event__link--single-line event__link--multi-line link--no-underline" href={event.incidents[0].url}>
                    <div className="event__content-wrapper event__content-wrapper--ellipsis">
	                    <h2 className="event__title" id={idHelper.createId(event.incidents[0].incidentTitle)}>{event.incidents[0].incidentTitle}</h2>
                      <p className="event__summary">{event.incidents[0].mainIntro}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="row event__content-row" key={index}>
            <EventSonar Translations={p.Translations} Hidden={!isFirst} IsHigh={false} />
            <div className="column event__content-column event__content-column--minor">
	            <div className="event__content-wrapper">
		            <h2 className="event__title event__title--incidents">{event.eventTitle}</h2>
		            {event.incidents.map((incident, index) => {
		              return (
		                <div className="row" key={index}>
		                  <div className="column event__content-column">
	                      <a className="event__link--single-line event__link--padded link--no-underline" href={incident.url}>
		                        <h3 className="event__title event__title--inline" id={idHelper.createId(incident.incidentTitle)}>{incident.incidentTitle}</h3>
	                          <p className="event__summary">{incident.mainIntro}</p>
		                    </a>
		                  </div>
		                </div>
		              );
	              })}
	            </div>
            </div>
          </div>
      );
    });

    return (
      <section className={p.RootClass}>
        {events}
      </section>
    )
  }
}
