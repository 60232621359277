import React from 'react';
import Guid from '../../../02-tools/guid/guid';
import FormHint from '../form-hint.jsx';
import FormMoreInfo from '../form-more-info.jsx';
import FormField from '../form-field.jsx';
import FieldFormError from '../form-error.jsx';

export default class FormInputRadio extends React.Component {
	constructor(props) {
        super(props);

        this.guid = Guid.create();
		this.dependencies = [];

        this.state = {
	        showInfo: false,
			selectedOption: props.UserInputValue ? props.UserInputValue : ''
        }
    }

    onChange = (e) => {
		e.persist();
		
		this.setState({
			selectedOption: e.target.value
		}, () => {
			if (this.props.HasError) this.props.ResetError(true);
			this.props.OnChange(this.props.Name, this.state.selectedOption)
			this.props.ValidateField(e);
		});
        this.props.toggleNavigation();
    }

    toggleInfo = (e) => {
        e.preventDefault();
	    this.setState(prevState => ({
		    showInfo: !prevState.showInfo
	    }));
    }

	renderDependency = (formFields, formDataInput, formSteps, activeStep, navigateToStep, stepIndex, handleChange, setFormErrors, removeFieldError, setErrorMessage, errors, toggleNavigation) => {
        this.dependencies = [];
	    formFields.map((formField, index) => (
            this.dependencies.push(<FormField key={index} FormFieldData={formField} FormDataInput={formDataInput} FormSteps={formSteps} ActiveStep={activeStep} navigateToStep={navigateToStep} StepIndex={stepIndex} handleChange={handleChange} setFormErrors={setFormErrors} removeFieldError={removeFieldError} setErrorMessage={setErrorMessage} Errors={errors} toggleNavigation={toggleNavigation} Translations={this.props.Translations} />)
	    ));
	}

	render() {
		const errorClass = "form-section__message form-section__message--error form-section__message--active";

		return (
            <>
                <fieldset className="form-section__fieldset">
                    <legend className="form-section__legend">{this.props.Required ? this.props.Label : this.props.Label + ' ' + this.props.Translations.Optional}</legend>
					{this.state.selectedOption == '' ? <input name={this.props.Name} type="hidden" value={this.state.selectedOption} data-validation="radio" data-label={this.props.Label} data-type={this.props.Type} required={this.props.Required} /> : null}
		            {this.props.Hint ?
	                    <FormHint Hint={this.props.Hint} />
	                    : null}
                    {this.props.InfoTrigger && this.props.Info ?
                        <FormMoreInfo Active={this.state.showInfo} Id={`more-info-${this.guid}`} Info={this.props.Info} BtnText={this.props.InfoTrigger} toggleInfo={this.toggleInfo} Translations={this.props.Translations} />
						: null}
					{this.props.HasError ?
						<FieldFormError errorMessage={this.props.ErrorMessage} stylename={errorClass} />
						: null}
	                {this.props.Options ?
                        this.props.Options.map((option, index) => {
							if (option.dependency && this.state.selectedOption === option.value) {
								this.renderDependency(option.dependency, this.props.FormDataInput, this.props.FormSteps, this.props.ActiveStep, this.props.navigateToStep, this.props.StepIndex, this.props.OnChange, this.props.setFormErrors, this.props.removeFieldError, this.props.setErrorMessage, this.props.Errors, this.props.toggleNavigation);
                            }

                            return (
	                            <label key={`radio-${index}`} className="form__label form-section__input-group-item">
                                    <input
										className="form__radio"
										name={this.props.Name}
										type="radio"
										value={option.value}
										checked={this.state.selectedOption === option.value}
										onChange={(e) => this.onChange(e)}
										data-label={this.props.Label}
										data-deadend={option.deadend}
										data-validation="radio"
									/>
		                            <span className="form__label-text form__label--offset">{option.label}</span>
		                        </label>
							)
                        })
		            : null}
                </fieldset>
                {this.dependencies}
			</>
		);
	}
}