import "babel-polyfill";
import "classlist-polyfill";
import "./01-settings/dataset-shim";
import "url-search-params-polyfill";
import "es6-string-polyfills";
import "element-closest";

// React
import ReactDOM from "react-dom";
import React from "react";
import Moment from "moment";

// Tools
import Mount from "./02-tools/mount";
import PrintInsight from "./02-tools/insight/print-insight";

// JSX Components
import * as JSX from "./05-blocks/jsx";

// Components
import Accordion from "./05-blocks/accordion/accordion";
import Feedback from "./05-blocks/feedback/feedback";
import FormSection from "./05-blocks/form-section/form-section";
import FormGuide from "./05-blocks/form-guide/form-guide";
import Header from "./05-blocks/header/header";
import Process from "./05-blocks/process/process";
import SiteNavigation from "./05-blocks/site-navigation/site-navigation";
import Modal from "./05-blocks/modal/modal";
//import Modal2 from './05-blocks/modal/2.0/modal';
import ImageModal from "./05-blocks/modal/image-modal";
import SearchResult from "./05-blocks/search-result/search-result";
import Video from "./05-blocks/video/video";
import SkipToContent from "./05-blocks/skip-to-content/skip-to-content";
import DataTable from "./05-blocks/data-table/data-table";
import EpiserverForms from "./05-blocks/episerver-forms/episerver-forms";
import Tabs from "./05-blocks/tabs/tabs";
import Toggler from "./05-blocks/toggler/toggler";
import ShowMore from "./05-blocks/show-more/show-more";
import DesignSystemComponent from "./05-blocks/design-system-component/design-system-component";
import SearchListing from "./05-blocks/search-listing/search-listing";
import MainContent from "./05-blocks/main-content/main-content";
//import MicroFrontend from './05-blocks/micro-frontend/micro-frontend';

global.React = React;
global.ReactDOM = ReactDOM;
global.Moment = Moment;
global.JSX = JSX;

// Mount
//Mount('micro-frontend', MicroFrontend);
Mount("accordion", Accordion);
Mount("feedback-component", Feedback);
Mount("form-section", FormSection);
Mount("form-guide", FormGuide);
Mount("header", Header);
Mount("process", Process);
Mount("search-listing", SearchListing);
Mount("site-navigation", SiteNavigation);
Mount("modal", Modal);
//Mount('modal-2', Modal2);
Mount("image-modal", ImageModal);
Mount("search-result", SearchResult);
Mount("video", Video);
Mount("skip-to-content", SkipToContent);
Mount("data-table", DataTable);
Mount("EPiServerForms", EpiserverForms);
Mount("tabs", Tabs);
Mount("toggler", Toggler);
Mount("js-show-more", ShowMore);
Mount("js-design-system-component", DesignSystemComponent);
Mount("main-content", MainContent);

// Tracking
PrintInsight();
