import React from 'react';
import FormSummaryStep from './form-summary-step.jsx';

export default class FormSummary extends React.Component {
	constructor(props) {
		super(props);
    }

    render() {
        return (
            <ul className="form-summary list--reset-style-type">
                {this.props.FormSteps.map((step, index) => {
                    if (index + 1 < this.props.ActiveStep) {
                        return (
                            <FormSummaryStep key={index} FormStep={step} FormData={this.props.FormDataInput[index]} StepIndex={index +1} navigateToStep={this.props.navigateToStep} />
                        )
                    }
                })}
            </ul>
		);
	}
}