import React from "react";
import Moment from "moment";

/*type Props = {
  PublishedDate: String;
  ChangedDate: String;
  ContentType: String;
	EnhancedLabels: Boolean;
	ShowResponsibleUnits: Boolean;
	ShowPublishedOrChangedDate: Boolean;
  ShowDate: Boolean;
  ResponsibleUnits: String;
	RootClass: String;
  Translations: Array<Object>;
};*/

export default class MetaData extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const p = this.props;
        const hasEnhancedLabels = p.EnhancedLabels === true ? true : false;
        const publishedMoment = Moment(p.PublishedDate);
        const changedMoment = Moment(p.ChangedDate);
        const delimiter = (
            <span aria-hidden="true" className="metadata__item-delimiter">
                |
            </span>
        );
        const responsibleUnitLi = p.ResponsibleUnits ? (
            <li className="metadata__item">
                <span
                    className={
                        hasEnhancedLabels
                            ? "metadata__item-label weight--bold"
                            : "metadata__item-label"
                    }
                >
                    {p.Translations.MetaDataResponsibleUnit}
                </span>{" "}
                <span
                    className={
                        hasEnhancedLabels
                            ? "metadata__item-text weight--regular"
                            : "metadata__item-text"
                    }
                >
                    {p.ResponsibleUnits}
                    {delimiter}
                </span>
            </li>
        ) : null;
        const contentTypeLi = p.ContentType ? (
            <li className="metadata__item">
                <span
                    className={
                        hasEnhancedLabels
                            ? "metadata__item-text weight--regular"
                            : "metadata__item-text"
                    }
                >
                    {p.ContentType}
                    {delimiter}
                </span>
            </li>
        ) : null;

        const isPublishedToday = publishedMoment.isSame(Moment(), "day");
        const isChangedToday = changedMoment.isSame(Moment(), "day");
        const isOnlyPublished = changedMoment.isSame(publishedMoment);

        let publishedDateLi = isPublishedToday ? (
            <li className="metadata__item">
                <span
                    className={
                        hasEnhancedLabels
                            ? "metadata__item-label weight--bold"
                            : "metadata__item-label"
                    }
                >
                    {p.Translations.MetaDataPublished}
                </span>{" "}
                <span
                    className={
                        hasEnhancedLabels
                            ? "metadata__item-text weight--regular"
                            : "metadata__item-text"
                    }
                >
                    {p.Translations.MetaDataToday?.toLowerCase()}{" "}
                    {publishedMoment.format("HH.mm")}
                    {delimiter}
                </span>
            </li>
        ) : (
            <li className="metadata__item">
                <span
                    className={
                        hasEnhancedLabels
                            ? "metadata__item-label weight--bold"
                            : "metadata__item-label"
                    }
                >
                    {p.Translations.MetaDataPublished}
                </span>{" "}
                <span
                    className={
                        hasEnhancedLabels
                            ? "metadata__item-text weight--regular"
                            : "metadata__item-text"
                    }
                >
                    {publishedMoment.format("DD.MM.YY")}
                    {delimiter}
                </span>
            </li>
        );
        const changedDateLi = !isOnlyPublished ? (
            <li className="metadata__item">
                <span
                    className={
                        hasEnhancedLabels
                            ? "metadata__item-label weight--bold"
                            : "metadata__item-label"
                    }
                >
                    {p.Translations.MetaDataUpdated}
                </span>{" "}
                <span
                    className={
                        hasEnhancedLabels
                            ? "metadata__item-text weight--regular"
                            : "metadata__item-text"
                    }
                >
                    {isChangedToday
                        ? p.Translations.MetaDataToday?.toLowerCase() +
                          " " +
                          changedMoment.format("HH.mm")
                        : changedMoment.format("DD.MM.YY")}
                    {delimiter}
                </span>
            </li>
        ) : null;

        if (p.ShowPublishedOrChangedDate && !isOnlyPublished) {
            publishedDateLi = null;
        }

        return (
            <ul className={p.RootClass}>
                {p.ShowDate ? publishedDateLi : null}
                {p.ShowDate ? changedDateLi : null}
                {p.ShowResponsibleUnits ? responsibleUnitLi : null}
                {contentTypeLi}
            </ul>
        );
    }
}
