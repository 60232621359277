import idHelper from "../../02-tools/id-helper/idHelper";

export default class MainContent {
  constructor(element) {
    const h1s = element.getElementsByTagName("h1");
    const h2s = element.getElementsByTagName("h2");
    const h3s = element.getElementsByTagName("h3");
    const h4s = element.getElementsByTagName("h4");
    const h5s = element.getElementsByTagName("h5");
    const h6s = element.getElementsByTagName("h6");

    this.loopThrough(h1s);
    this.loopThrough(h2s);
    this.loopThrough(h3s);
    this.loopThrough(h4s);
    this.loopThrough(h5s);
    this.loopThrough(h6s);
  }

  loopThrough(headings) {
    for (const heading of headings) {
      if (!heading.innerHTML) {
        heading.remove();
      } else if (!heading.hasAttribute('id')) {
        heading.setAttribute('id', idHelper.createId(heading.textContent));
      }
    }
  }
}
