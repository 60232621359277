import React from 'react';
import FilterSorting from '../filter/filter-sorting.jsx';
import idHelper from '../../02-tools/id-helper/idHelper.js';

/*type Props = {
  Title: ?string;
  SortItems: Array<Object>;
  SortParam: String;
  Translations: Array<Object>;
};*/

export default class DocumentFilterResult extends React.Component {
  constructor(props) {
    super(props);
  }

  static defaultProps = {
    SelectedFilterItems: {}
  };

  findValidInterval = (start, stop) => {
    const startYear = start.substring(start.length, (start.length - 4));
    const startDate = start.substring(0, 5);
    const stopYear = stop.substring(stop.length, (stop.length - 4));

    if (start === stop) {
      return start;
    } else if (startYear === stopYear) {
      return `${startDate}–${stop}`;
    } else if (start && !stop) {
      return start;
    } else if (!start && stop) {
	    return stop;
    } else {
      return `${start}–${stop}`;
    }
  }

  render() {
    const p = this.props;
    let resultString = this.props.Translations.Results;
    resultString = resultString.replace('{0}', p.TotalItems);
    return (
      <div className="column xsmall-12">
	      <div className="row">
		      <div className="column xsmall-12 filter__hits-column">
			      <div className="filter__results-toolbar">
				      <h2 className="filter__hits" aria-hidden="true" id={idHelper.createId(this.props.Translations.Results)}>{resultString}</h2>
				      <FilterSorting Translations={this.props.Translations} SortItems={this.props.SortItems} SortParam={this.props.SortParam} updateParams={this.props.updateParams} />
			      </div>
		      </div>
	      </div>
	      <div className="row">
		      <div className="column xsmall-12 medium-10 large-8">
			      {p.Items.length ?
              <ol className="filter__results list--reset-style-type" aria-live="polite" aria-atomic="true">
                {p.Items.map((item, index) => (
                  <li className="page-listing__item" key={index}>
                    <article className="filter__article" role="text">
                      <h2 className="h3 page-listing__title" id={idHelper.createId(item.Title)}>
                        {(item.Url) ?
                          <span className={`page-listing__icon page-listing__icon--${item.Url.split('.').pop()}`}></span>
                          : null
                        }
                        <a className="page-listing__link" href={item.Url} lang={item.DocumentLanguage}>{item.Title.replace('.' + item.Url.split('.').pop(), '')}
													{(item.DocumentLanguage === 'nb') ? <span className="show-for-sr">{p.Translations.DocumentInfo.replace('{0}', item.Url.split('.').pop()).replace('{1}', p.Translations.DocLangNB.toLowerCase()).replace('{2}', item.FileSize.toUpperCase())}</span> : null}
													{(item.DocumentLanguage === 'nn') ? <span className="show-for-sr">{p.Translations.DocumentInfo.replace('{0}', item.Url.split('.').pop()).replace('{1}', p.Translations.DocLangNN.toLowerCase()).replace('{2}', item.FileSize.toUpperCase())}</span> : null}
													{(item.DocumentLanguage === 'en') ? <span className="show-for-sr">{p.Translations.DocumentInfo.replace('{0}', item.Url.split('.').pop()).replace('{1}', p.Translations.DocLangEN.toLowerCase()).replace('{2}', item.FileSize.toUpperCase())}</span> : null}
													{(item.DocumentLanguage === 'se') ? <span className="show-for-sr">{p.Translations.DocumentInfo.replace('{0}', item.Url.split('.').pop()).replace('{1}', p.Translations.DocLangSE.toLowerCase()).replace('{2}', item.FileSize.toUpperCase())}</span> : null}
                        </a>
                        {(item.DocumentLanguage === 'nb') ? <span className="page-listing__meta-data" aria-hidden="true"> – {p.Translations.DocLangNB} <span className="page-listing__filesize">({item.FileSize.toUpperCase()})</span></span> : null}
                        {(item.DocumentLanguage === 'nn') ? <span className="page-listing__meta-data" aria-hidden="true"> – {p.Translations.DocLangNN} <span className="page-listing__filesize">({item.FileSize.toUpperCase()})</span></span> : null}
                        {(item.DocumentLanguage === 'en') ? <span className="page-listing__meta-data" aria-hidden="true"> – {p.Translations.DocLangEN} <span className="page-listing__filesize">({item.FileSize.toUpperCase()})</span></span> : null}
                        {(item.DocumentLanguage === 'se') ? <span className="page-listing__meta-data" aria-hidden="true"> – {p.Translations.DocLangSE} <span className="page-listing__filesize">({item.FileSize.toUpperCase()})</span></span> : null}
                      </h2>
                      <ul className="metadata metadata--subtle list--reset-style">
                        {item.ValidStartDateFormated || item.ValidStopDateFormated ?
                        <li className="metadata__item">
                            <span className="metadata__item-label">{p.Translations.Valid}{item.ValidStartDateFormated && !item.ValidStopDateFormated ? ' ' + p.Translations.ValidFrom : null}{!item.ValidStartDateFormated && item.ValidStopDateFormated ? ' ' + p.Translations.ValidTo : null}</span> <span className="metadata__item-text">{this.findValidInterval(item.ValidStartDateFormated, item.ValidStopDateFormated, p.Translations)}</span>
                        </li>
                        : null}
                        {(item.ResponsibleUnitReference) ? (
                          <li className="metadata__item">
                            <span className="metadata__item-label">{p.Translations.Of}:</span> <span className="metadata__item-text">{item.ResponsibleUnitReference}</span>
                          </li>)
                          : null}
                        {(item.ContentType) ? (
                          <li className="metadata__item">
                            <span className="metadata__item-text">{item.ContentType}</span>
                          </li>)
                          : null}
                      </ul>
                      <p className="page-listing__excerpt">{item.MainIntro}</p>
                      {(item.Themes && item.Themes.length) ? <FilterResultTags Items={item.Themes} UserSelectedCategories={p.SelectedFilterItems.ListOfCategoryFilterItemLists} /> : null}
                    </article>
                  </li>
                ))}
              </ol>
            : null}
		      </div>
	      </div>
      </div>
    );
  }
}

class FilterResultTags extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const p = this.props;

    return (
      <ul className="tag-list list--reset-style-type">
        {p.Items.map((result, i) => {
          let tagIsSelected = false;

          if (p.UserSelectedCategories && p.UserSelectedCategories.length) {
            p.UserSelectedCategories[0].Items.map((item) => {
              if (item.Name === result) {
                tagIsSelected = true;
              }
            });
          }

          return (
            <li key={i} className={tagIsSelected ? 'tag-list__tag tag-list__tag--accented' : 'tag-list__tag'}>{result}</li>
          )
        })}
      </ul>
    )
  }
}
