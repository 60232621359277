import React from "react";
import XHR from "../../02-tools/xhr/xhr";
import Keyboard from "../../02-tools/keyboard/keyboard";
import Dictionary from "../../02-tools/dictionary/dictionary";
import Notification from "../notification/notification.jsx";
import { debounce } from 'lodash';


export default class Autocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRef: null,
            expanded: false,
            typedValue: '',
            flag: 0,
            dataId: "",
            error: false,
            errorMsg: "",
            results: []
        };

        // Debounce the call to handleInput change method
        this.debouncedInputChange = debounce(this.handleInputChange, 500);
    }

    onInputChange = (e) => {
        this.setState({
            selectedRef: this.refs.input,
            expanded: true,
            typedValue: this.refs.input.value,
            flag: 0,
            dataId: "",
            error: false,
            errorMsg: "",
        });

        // Call debounced version of input change handler
        this.debouncedInputChange(e.target.value);
    };

    handleInputChange = (searchTerm) => {
        if (searchTerm.length >= 2) { // Check if the search term is at least 3 characters
            XHR.get("/api/places/autocomplete/?searchTerm=", encodeURI(searchTerm))
                .then((data) => {
                    this.setState({
                        results: JSON.parse(data),
                    });
                })
                .catch((error) => {
                    console.log(error);
                    if (error.status === 429) {
                        this.setState({
                            error: true,
                            errorMsg: this.props.Translations.Errors.HttpStatus429,
                        });
                    }
                });
        } else {
            // Optionally, clear results if the term is less than 3 characters
            this.setState({ results: [] });
        }
    };

    onInputKeyDown = (e) => {
        if (e.which === Keyboard.up) {
            e.preventDefault();
            let el = this.refs.listItems.lastElementChild;
            this.setState({
                selectedRef: el,
                dataId: el.firstElementChild.dataset.identifier,
            });
            el.firstElementChild.focus();
            this.refs.input.value = el.firstElementChild.dataset.id;
        } else if (e.which === Keyboard.down) {
            e.preventDefault();
            let el = this.refs.listItems.firstElementChild;
            el.firstElementChild.focus();
            this.refs.input.value = el.firstElementChild.dataset.id;

            this.setState({
                selectedRef: el,
                dataId: el.firstElementChild.dataset.identifier,
                flag: 1,
            });
        }
    };

    onInputFocus = () => {
        this.setState({
            selectedRef: this.refs.input,
            expanded: true,
        });
    };

    onItemKeyDown = (e) => {
        if (e.which === Keyboard.up) {
            e.preventDefault();

            if (
                this.state.selectedRef === this.refs.listItems.firstElementChild
            ) {
                this.refs.input.focus();
                this.refs.input.value = this.state.typedValue;
                this.setState({
                    selectedRef: this.refs.input,
                    dataId: "",
                    flag: 0,
                });
            } else {
                let el = this.state.selectedRef.previousElementSibling;
                el.firstElementChild.focus();
                this.refs.input.value = el.firstElementChild.dataset.id;
                let flag = 0;

                if (el === this.refs.listItems.firstElementChild) {
                    flag = 1;
                }

                this.setState({
                    selectedRef: el,
                    dataId: el.firstElementChild.dataset.identifier,
                    flag: flag,
                });
            }
        } else if (e.which === Keyboard.down) {
            e.preventDefault();

            if (
                this.state.selectedRef === this.refs.listItems.lastElementChild
            ) {
                this.refs.input.focus();
                this.refs.input.value = this.state.typedValue;
                this.setState({
                    selectedRef: this.refs.input,
                    dataId: "",
                    flag: 0,
                });
            } else {
                let el = this.state.selectedRef.nextElementSibling;
                el.firstElementChild.focus();
                this.refs.input.value = el.firstElementChild.dataset.id;
                let flag = 0;

                if (el === this.refs.listItems.firstElementChild) {
                    flag = 1;
                }

                this.setState({
                    selectedRef: el,
                    dataId: el.firstElementChild.dataset.identifier,
                    flag: flag,
                });
            }
        }
    };

    onItemClick = (e) => {
        e.preventDefault();
        this.refs.input.value = e.target.dataset.id;
        let value = this.refs.input.value;
        let id = e.target.dataset.identifier;

        this.setState(
            {
                flag: 0,
                typedValue: value,
                dataId: e.target.dataset.identifier,
            },
            () => {
                this.onSubmit(e);
                this.setExpanded(false);
            },
        );
    };

    componentDidMount() {
        var autocomplete = this.refs.autocomplete;
        document.addEventListener("click", (e) => {
            if (autocomplete && !autocomplete.contains(e.target)) {
                this.setState({
                    expanded: false,
                });
            }
        });
    }

    setExpanded = (state) => {
        this.setState({
            expanded: state,
        });
    };

    onGpsClick = (e) => {
        e.preventDefault();
        this.refs.input.value = "";
        this.setState(
            {
                flag: 1,
                typedValue: "",
            },
            () => {
                this.setExpanded(false);
                this.props.onGpsClick();
            },
        );
    };

    onSubmit = (e) => {
        this.setExpanded(false);

        if (
            this.state.flag == 1 ||
            (this.state.flag == 0 && !this.refs.input.value)
        ) {
            this.onGpsClick(e);
        } else {
            e.preventDefault();
            let value = this.refs.input.value;
            let id = this.state.dataId;

            // Run dictionary
            Dictionary.lookup(this.refs.input.value)
                .then((data) => {
                    this.props.onSubmit(JSON.parse(data), id);
                    this.props.updateSearchText(value);
                })
                .catch((error) => {
                    this.props.onSubmit(value, id);
                    this.props.updateSearchText(value);
                });
        }
    };

    formatOutput = (str) => {
        const data = str.split("||");
        const suggestions = {
            place: data[0],
            municipality: data[1],
            county: data[2],
            type: data[3],
        };

        let output = suggestions.place;

        if (suggestions.municipality !== suggestions.place) {
            // We only want to show municipality if place is not municipality or county
            if (
                suggestions.place.indexOf("kommune") === -1 &&
                suggestions.place.indexOf("fylke") === -1
            ) {
                output += ", " + suggestions.municipality;
            }
        }

        if (suggestions.county !== suggestions.municipality) {
            // We only want to show county if place is not county
            if (suggestions.place.indexOf("fylke") === -1) {
                output += ", " + suggestions.county;
            }
        }

        return output.replace(/\, $/, "");
    };

    render() {
        const p = this.props;
        var hidden = false;
        let results = this.state.results ? this.state.results : [];
        const uniqueResults = [];
        const resultIdNumber = [];
        const resultIdStr = [];

        results.map((result, index) => {
            // Only push non-duplicates to the array. Check for both id and name duplicates
            if (
                resultIdNumber.indexOf(result.id) === -1 &&
                resultIdStr.indexOf(result.identifier) === -1
            ) {
                resultIdNumber.push(result.id);
                resultIdStr.push(result.identifier);
                uniqueResults.push(result);
            }
        });

        results = uniqueResults.slice(0, 5);

        return (
            <form
                className={`autocomplete${
                    hidden ? " autocomplete--hidden" : ""
                }`}
                ref="autocomplete"
            >
                <div className="autocomplete__inputgroup">
                    <div className="autocomplete__container">
                        {this.props.Error || this.state.error ? (
                            <Notification
                                Heading={
                                    this.props.Translations.Errors.ErrorHeading
                                }
                                Message={
                                    this.state.error
                                        ? this.state.errorMsg
                                        : this.props.Error
                                }
                                Type="error"
                                Size="small"
                                Inline="true"
                                Modifier="autocomplete__notification"
                            />
                        ) : null}
                        <input
                            type="text"
                            ref="input"
                            onFocus={this.onInputFocus}
                            onInput={this.onInputChange}
                            onKeyDown={this.onInputKeyDown}
                            className="autocomplete__input"
                            id="autocomplete__input"
                            aria-autocomplete="both"
                            aria-haspopup="true"
                            autoComplete="off"
                            aria-owns="autocomplete__results"
                            aria-label={this.props.Translations.Label}
                            data-value={this.state.typedValue}
                            placeholder={p.Translations.PlaceholderText}
                            data-testid="find-input"
                        />
                        {p.HasPositionOption ? (
                            <a
                                href="#"
                                onKeyDown={this.onItemKeyDown}
                                onClick={this.onGpsClick}
                                tabIndex="-1"
                                className={`autocomplete__your-position link--no-underline ${
                                    this.state.expanded
                                        ? "autocomplete__your-position--hidden"
                                        : ""
                                }`}
                                data-id={this.props.Translations.YourPosition}
                            >
                                {this.props.Translations.YourPosition}
                            </a>
                        ) : null}
                        <ol
                            role="listbox"
                            className={`autocomplete__list list--reset-style ${
                                !this.state.expanded
                                    ? "autocomplete__list--hidden"
                                    : ""
                            }`}
                            id="autocomplete__results"
                            ref="listItems"
                        >
                            {p.HasPositionOption ? (
                                <li
                                    role="option"
                                    className="autocomplete__list-item autocomplete__list-item--gps"
                                >
                                    <a
                                        href="#"
                                        onKeyDown={this.onItemKeyDown}
                                        onClick={this.onGpsClick}
                                        tabIndex="-1"
                                        className="link--no-underline"
                                        data-id={
                                            this.props.Translations.YourPosition
                                        }
                                        data-testid="find-your-position"
                                    >
                                        {this.props.Translations.YourPosition}
                                    </a>
                                </li>
                            ) : null}
                            {results.length
                                ? results.map((result, index) => (
                                      <li
                                          key={index}
                                          className="autocomplete__list-item"
                                          role="option"
                                      >
                                          <a
                                              href="#"
                                              onKeyDown={this.onItemKeyDown}
                                              onClick={this.onItemClick}
                                              className="link--no-underline"
                                              data-id={result.identifier}
                                              data-identifier={result.id}
                                              tabIndex="-1"
                                          >
                                              {this.formatOutput(
                                                  result.displayName,
                                              )}
                                          </a>
                                      </li>
                                  ))
                                : null}
                        </ol>
                        <span
                            className="autocomplete__live show-for-sr"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            {results.length + 1}{" "}
                            {this.props.Translations.AvailableResults}
                        </span>
                    </div>
                    <AutocompleteBtnGroup
                        setExpanded={this.setExpanded.bind(this)}
                        onSubmit={this.onSubmit}
                        Loading={this.props.Loading}
                        label={this.props.Translations.Submit}
                    />
                </div>
                {this.props.SearchTitle && !this.props.Error ? (
                    <p className="autocomplete__used-address-phrase">
                        {this.props.Translations.SearchTitle}{" "}
                        <span>
                        «{this.props.SearchTitle}»
                        </span>
                    </p>
                ) : null}
            </form>
        );
    }
}

class AutocompleteBtnGroup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="autocomplete__btngroup">
                <button
                    type="submit"
                    onClick={this.props.onSubmit}
                    className={`btn btn--icon autocomplete__search-btn ${
                        this.props.Loading &&
                        "autocomplete__search-btn--disabled"
                    }`}
                    aria-label={this.props.label}
                    disabled={this.props.Loading ? true : false}
                    onFocus={this.props.setExpanded.bind(this, false)}
                    data-testid="find-submit-btn"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        className="autocomplete__search-icon"
                    >
                        <g>
                            <path d="M2.182 9.024a6.85 6.85 0 0 1 6.842-6.842 6.85 6.85 0 0 1 6.842 6.842 6.85 6.85 0 0 1-6.842 6.843 6.85 6.85 0 0 1-6.842-6.843zM23.767 22.23l-7.644-7.644a8.978 8.978 0 0 0 1.925-5.56 9.024 9.024 0 1 0-9.024 9.024 8.971 8.971 0 0 0 5.555-1.923l7.645 7.645 1.543-1.542z" />
                        </g>
                    </svg>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 37 38"
                        className="autocomplete__process-icon"
                    >
                        <defs>
                            <path
                                id="a"
                                d="M9.744 2.046V.223H.482v1.823h9.262z"
                            />
                            <path id="c" d="M2.782.77H.955v9.25h1.827V.77z" />
                        </defs>
                        <g fill="none" fillRule="evenodd">
                            <g transform="translate(27.038 17.73)">
                                <mask id="b" fill="#fff">
                                    <use xlinkHref="#a" />
                                </mask>
                                <path
                                    fill="#E6E9F2"
                                    d="M1.394.223a.912.912 0 1 0 0 1.823h7.438a.913.913 0 0 0 .914-.912c0-.503-.41-.91-.914-.91H1.394z"
                                    mask="url(#b)"
                                />
                            </g>
                            <path
                                fill="#A1A8B5"
                                d="M.913 17.954a.912.912 0 1 0 0 1.823H8.35a.913.913 0 0 0 .913-.912c0-.503-.41-.91-.913-.91H.913z"
                            />
                            <path
                                fill="#EAEDF4"
                                d="M27.543 23.09a.912.912 0 1 0-.912 1.58l6.442 3.711a.912.912 0 1 0 .911-1.578l-6.44-3.714z"
                            />
                            <path
                                fill="#B0B6C2"
                                d="M3.711 9.35a.91.91 0 1 0-.913 1.578L9.24 14.64a.912.912 0 1 0 .914-1.578L3.71 9.35z"
                            />
                            <path
                                fill="#F1F2F8"
                                d="M24.203 27.093a.913.913 0 0 0-1.582.911l3.72 6.433a.914.914 0 0 0 1.581-.911l-3.719-6.433z"
                            />
                            <path
                                fill="#BFC4CE"
                                d="M10.443 3.294a.912.912 0 1 0-1.582.912l3.72 6.432a.912.912 0 0 0 1.582-.912l-3.72-6.432z"
                            />
                            <path
                                fill="#F6F8FC"
                                d="M19.305 28.892a.913.913 0 0 0-1.827 0v7.428a.913.913 0 0 0 1.827 0v-7.428z"
                            />
                            <g transform="translate(16.524 -.269)">
                                <mask id="d" fill="#fff">
                                    <use xlinkHref="#c" />
                                </mask>
                                <path
                                    fill="#C8CDD9"
                                    d="M2.782 1.68a.912.912 0 0 0-1.827 0v7.427a.913.913 0 0 0 1.827 0V1.681z"
                                    mask="url(#d)"
                                />
                            </g>
                            <path
                                fill="#FFF"
                                d="M14.162 28.005a.913.913 0 0 0-1.582-.91l-3.718 6.431a.91.91 0 0 0 .334 1.245.915.915 0 0 0 1.247-.334l3.719-6.432z"
                            />
                            <path
                                fill="#D1D6E1"
                                d="M27.922 4.206a.912.912 0 1 0-1.582-.912l-3.718 6.432a.913.913 0 0 0 1.58.912l3.72-6.432z"
                            />
                            <path
                                fill="#959DAE"
                                d="M10.153 24.668a.911.911 0 1 0-.914-1.579L2.8 26.803a.912.912 0 0 0 .913 1.58l6.44-3.715z"
                            />
                            <path
                                fill="#DCDFE8"
                                d="M33.984 10.928a.912.912 0 0 0-.912-1.578l-6.442 3.712a.911.911 0 1 0 .913 1.58l6.441-3.714z"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        );
    }
}
