import React from 'react';
import PropTypes from 'prop-types';
import Filter from '../filter/filter.jsx';
import VVRFilterResult from './vvr-filter-result.jsx';
import VVRTransformJson from './vvr-transform-json.js';

export default class VVRFilter extends React.Component {
    constructor(props) {
        super(props);

        const data = JSON.parse(props.Data);
        this.transformedJson = VVRTransformJson(data, props.DistrictsParam, props.ServiceAreasParam, props.Translations.Labels);
    }

    render() {
        return (
            <Filter
                FilterItems={this.transformedJson.VVRFilters}
                API={this.props.API}
                SelectedFilterItems={this.transformedJson.SelectedFilterItems}
                FilterResult={this.transformedJson.filterResult}
                PaginationFilterItem={this.transformedJson.filterPagination}
                ServiceAreasParam={this.props.ServiceAreasParam}
                DistrictsParam={this.props.DistrictsParam}
                Translations={this.props.Translations}
                IsVVR={true}>
                <VVRFilterResult Translations={this.props.Translations} SortItems={JSON.parse(this.props.SortItems)} SortParam={this.props.SortParam} updateParams={this.props.updateParams} />
            </Filter>
        );
    }
}


VVRFilter.propTypes = {
    API: PropTypes.string,
    SortItems: PropTypes.array,
    SortParam: PropTypes.string,
    ServiceAreasParam: PropTypes.string,
    DistrictsParam: PropTypes.string,
    Translations: PropTypes.array,
    Data: PropTypes.array
};
