import React from 'react';
import Params from '../../02-tools/params/params';
import Keyboard from '../../02-tools/keyboard/keyboard';

/*type Props = {
  Label: ?string;
  CategoryFilterItems: Array<Object>;
  Translations: Array<Object>;
};*/

export default class FilterPanelDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Params: Params.getParams()
    }
  }

  dateChange = (element) => {
    this.state.Params = Params.getParams();
    this.state.Params[element.name] = Params.addParam(element.value);

    this.props.updateParams(this.state.Params)
  }

  componentWillReceiveProps(nextProps) {
    this.state.Params = Params.getParams();
  }

  dateReset = () => {
    this.state.Params.fradato = '';
    this.state.Params.tildato = '';
    this.setState({
      Params: {
        fradato: '',
        tildato: ''
      }
    });
    this.props.updateParams(this.state.Params);
  }

  render() {
    const p = this.props;

    return (
      <div className={`filter__panel${p.selected ? '' : ' filter__panel--hidden'}`}>
        <div className="xsmall-12 column" id={`${p.Label}Panel`} role="tabpanel" aria-labelledby={`${p.Label}Tab ${p.Label}TabMobile`} aria-hidden={!this.props.selected}>
          <ul className="filter__item-choices filter__item-choices--sectioned list--reset-style-type row">
            <li className="xsmall-12 medium-9 large-8 xlarge-7 xxlarge-6 column">
              <ReactPikaday Label={p.Translations.FromDate} Id="fradato" onChange={this.dateChange} value={this.state.Params.fradato} Translations={p.Translations} />
              <ReactPikaday Label={p.Translations.ToDate} Id="tildato" onChange={this.dateChange} value={this.state.Params.tildato} Translations={p.Translations} />
            </li>
            <ResetButton Label={p.Translations.ResetDate} FraDato={this.state.Params.fradato} TilDato={this.state.Params.tildato} dateReset={this.dateReset} />
          </ul>
          <div className="filter__btn-container">
            <button className="btn btn--positive btn--small" onClick={p.CloseTabs}>{p.Translations.Close}</button>
          </div>
        </div>
      </div>
    );
  }
}

class ResetButton extends React.Component {
  constructor(props) {
    super(props);

    this.Show = false;
  }

  componentWillReceiveProps(nextProps) {
    this.Show = (nextProps.FraDato || nextProps.TilDato);
  }

  render() {
    if (this.Show) {
      return (
        <li className="xsmall-12 medium-3 large-4 xlarge-5 xxlarge-6 column filter__date-btn-container">
          <button className="filter__reset-date-btn" onClick={this.props.dateReset}>{this.props.Label}</button>
        </li>
      );
    }
    return null;
  }
}

class ReactPikaday extends React.Component {
  constructor(props) {
    super(props);

    this.Pikaday;

    if (typeof window === 'object') {
      this.Pikaday = require('pikaday');
    }
  }

  onChange = () => {
    var element = this.refs.pikaday;
    this.props.onChange(element);
  }

  handlePickerKeydown(e) {
    if (e.type === 'keydown') {
	    e.preventDefault();
    }
	}

  componentDidMount = () => {
    var element = this.refs.pikaday;
	  const p = this.props;

    this._picker = new this.Pikaday({
      field: element,
      format: 'DD.MM.YYYY',
      onClose: this.onChange,
      firstDay: 1,
      yearRange: 5,
      blurFieldOnSelect: false,
      maxDate: new Date(),
      i18n: {
	      previousMonth: p.Translations.PrevLabel,
        nextMonth: p.Translations.NextLabel,
        months: [p.Translations.Months.Jan, p.Translations.Months.Feb, p.Translations.Months.Mar, p.Translations.Months.Apr, p.Translations.Months.May, p.Translations.Months.Jun, p.Translations.Months.Jul, p.Translations.Months.Aug, p.Translations.Months.Sep, p.Translations.Months.Oct, p.Translations.Months.Nov, p.Translations.Months.Dec],
        weekdays: [p.Translations.Days.Long.Su, p.Translations.Days.Long.Mo, p.Translations.Days.Long.Tu, p.Translations.Days.Long.We, p.Translations.Days.Long.Th, p.Translations.Days.Long.Fr, p.Translations.Days.Long.Sa],
        weekdaysShort: [p.Translations.Days.Short.Su, p.Translations.Days.Short.Mo, p.Translations.Days.Short.Tu, p.Translations.Days.Short.We, p.Translations.Days.Short.Th, p.Translations.Days.Short.Fr, p.Translations.Days.Short.Sa]
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    var element = this.refs.pikaday;
    element.value = nextProps.value || '';
  }

  render() {
    const p = this.props;

    return (
      <label className="filter__date-label form__label form__label--datetime-date" htmlFor={p.Id}>
        <span className="form__label-text">{p.Label}:</span>
        <input
          type="text"
          className="form__date form__date--datetime"
          id={p.Id}
          name={p.Id}
          readOnly
          placeholder="dd.mm.åååå"
          aria-label="Use the arrow keys to pick a date"
          ref="pikaday"
          onKeyDown={this.handlePickerKeydown} />
      </label>
    )
  }
}