import React from 'react';
import FormInputText from './form-field-types/form-input-text.jsx';
import FormInputRadio from './form-field-types/form-input-radio.jsx';
import FormInputCheckbox from './form-field-types/form-input-checkbox.jsx';
import FormInputSelect from './form-field-types/form-input-select.jsx';
import FormInputHidden from './form-field-types/form-input-hidden.jsx';
import FormSummary from './form-summary.jsx';
import FormContextInfo from './form-context-info.jsx';
import Notification from '../../05-blocks/notification/notification.jsx';
import { isValid } from './form-field-validation.js';

export default class FormField extends React.Component {
    constructor(props) {
        super(props);

        let errorsInfo = props.Errors && props.Errors.length > 0 ? props.Errors.filter(val => val.name === props.FormFieldData.name) : null;
        let hasError = errorsInfo && errorsInfo.length > 0 ? errorsInfo[0].hasError : false;
        let errorMsg = errorsInfo && errorsInfo.length > 0 ? errorsInfo[0].errorMessage : '';

        this.state = {
            hasError: hasError,
            errorMessage: errorMsg,
            value: this.props.FormFieldData.value
        }
    }

    componentWillUnmount() {
        this.props.removeFieldError(this.props.FormFieldData.name);
    }

    // On change of the list of props.Errors, update the state.hasError and state.errorMsg
    static getDerivedStateFromProps(props, state) {
        let errorsInfos = [];
        if (props.Errors && props.Errors.length > 0) {
            errorsInfos = props.Errors.filter(val => val.name === props.FormFieldData.name);

            if (errorsInfos.length > 0 && errorsInfos[0].hasError !== state.hasError) {
                return {
                    hasError: errorsInfos[0].hasError,
                    errorMessage: errorsInfos[0].errorMessage
                };
            }
        }

        return null;
    }

    validateField = (e) => {
        let validationObj = isValid(e.target);
        let hasError = !validationObj.valid;

        this.setState({
            hasError: hasError,
            errorMessage: validationObj.valid ? null : this.props.setErrorMessage(validationObj.errorType)
        });

        !hasError && this.props.removeFieldError(e.target.name);
    }

    resetError = (isReset) => {
        this.setState({
            hasError: !isReset,
            errorMessage: isReset ? '' : this.state.errorMessage
        })
    }

    contentRouter = (type, data, value, input, steps, activeStep, navigateToStep, toggleNavigation, handleChange, stepIndex, hasError, errorMsg, setFormErrors, setErrorMessage, errors) => {
        let userInputValue = '';
        input[stepIndex - 1].map((input) => {
            if (input.name === data.name) {
                userInputValue = input.value;
            }
        });

        switch (type) {
            case 'email':
            case 'tel':
            case 'text':
                return <FormInputText
                    Hint={data.hint}
                    ImportantInfo={data.importantInformation}
                    Info={data.information}
                    InfoTrigger={data.informationTrigger}
                    Label={data.label}
                    HideLabel={data.hideLabel}
                    Name={data.name}
                    Placeholder={data.placeholder}
                    Required={data.required}
                    ReadOnly={data.readOnly}
                    Type={type}
                    Validation={data.validation}
                    Value={value}
                    UserInputValue={userInputValue}
                    FormDataInput={input}
                    FormSteps={steps}
                    ActiveStep={activeStep}
                    StepIndex={stepIndex}
                    navigateToStep={navigateToStep}
                    HasError={hasError}
                    ErrorMessage={errorMsg}
                    ValidateField={(e) => this.validateField(e)}
                    OnChange={handleChange}
                    ResetError={(e) => this.resetError(e)}
                    Errors={errors}
                    Translations={this.props.Translations}
                />;
            case 'hidden':
                return <FormInputHidden
                    Name={data.name}
                    Type={type}
                    Value={data.value}
                    Translations={this.props.Translations}
                />;
            case 'radio':
                return <FormInputRadio
                    Hint={data.hint}
                    ImportantInfo={data.importantInformation}
                    Info={data.information}
                    InfoTrigger={data.informationTrigger}
                    Label={data.label}
                    Name={data.name}
                    Options={data.options}
                    Placeholder={data.placeholder}
                    Required={data.required}
                    ReadOnly={data.readOnly}
                    Type={type}
                    Value={value}
                    UserInputValue={userInputValue}
                    FormDataInput={input}
                    FormSteps={steps}
                    ActiveStep={activeStep}
                    StepIndex={stepIndex}
                    navigateToStep={navigateToStep}
                    OnChange={handleChange}
                    ValidateField={(e) => this.validateField(e)}
                    HasError={hasError}
                    ErrorMessage={errorMsg}
                    ResetError={(e) => this.resetError(e)}
                    setFormErrors={setFormErrors}
                    setErrorMessage={setErrorMessage}
                    toggleNavigation={toggleNavigation}
                    Errors={errors}
                    Translations={this.props.Translations}
                    removeFieldError={this.props.removeFieldError}
                />;
            case 'checkbox':
                return <FormInputCheckbox
                    Hint={data.hint}
                    ImportantInfo={data.importantInformation}
                    Info={data.information}
                    InfoTrigger={data.informationTrigger}
                    Label={data.label}
                    Name={data.name}
                    Options={data.options}
                    Placeholder={data.placeholder}
                    Required={data.required}
                    ReadOnly={data.readOnly}
                    Value={value}
                    UserInputValue={userInputValue}
                    HasError={hasError}
                    ErrorMessage={errorMsg}
                    ValidateField={(e) => this.validateField(e)}
                    ResetError={(e) => this.resetError(e)}
                    OnChange={handleChange}
                    Errors={errors}
                    Translations={this.props.Translations}
                />;
            case 'select':
                return <FormInputSelect
                    Hint={data.hint}
                    ImportantInfo={data.importantInformation}
                    Info={data.information}
                    InfoTrigger={data.informationTrigger}
                    Label={data.label}
                    Name={data.name}
                    Options={data.options}
                    Placeholder={data.placeholder}
                    Required={data.required}
                    ReadOnly={data.readOnly}
                    Type={type}
                    Value={value}
                    UserInputValue={userInputValue}
                    FormDataInput={input}
                    FormSteps={steps}
                    ActiveStep={activeStep}
                    StepIndex={stepIndex}
                    navigateToStep={navigateToStep}
                    HasError={hasError}
                    ErrorMessage={errorMsg}
                    OnChange={handleChange}
                    toggleNavigation={toggleNavigation}
                    ValidateField={(e) => this.validateField(e)}
                    setFormErrors={setFormErrors}
                    setErrorMessage={setErrorMessage}
                    ResetError={(e) => this.resetError(e)}
                    Errors={errors}
                    Translations={this.props.Translations}
                    removeFieldError={this.props.removeFieldError}
                />;
            case 'importantInformation':
                return <Notification
                    Type="inverted"
                    Message={data.importantInformation}
                    Modifier={`notification__form--${data.layoutPosition}`}
                    Icon="info" />;
            case 'importantInformationSubtle':
                return <FormContextInfo
                    Message={data.importantInformation}
                    Position={data.layoutPosition} />;
            case 'summary':
                return <FormSummary
                    FormDataInput={input}
                    FormSteps={steps}
                    ActiveStep={activeStep}
                    StepIndex={stepIndex}
                    navigateToStep={navigateToStep}
                    Translations={this.props.Translations}
                />;
            default:
                console.log('Missing: ' + type);
                return null;
        }
    }

    render() {
        let errors = [];
        if (this.props.Errors && this.props.Errors.length > 0) {
            errors = this.props.Errors.filter(el => {
                return this.props.FormDataInput[this.props.ActiveStep - 1].find(element => {
                    return element.name === el.name;
                });
            });
        }

        return (
            <>{this.contentRouter(this.props.FormFieldData.type, this.props.FormFieldData, this.state.value, this.props.FormDataInput, this.props.FormSteps, this.props.ActiveStep, this.props.navigateToStep, this.props.toggleNavigation, this.props.handleChange, this.props.StepIndex, this.state.hasError, this.state.errorMessage, this.props.setFormErrors, this.props.setErrorMessage, errors)}</>
        );
    }
}
