export default class DataTable {
  constructor(element) {
    const cells = element.querySelectorAll('td, th');
    const headerCells = element.querySelectorAll('thead th');
    let headerCellsContent = [];

    for (const cell of headerCells) {
      headerCellsContent.push(cell.innerText);
    }

    for (const cell of cells) {
      cell.innerHTML = `<span class="data-table__cell-data">${cell.innerHTML}</span>`;

      if (cell.tagName.toUpperCase() === 'TD' && headerCellsContent[cell.cellIndex]) {
        cell.setAttribute('data-table-header', headerCellsContent[cell.cellIndex]);
      }
      }
      if (element.hasAttribute("style")) {
          let tableHeight = 0;
          if (element.style.height) {
              tableHeight = element.style.height,
              element.style.removeProperty('height'),
              element.style.minHeight = tableHeight
          }
      }


      element.classList.add('data-table--responsive');

  }
}
