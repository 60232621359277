import React from 'react';
import idHelper from '../../02-tools/id-helper/idHelper';

/*type Props = {
  Label: ?string;
  Items: Array<Object>;
  Loading: Boolean;
};*/

export default class FilterPanelCategory extends React.Component {
  constructor(props) {
    super(props);
  }

  check = (e) => {
    let indexOfItem = this.props.Items.findIndex(i => i.Id == e.target.value);
    if (indexOfItem >= 0) {
      this.props.Items[indexOfItem].Selected = !(this.props.Items[indexOfItem].Selected);
    }

    this.props.filter(e.target.name, e.target.value, e.target.dataset.param, e.target.checked);
  }

  render() {
    const p = this.props;
    const groupSize = 15;
    const sectionHeadingThreshold = 5;
    let sectionLetters = [];
    let sectionHeading = '';

    // Sort array alphabetically
    p.Items.sort((a, b) => {
      const nameA = a.Name;
      const nameB = b.Name;

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });

    return (
      <div className={`filter__panel${p.selected ? '' : ' filter__panel--hidden'}`}>
        <div className="xsmall-12 medium-12 column" id={`${p.Label}Panel`} role="tabpanel" aria-labelledby={`${p.Label}Tab ${p.Label}TabMobile`} aria-hidden={!this.props.selected}>
          {(p.Items && p.Items.length) ? p.Items.map((result, i) => {
            // First item in group
            if (i % groupSize === 0) {
              sectionLetters.push([result.Name.substring(0, 1)]);
            }

            // Last item in group
            if ((i % groupSize + 1) === groupSize || i === p.Items.length - 1) {
              sectionLetters[sectionLetters.length - 1].push(result.Name.substring(0, 1))
            }

            return (
              <li className="xsmall-12 medium-4 column" key={result.Id} role="text">
                <label className="form__label" aria-hidden={result.NumberOfItems === 0 ? true : false}>
                  <input type="checkbox" onChange={this.check.bind(this)} className="form__checkbox form__checkbox--filter" name={p.Label} value={result.Id} data-param={result.Name} id={`Filter-${p.Label}-${result.Id}`} checked={result.Selected ? 'checked' : ''} disabled={result.NumberOfItems === 0 || p.Loading ? 'disabled' : ''} />
                  <span className="form__label-text form__label--offset">{result.Name} (<span aria-label={p.Translations.NumberOfItemsPrefix + ' ' + result.NumberOfItems + ' ' + p.Translations.NumberOfItemsPostfix}>{result.NumberOfItems}</span>)</span>
                </label>
              </li>
            )
          }).reduce((items, item, i) => {
            if (i % groupSize === 0) {
              // Create a new sub array if first in group'
              items.push([]);
            }

            // Push current item to current groups sub array
            items[items.length - 1].push(item);

            return items;
            }, []).map((result, i) => {

              // Only render section heading if number of item exceed threshold
              if (p.Items.length > sectionHeadingThreshold) {
                let sectionHeadingContent = sectionLetters[i][0] === sectionLetters[i][1] ? sectionLetters[i][0] : sectionLetters[i][0] + '–' + sectionLetters[i][1];

                sectionHeading = <h2 className="filter__item-choices-heading" id={idHelper.createId(sectionHeadingContent)}>{sectionHeadingContent}</h2>
              }

              return (
                <section key={i} className="filter__choices-section">
                  {sectionHeading}
                  <ul className="filter__item-choices list--reset-style-type row">{result}</ul>
                </section>
              )
            }) : <h2 className="filter__panel-message" id={idHelper.createId(p.Translations.Errors.NoFilters)}>{p.Translations.Errors.NoFilters}</h2>}
          <div className="filter__btn-container">
            <button className="btn btn--positive btn--small right" onClick={p.CloseTabs}>{p.Translations.Close}</button>
          </div>
        </div>
      </div>
    );
  }
}
