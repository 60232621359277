import Moment from 'moment';

export const transformData = (data) => {
    let transformedData = {};

    for (var key in data) {
        if (key === 'postcodelocation' && data[key].length > 4) {
            let postcode = data[key].substring(0, 4);
            let postlocation = data[key].substring(5);

            transformedData['postcode'] = postcode;
            transformedData['postlocation'] = postlocation;
        } else if (key === 'alarm-agreement-number' && data[key] === '') {
            transformedData[key] = 'Ettersendes';
        } else {
            transformedData[key] = data[key];
        }
    }

    if (transformedData['weaponry']) {
        if (transformedData['weaponry-serial'] || transformedData['weaponry-serial'] === '') {
            transformedData['weaponry-serial'] = transformedData['weaponry-serial'] === '' ? 'Ettersendes' : transformedData['weaponry-serial'];
        } else {
            transformedData['weaponry-serial'] = 'Ettersendes';
        }
    }

    let submitTimestamp = Moment();
    transformedData['timestamp-iso8601'] = submitTimestamp.toISOString(true);
    transformedData['referenceid'] = 'VAPENSOKNAD-' + transformedData['form-type'].toUpperCase() + '-' + submitTimestamp.format('YYYYMMDDHHmmssSSS');

    return transformedData;
}