import React from 'react';

export default class ContactPoints extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <ul className='contact-points'>
        <li className='contact-points__item'>
          <a
            className='contact-points__link'
            data-gtm-component='header-emergency-phone'
            href={this.props.emergencyHref}
          >
            {this.props.emergencyLabel}{' '}
            <strong>{this.props.emergencyNumber}</strong>
          </a>
          <span className='contact-points__separator' role='presentation'></span>
        </li>
        <li className='contact-points__item'>
          <a
            className='contact-points__link'
            data-gtm-component='header-phone'
            href={this.props.phoneHref}
          >
            {this.props.phoneLabel}{' '}
            <strong>{this.props.phoneNumber}</strong>
          </a>
        </li>
        <li className='contact-points__item'>
          <a
            className='contact-points__link'
            data-gtm-component='header-find-police'
            href={this.props.findPoliceHref}
          >
            {this.props.findPoliceLabel}
            <svg className="contact-points__arrow" viewBox="0 0 24 24" aria-hidden={true}>
              <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
            </svg>
          </a>
        </li>
      </ul>
    );
  }
}
