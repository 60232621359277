import Insight from '../../02-tools/insight/insight';

export default function PrintInsight() {
  if (window.matchMedia) {
    const mediaQueryList = window.matchMedia('print');

    mediaQueryList.addListener((mql) => {
      if (!mql.matches) {
        Insight.track('gtm', { 'event': 'ÅpnePrintDialog', 'category': 'Print', 'data': location.pathname });
      }
    });
  }
}