import React from 'react';
import Keyboard from '../../02-tools/keyboard/keyboard';
import idHelper from '../../02-tools/id-helper/idHelper';

export default class FormCancelModal extends React.Component {
	constructor(props) {
        super(props);
    }

    execCancel = (e, url) => {
	    e.preventDefault();
	    window.location.href = url;
    }

    closeModal = (e) => {
        e.preventDefault();
        let modal = document.getElementById('modal-2');
	    let modalTrigger = document.querySelector('.js-modal-2-trigger');
        if (modal) {
	        modal.classList.remove('modal-2--open');
	        modal.setAttribute('aria-hidden', true);
            modal.setAttribute('aria-expanded', false);

            if (modalTrigger) {
	            modalTrigger.focus();
            }
        }
    }

    componentDidMount() {
        let modal = document.getElementById('modal-2');
        if (modal) {
		    document.addEventListener('keyup', (e) => {
			    const evt = e || window.event;
			    if (evt.which === Keyboard.esc && modal.classList.contains('modal-2--open')) {
				    this.closeModal(evt);
			    }
		    });
        }
    }

    render() {
        return (
            <div className="row align-center align-middle modal-2__row">
	            <div className="xsmall-12 medium-8 large-6 xlarge-5 column modal-2__content" tabIndex="-1">
		            <section className="notification notification--alert" role="alert">
                        <h2 className="notification__heading" id={idHelper.createId(this.props.Title)}>{this.props.Title}</h2>
			            <div className="notification__message">
                            <span dangerouslySetInnerHTML={{ __html: this.props.BodyText }}></span>
                            <div className="notification__btn-row">
	                            <button className="btn btn--subtle btn--stackable" onClick={(e) => this.execCancel(e, this.props.CancelUrl)}>
		                            <span className="btn--link__link-text">{this.props.Translations.CancelModalBtnCancel}</span>
	                            </button>
	                            <button className="btn--subtle btn--link btn--stackable" onClick={(e) => this.closeModal(e)}>
		                            <span className="btn--link__link-text">{this.props.Translations.CancelModalBtnContinue}</span>
	                            </button>
                            </div>
			            </div>
		            </section>
	            </div>
            </div>
		);
	}
}
