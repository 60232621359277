import React from "react";
import MetaData from "../metadata/metadata.jsx";

export default class NewsItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <li className="column list--item-flex spacing--no-margin">
                <a
                    href={this.props.item.Url}
                    className={`card${
                        this.props.item.Type === "news"
                            ? ""
                            : " card--extraordinary"
                    }`}
                    data-gtm-hook="news-list-home-link"
                >
                    <h3 className="h2 card__headline">
                        {this.props.item.Heading}
                    </h3>

                    <MetaData
                        PublishedDate={this.props.item.Metadata.PublishedDated}
                        ChangedDate={this.props.item.Metadata.ChangedDate}
                        ContentType={this.props.item.Metadata.ContentType}
                        EnhancedLabels={this.props.item.Metadata.EnhancedLabels}
                        ShowResponsibleUnits={
                            this.props.item.Metadata.ShowResponsibleUnits
                        }
                        ShowPublishedOrChangedDate={
                            this.props.item.Metadata.ShowPublishedOrChangedDate
                        }
                        ShowDate={this.props.item.Metadata.ShowDate}
                        ResponsibleUnits={
                            this.props.item.Metadata.ResponsibleUnits
                        }
                        RootClass={this.props.item.Metadata.RootClass}
                        Translations={this.props.item.Metadata.Translations}
                    />

                    <p className="card__paragraph card__clamped">
                        {this.props.item.Summary}
                    </p>
                </a>
            </li>
        );
    }
}
