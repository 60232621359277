import React from 'react';

export default class ErrorMessage extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<span className={this.props.stylename}>
				{this.props.errorMessage}
			</span>
		);
	}
}