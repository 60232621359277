import Keyboard from '../../02-tools/keyboard/keyboard';

export default class Header {
  constructor(element, className) {
    const menuBtn = element.querySelector('.js-menu-btn');
    const searchBtn = element.querySelector('.js-search-btn');
    const menu = document.querySelector('.js-menu');
    const menuContentWrapper = document.querySelector('.site-navigation__content-wrapper');

    if (menuBtn && menu) {

      menuBtn.addEventListener('click', () => {
        element.classList.toggle('header--menu-open');
        menuBtn.classList.toggle('header__btn--menu-open');
        menu.classList.toggle('site-navigation--open');
        if (menuBtn.classList.contains('header__btn--menu-open')) {
          menuBtn.setAttribute('aria-expanded', true);
          menu.setAttribute('aria-expanded', true);
          menu.setAttribute('aria-hidden', false);
        } else {
          menuBtn.setAttribute('aria-expanded', false);
          menu.setAttribute('aria-expanded', false);
          menu.setAttribute('aria-hidden', true);
        }
        if (menuBtn.getAttribute('data-gtm-component') === 'menu') {
          Insight.track('matomo', {
            isEvent: true,
            category: 'Rammeverk',
            action: 'Hamburgermeny - Åpne/Lukke',
            name: location.pathname,
            value: 0,
          });
        }
      });
    }

    searchBtn.addEventListener('click', () => {
      const input = document.querySelector('.js-global-search-input[data-global="true"]');
      setTimeout(() => { input.focus(); }, 50);
    });

    // Keyboard Esc closes menu
    document.addEventListener('keyup', (e) => {
      const evt = e || window.event;
      if (menuContentWrapper.offsetHeight) {
        if (evt.which === Keyboard.esc) {
          menuBtn.click();
          menuBtn.focus();
        }
      }
    });
  }
}
