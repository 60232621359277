import XHR from '../../02-tools/xhr/xhr';

const lang = document.documentElement.lang;

class GlobalText {
  get() {
    return new Promise((resolve, reject) => {
      if (document.documentElement.lang) {
        XHR.get('/api/localization/script/', document.documentElement.lang).then((response) => {
          resolve(JSON.parse(response));
        }).catch((error) => {
          reject(error);
        });
      } else {
        reject();
      }
    });
  }
}

export default new GlobalText();
