import React from "react";
import idHelper from "../../02-tools/id-helper/idHelper.js";

/*type Props = {
	Translations: Array<Object>;
};*/

export default class EventActionEntries extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const p = this.props;
        let entries = [];

        if (p.NextOfKin) {
            entries.push(
                <li className={"column action-entry__item"} key="nextofkin">
                    <a
                        href={"tel:" + p.NextOfKin}
                        className="action-entry__link link--no-underline"
                    >
                        <h2
                            className="h5 action-entry__label"
                            id={idHelper.createId(p.Translations.NextOfKin)}
                        >
                            {p.Translations.NextOfKin}
                        </h2>
                        <p className="h2 action-entry__title">{p.NextOfKin}</p>
                    </a>
                </li>
            );
        }

        if (p.Entries) {
            p.Entries.map((entry, index) => {
                entries.push(
                    <li
                        className={
                            entry.phone
                                ? "column action-entry__item"
                                : "column action-entry__item action-entry__item--link"
                        }
                        key={index}
                    >
                        <a
                            href={
                                entry.phone
                                    ? "tel:" + entry.phone.replace(/\s/g, "")
                                    : entry.link
                            }
                            className="action-entry__link link--no-underline"
                        >
                            {entry.title ? (
                                <h2 className="h5 action-entry__label">
                                    {entry.title}
                                </h2>
                            ) : null}
                            <p className="h2 action-entry__title">
                                {entry.phone ? entry.phone : entry.linkTitle}
                            </p>
                        </a>
                    </li>
                );
            });
        }

        return (
            <ul className="row large-up-3 medium-up-1 small-up-1 list--reset-style-type action-entry action-entry--event">
                {entries}
            </ul>
        );
    }
}
