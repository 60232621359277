import React from "react";
import NewsItem from "./news-item.jsx";
import EventItem from "./event-item.jsx";

export default class NewsList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { NewsArchiveLink, NewsArchiveLinkTitle, NewsList, NewsTitle } =
            this.props;

        return (
            <section
                className="block main-content__content-item"
                data-gtm-component="news-list-home"
            >
                <div className="row">
                    <div className="xsmall-12 medium-6 column">
                        <h2 className="h3 block__title block__title--news">
                            {NewsTitle}
                        </h2>
                    </div>
                </div>
                <ul className="row xlarge-up-3 medium-up-2 small-up-1 list--reset-style-type spacing--no-btm-margin">
                    {NewsList.map((item, index) =>
                        item.Type === "occurance" ? (
                            <EventItem key={"news-item-" + index} item={item} />
                        ) : (
                            <NewsItem key={"news-item-" + index} item={item} />
                        ),
                    )}
                </ul>
                <div className="row">
                    <div className="xsmall-12 column block__read-more-column">
                        <a
                            className="block__archive-link"
                            href={NewsArchiveLink}
                            data-gtm-hook="news-list-home-more"
                        >
                            {NewsArchiveLinkTitle}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M12 20L10.575 18.6L16.175 13H4V11H16.175L10.575 5.4L12 4L20 12L12 20Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}
