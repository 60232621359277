import React, { useState, useEffect, useRef } from "react";
import GlobalSearch from "../global-search/global-search.jsx";
import SiteNavigation from "../site-navigation/site-navigation.jsx";
import {
    getApiCode,
    isValidApiRoot,
    isValidLanguage,
    getUrlRoot,
    getHash,
} from "../../02-tools/utils/utils.js";
import { Modal } from "@politiet/pds";
import Insight from "../../02-tools/insight/insight.js";
import { Notification as PdsNotification } from "@politiet/pds";
import DOMPurify from "dompurify";
import PolitietLogo from "./politiet-logo.jsx";

const Header = ({ userlang, apiroot }) => {
    const searchButtonRef = useRef(null);

    const [userLanguage, setUserLanguage] = useState(null);
    const [apiRoot, setApiRoot] = useState(null);
    const [environment, setEnvironment] = useState(null);

    const [htmlElement, setHtmlElement] = useState(null);
    const [headerContent, setHeaderContent] = useState(null);
    const [headerMessageIsDismissed, setHeaderMessageIsDismissed] =
        useState(true);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        setHtmlElement(document.querySelector("html"));
    }, []);

    useEffect(() => {
        setUserLanguage(userlang);
        setApiRoot(apiroot);
    }, [userlang, apiroot]);

    useEffect(() => {
        if (!apiRoot) return;
        if (!userLanguage) return;
        setEnvironment(isValidApiRoot(apiRoot) ? apiRoot : "production");

        if (!environment) return;
        fetchData();
    }, [userLanguage, apiRoot, environment]);

    const logoClickHandler = (event) => {
        event.preventDefault();
        const linkUrl = event.target.href
            ? event.target.href
            : event.target.closest("a").href;

        Insight.track("matomo", {
            isEvent: true,
            category: "Navigasjon",
            action: "Klikk logo i header",
            name: `${document.title} (${location.pathname})`,
            value: 0,
        });

        location.href = linkUrl;
    };

    const openSearchModal = () => {
        if (htmlElement) {
            htmlElement.classList.add("html--modal-open");
        }

        setShowSearchModal(true);

        Insight.track("matomo", {
            isEvent: true,
            category: "Søk",
            action: "Åpne globalt søk",
            name: `${document.title} (${location.pathname})`,
            value: 0,
        });
    };

    const closeSearchModal = () => {
        if (htmlElement) {
            htmlElement.classList.remove("html--modal-open");
        }

        setShowSearchModal(false);

        searchButtonRef.current.focus();

        Insight.track("matomo", {
            isEvent: true,
            category: "Søk",
            action: "Lukk globalt søk",
            name: `${document.title} (${location.pathname})`,
            value: 0,
        });
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);

        Insight.track("matomo", {
            isEvent: true,
            category: "Navigasjon",
            action: showMenu ? "Lukk meny" : "Åpne meny",
            name: `${document.title} (${location.pathname})`,
            value: 0,
        });
    };

    const fetchData = () => {
        const fetchHeaders = new Headers({
            api_code: getApiCode(environment),
            api_id: "content-api-header-and-footer",
            "cache-control": "no-store",
        });

        const fetchConfig = {
            method: "GET",
            headers: fetchHeaders,
        };

        return fetch(
            `${getUrlRoot(
                environment,
            )}/api/contentdata/header-common?cultureName=${
                isValidLanguage(userLanguage) ? userLanguage : "nb"
            }`,
            fetchConfig,
        )
            .then((response) => response.json())
            .then((data) => {
                const hash = getHash(
                    data.header.globalHeaderMessage?.title +
                        data.header.globalHeaderMessage?.message,
                );
                const globalHeaderMessageIsDismissed =
                    sessionStorage.getItem(hash);

                setHeaderContent({
                    headerData: data.header,
                    menuData: data.menuList.menu[0].coloumns,
                    localizationStrings: data.localization,
                });
                setHeaderMessageIsDismissed(globalHeaderMessageIsDismissed);
            })
            .catch((err) => console.error(err.message));
    };

    const closeHeaderMessage = () => {
        const hash = getHash(
            headerContent?.headerData.globalHeaderMessage.title +
                headerContent?.headerData.globalHeaderMessage.message,
        );
        sessionStorage.setItem(hash, "true");
        setHeaderMessageIsDismissed(true);
    };

    const showHeaderNotification =
        headerContent?.headerData.globalHeaderMessage &&
        !headerMessageIsDismissed;

    return (
        <>
            <header
                className={`polno-header ${
                    showHeaderNotification && "polno-header__notification"
                }`}
                data-user-lang={userLanguage}
            >
                <div className="polno-header__row">
                    <div className="polno-header__content">
                        <div className="polno-header__content-menu-btn">
                            <button
                                className="pno-header__header-btn pno-header__header-btn--menu"
                                aria-controls="site-navigation"
                                aria-expanded={showMenu}
                                onClick={toggleMenu}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="pno-header__header-btn-icon pno-header__menu-icon-open"
                                    viewBox="0 -960 960 960"
                                >
                                    <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="pno-header__header-btn-icon pno-header__menu-icon-closed"
                                    viewBox="0 -960 960 960"
                                >
                                    <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                                </svg>
                                <span className="pno-header__header-btn-text">
                                    {
                                        headerContent?.localizationStrings?.menu
                                            ?.btntext
                                    }
                                </span>
                            </button>
                        </div>
                        <div className="polno-header__content-menu">
                            <SiteNavigation
                                ShowMenu={showMenu}
                                HeaderData={headerContent?.headerData}
                                MenuData={headerContent?.menuData}
                                LocalizationStrings={
                                    headerContent?.localizationStrings?.menu
                                }
                                ApiRoot={environment}
                                Language={userLanguage}
                            />
                        </div>
                        <div className="polno-header__content-logo">
                            <a
                                onClick={logoClickHandler}
                                className="brand"
                                href={headerContent?.headerData?.homeLink?.path}
                                accessKey="1"
                            >
                                <PolitietLogo />
                                <span className="show-for-sr">
                                    {
                                        headerContent?.localizationStrings
                                            ?.header?.logotext
                                    }
                                </span>
                            </a>
                        </div>

                        <div className="polno-header__content-search-btn">
                            {headerContent?.headerData?.searchPage ? (
                                <button
                                    ref={searchButtonRef}
                                    className="pno-header__header-btn js-search-btn"
                                    aria-controls="modal-global-search"
                                    aria-expanded={showSearchModal}
                                    accessKey="4"
                                    onClick={openSearchModal}
                                >
                                    <span className="pno-header__header-btn-text">
                                        {
                                            headerContent?.headerData
                                                ?.searchPage?.title
                                        }
                                    </span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="pno-header__header-btn-icon"
                                        viewBox="0 -960 960 960"
                                    >
                                        <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                                    </svg>
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                {showHeaderNotification && (
                    <PdsNotification
                        dismissible
                        fullWidth
                        iconFill
                        icon="info"
                        type="warning"
                        iconColor="alert-warning"
                        title={
                            headerContent?.headerData.globalHeaderMessage.title
                        }
                        onClose={closeHeaderMessage}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    headerContent?.headerData
                                        .globalHeaderMessage.message,
                                ),
                            }}
                        />
                    </PdsNotification>
                )}
            </header>
            <Modal
                isOpen={showSearchModal}
                onClose={closeSearchModal}
                id="modal-global-search"
                aria-expanded={showSearchModal}
                aria-modal
                role="dialog"
                aria-labelledby="modal-global-search-heading"
            >
                <div
                    className={`modal${showSearchModal ? " modal--open" : ""}`}
                >
                    <div className="row align-center modal__row">
                        <div className="xsmall-12 medium-9 large-7 xlarge-6 column">
                            <h1
                                id="modal-global-search-heading"
                                className="page__heading page__heading--negative modal__heading"
                            >
                                {headerContent?.headerData?.searchPage?.title}
                            </h1>
                            <GlobalSearch
                                IsGlobal={true}
                                SearchUrl={
                                    headerContent?.headerData?.searchPage?.path
                                }
                                Translations={{
                                    Clear: headerContent?.localizationStrings
                                        ?.globalSearch?.clear,
                                    Focus: headerContent?.localizationStrings
                                        ?.globalSearch?.focus,
                                    Count: headerContent?.localizationStrings
                                        ?.globalSearch?.count,
                                    Submit: headerContent?.localizationStrings
                                        ?.globalSearch?.submit,
                                    Placeholder:
                                        headerContent?.localizationStrings
                                            ?.globalSearch?.placeholder,
                                }}
                            />
                        </div>
                        <button
                            className="modal__close-btn js-close-modal"
                            aria-expanded={showSearchModal}
                            aria-controls="modal-global-search"
                            onClick={closeSearchModal}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="modal__close-btn-icon"
                                viewBox="0 -960 960 960"
                            >
                                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Header;
