import React from 'react';
import FilterSorting from '../filter/filter-sorting.jsx';
import idHelper from '../../02-tools/id-helper/idHelper.js';
import xss from 'xss';

const xssConfig = {
    whiteList: {},
    stripIgnoreTag: true,
    stripIgnoreTagBody: ['script']
};

export default class VVRFilterResult extends React.Component {
    constructor(props) {
        super(props);
    }

    translateArray = (arr, translations) => {
        const translated = [];
        arr.map((item) => {
            translated.push(
                translations.Tags[item]
            );
        });

        translated.sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;

            return 0;
        });

        return translated;
    }

    arrayToStr = (arr, translations) => {
        const translated = this.translateArray(arr, translations);
        let str;

        if (translated.length > 1) {
            str = translated.join(', ');
            str = str.replace(/,(?=[^,]*$)/, ` ${translations.And}`);
        } else {
            str = translated.toString();
        }

        return str.replace(/ politidistrikt/g, '');
    }

    arrayToList = (arr, translations) => {
        const translated = this.translateArray(arr, translations);
        const listArray = [];

        translated.map((item, index) => {
            listArray.push(
                <li key={`list-item-${index}`} className="structured-info__items-list-item">{xss(item, xssConfig)}</li>
            );
        });

        return (
            <ul className="structured-info__items-list">{listArray}</ul>
        );
    }

    formatOtherAuthorizations = (selfsec, id, translations) => {
        const listArray = [];
        if (selfsec) {
            listArray.push(
                <li key={`list-item-selfsec`} className="structured-info__items-list-item">{translations.SelfSecurity}</li>
            );
        } else if (id) {
            listArray.push(
                <li key={`list-item-id`} className="structured-info__items-list-item">{translations.IDIssuing}</li>
            );
        }

        return listArray.length ? <ul className="structured-info__items-list">{listArray}</ul> : false;
    }

    formatOrgNumber = (orgnr) => {
        const orgnrSanitized = xss(orgnr.replace(/[.,-\s]/g, ''), xssConfig);
        return orgnrSanitized.length === 9 ? `${orgnrSanitized.substring(0, 3)} ${orgnrSanitized.substring(3, 6)} ${orgnrSanitized.substring(6, 9)}` : orgnrSanitized;
    }

    formatAddress = (address) => {
        const tempAddress = address.replace(/\n/g, ', ');
        const postCodeMatch = tempAddress.match(/, \d{4}/);

        if (!postCodeMatch) return tempAddress;

        let postArea = tempAddress.substring(postCodeMatch.index + 7);
        const restOfAddress = tempAddress.substring(0, (postCodeMatch.index + 7));
        const isPostAreaUppercase = postArea === postArea.toUpperCase();

        if (isPostAreaUppercase) postArea = postArea.charAt(0) + postArea.toLowerCase().slice(1);

        return `${restOfAddress} ${postArea}`;
    }

    render() {
        const p = this.props;
        var resultString = this.props.Translations.Results;
        resultString = resultString.replace('{0}', p.TotalItems);

        return (
            <div className="column xsmall-12">
                <div className="row">
                    <div className="column xsmall-12 filter__hits-column">
                        <div className="filter__results-toolbar">
                            <h2 className="filter__hits" aria-hidden="true" id={idHelper.createId(this.props.Translations.Results)}>{resultString}</h2>
                            <FilterSorting Translations={this.props.Translations} SortItems={this.props.SortItems} SortParam={this.props.SortParam} updateParams={this.props.updateParams} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="column xsmall-12 medium-10 large-8">
                        {p.Items.length ?
                            <ol className="filter__results list--reset-style-type" aria-live="polite" aria-atomic="true">
                                {p.Items.map((item, index) => (
                                    <li className="page-listing__item" key={index}>
                                        <article className="filter__article" role="text">
                                            <h2 className="h3 page-listing__title" id={idHelper.createId(item.Item.org_name)}>{xss(item.Item.org_name, xssConfig)}</h2>
                                            <dl className="structured-info">
                                                {item.Item.address ?
                                                    <div className="structured-info__key-value--inline">
                                                        <dt className="structured-info__key">{p.Translations.VVR.Address}</dt>
                                                        <dd className="structured-info__value">{this.formatAddress(item.Item.address)}</dd>
                                                    </div>
                                                    : null}
                                                {item.Item.org_nummer ?
                                                    <div className="structured-info__key-value--inline stack-regular-fixed">
                                                        <dt className="structured-info__key">{p.Translations.VVR.OrgNumber}</dt>
                                                        <dd className="structured-info__value"><a href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${xss(item.Item.org_nummer, xssConfig)}`} target="_blank">{this.formatOrgNumber(item.Item.org_nummer)}</a></dd>
                                                    </div>
                                                    : null}
                                                {item.Item.virkeomrader.length || item.Item.opplaering ?
                                                    <div className="structured-info__key-value--group stack-regular-fixed">
                                                        <dt className="structured-info__key structured-info__key--title h3">{p.Translations.VVR.AuthorizedBusinessFields}</dt>
                                                        <dd className="structured-info__value">{this.arrayToList(item.Item.virkeomrader.concat(item.Item.opplaering), p.Translations.VVR)}</dd>
                                                    </div>
                                                    : null}
                                                {(item.Item.egenvakt || item.Item.id_utsteder) ?
                                                    <div className="structured-info__key-value--group stack-regular-fixed">
                                                        <dt className="structured-info__key structured-info__key--title h3">{item.Item.virkeomrader.length ? p.Translations.VVR.OtherAuthorizations : p.Translations.VVR.Authorizations}</dt>
                                                        <dd className="structured-info__value">{this.formatOtherAuthorizations(item.Item.egenvakt, item.Item.id_utsteder, p.Translations.VVR)}</dd>
                                                    </div>
                                                    : null}
                                                {item.Item.distrikt.length ?
                                                    <div className="structured-info__key-value--group">
                                                        <dt className="structured-info__key structured-info__key--title h3">{p.Translations.VVR.Districts}</dt>
                                                        <dd className="structured-info__value">{this.arrayToList(item.Item.distrikt, p.Translations.VVR)}</dd>
                                                    </div>
                                                    : null}
                                            </dl>
                                        </article>
                                    </li>
                                ))}
                            </ol>
                            : null}
                    </div>
                </div>
            </div>
        );
    }
}
