import React from 'react';
import Params from '../../02-tools/params/params';
import idHelper from '../../02-tools/id-helper/idHelper';

/*type Props = {
  Label: ?string;
  CategoryFilterItems: Array<Object>;
  Translations: Array<Object>;
  Loading: Boolean;
};*/

export default class FilterPanelYear extends React.Component {
  constructor(props) {
    super(props);
  }

  check = (e) => {
    let indexOfItem = this.props.Items.findIndex(i => i.Year == e.target.value);
    if (indexOfItem >= 0) {
      this.props.Items[indexOfItem].Selected = !(this.props.Items[indexOfItem].Selected);
    }

    this.props.filter(e.target.name, e.target.value, e.target.dataset.param, e.target.checked);
  }

  render() {
    const p = this.props;
    const cclass = (this.props.selected ? "filter__panel" : "filter__panel filter__panel--hidden");

    return (
      <div className={cclass}>
        <div className="xsmall-12 medium-12 column" id={`${p.Label}Panel`} role="tabpanel" aria-labelledby={`${p.Label}Tab ${p.Label}TabMobile`} aria-hidden={!this.props.selected}>
          <ul className="filter__item-choices filter__item-choices--sectioned list--reset-style-type row">
            {(p.Items && p.Items.length) ? p.Items.map((result) => (
              <li className="xsmall-12 medium-4 column" key={result.Year}> role="text"
                <label className="form__label" aria-hidden={result.NumberOfItems === 0 ? true : false}>
                  <input type="checkbox" onChange={this.check.bind(this)} className="form__checkbox form__checkbox--filter" name={p.Label} value={result.Year} data-param={result.Name} id={`Filter-${p.Label}-${result.Year}`} checked={result.Selected ? 'checked' : ''} disabled={result.NumberOfItems === 0 || p.Loading ? 'disabled' : ''} />
                  <span className="form__label-text form__label--offset">{result.Year} ({result.NumbersOfItemsPrYear})</span>
                </label>
              </li>
            )) : <h2 className="filter__panel-message" id={idHelper.createId(p.Translations.Errors.NoFilters)}>{p.Translations.Errors.NoFilters}</h2>}
          </ul>
          <div className="filter__btn-container">
            <button className="btn btn--positive btn--small" onClick={p.CloseTabs}>{p.Translations.Close}</button>
          </div>
        </div>
      </div>
    );
  }
}
